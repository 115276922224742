import { Tooltip } from 'primereact/tooltip';
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import React from 'react';
import { InputErrors } from './InputErrors';
import { Appl } from '../../../core/Appl';

interface AutoCompleteExProps extends AutoCompleteProps {
  label?: string;
  helpText?: string;
  valuefield: string;
  suffixid?: string;
  required?: boolean;
}

export class InputAutoComplete extends React.Component<AutoCompleteExProps, AutoComplete> {
  constructor(props: AutoCompleteExProps) {
    super(props);
  }

  render() {
    const input = this.props;
    return (
      <>
        {input.label && !input.hidden && (
          <label htmlFor={input.id} className="form-label">
            {input.required && <span className="required" />}
            {input.label || input.id}:
            {input.helpText && (
              <>
                <Tooltip target={`.${input.id}-help-text`} content={input.helpText} />
                <i className={`pi pi-question-circle ml-2 ${input.id}-help-text`} style={{ fontSize: '0.8em' }}></i>
              </>
            )}
          </label>
        )}
        {input.disabled ? (
          <>
            <div className="field col-12 md:col-6">{input.value?.toString()}</div>
          </>
        ) : (
          <>
            <AutoComplete
              className={input.className ? input.className : 'field w-100'}
              id={input.id}
              inputId={input.id}
              field={input.valuefield}
              value={input.value}
              placeholder={input.placeholder}
              data-val-field-label={`${input.id}-feedback`}
              virtualScrollerOptions={{ itemSize: 30 }}
              readOnly={false}
              dropdown={false}
              multiple={false}
              forceSelection={true}
              dropdownMode="current"
              showEmptyMessage={true}
              emptyMessage="No records found"
              minLength={1}
              delay={300}
              onChange={this.props.onChange}
              onSelect={this.props.onSelect}
              {...this.props}
            />
            {!input.hidden && <InputErrors errors={Appl.Error?.items} viewId={input?.id} suffixid={input?.suffixid} />}
          </>
        )}
      </>
    );
  }
}
