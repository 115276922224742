import { action, makeObservable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { Appl } from '../../../../core/Appl';

@Service()
export default class RapDashboardViewModel extends BaseViewModel {
  constructor() {
    super();
    makeObservable(this);
  }

  @action public async initAsync(): Promise<void> {
    this.pageTitle = 'My RAP Dashboard';
    if (Appl.User.isAuthenticated) {
      this.pageTitle = 'Admin - RAP Dashboard';
    }
  }
}
