import { RouteObject } from "react-router-dom";
import { AppConfig, ISetting } from "../config/AppConfig";
import { UserContext } from "./services/UserContext";
import { ConsoleLogger, ILogger } from "./services/Logger";
import { IServiceContainer, ServiceContainer } from "./services/ServiceContainer";
import { ISpinnerService, SpinnerService } from "./components/dialogs/Spinner";
import { CacheService, ICacheService } from "./services/CacheService";
import { AlertBoxService, IAlertBoxService } from "./components/dialogs/AlertBox";
import { ConfirmBoxService, IConfirmBoxService } from "./components/dialogs/ConfirmBox";
import { IMessageBoxService, MessageBoxService } from "./components/dialogs/MessageBox";
import { IProgressBarService, ProgressBarService } from "./components/dialogs/ProgressBar";
import { ErrorService, IErrorService } from "./services/ErrorService";
import { IValidator, Validator } from "./services/Validator";
import { IUserContext } from "./services/IUserContext";

export class Appl {

    public static Error: IErrorService = ErrorService.instance;
    public static Logger: ILogger = ConsoleLogger.instance;
    public static Setting: ISetting = AppConfig.instance.Setting!;
    public static Cache: ICacheService = CacheService.instance;
    public static User: IUserContext = UserContext.instance!;
    public static Services: IServiceContainer = ServiceContainer.instance;
    public static Validator: IValidator = Validator.instance;

    public static AlertBox: IAlertBoxService = AlertBoxService.instance;
    public static ConfirmBox: IConfirmBoxService = ConfirmBoxService.instance;
    public static MessageBox: IMessageBoxService = MessageBoxService.instance;
    public static ProgressBar: IProgressBarService = ProgressBarService.instance;
    public static Spinner: ISpinnerService = SpinnerService.instance;
    public static Routes: Array<RouteObject> = [];

}
