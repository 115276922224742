import React from 'react';
import './HeaderView.css';
import { observer } from 'mobx-react';
import Logo from '../../assets/images/logo_primary.svg';
import { Link } from 'react-router-dom';
import { Container, Input, InputType } from '../../core/components/Index';
import { MenuBarProfileView } from '../../core/components/Index';
import { Appl } from '../../core/Appl';

export const HeaderView: React.FC = observer(() => {
  const userContext = Appl.User;
  // useAsyncEffect(async () => {
  //   //vm.menuToggle = false;
  // }, []);

  // setTimeout(() => {
  //   /*** Easy on scroll event listener */
  //   const onscroll = (el: Document, listener: any) => {
  //     el.addEventListener('scroll', listener);
  //   };
  //   /*** Toggle .header-scrolled class to #header when page is scrolled */
  //   const selectHeader = document.getElementById('header') as HTMLElement;
  //   if (selectHeader) {
  //     const headerScrolled = () => {
  //       if (window.scrollY > 100) {
  //         // selectHeader.classList.add('header-scrolled')
  //         selectHeader.className = 'sticky top-0 header-scrolled';
  //       } else {
  //         selectHeader.className = 'sticky top-0';
  //       }
  //     };
  //     window.addEventListener('load', headerScrolled);
  //     onscroll(document, headerScrolled);
  //   }
  // }, 1000);

  return (
    <>
      <header className="fixed top-0 w-full z-10 h-[66px]">
        <nav className={`bg-cream-50  border-b-2 border-orange-500  py-1 p-0 px-4 md:px-6 ${!userContext.isAuthenticated && 'lg:px-12'}`}>
          <div className="flex items-center justify-between">
            <Link to="/" className={`py-2 flex-shrink-0 md:ml-0 ${userContext.isAuthenticated && 'ml-[64px]'}`}>
              <img src={Logo} alt="Logo" className='w-[152px] h-[40px] max-h-[90%]' />
            </Link>
            <div className="flex items-center space-x-4">
              {userContext.isAuthenticated && (
                <MenuBarProfileView
                  //profilePictureUrl={!props.profilePictureUrl ? `/user/profile/${userContext.user().userId}` : props.profilePictureUrl}
                  profileTitle={userContext.displayName}
                  // role={userContext.roles}
                  logoutUrl={''}
                  profileUrl={'/myProfile'}
                />
              )}

            </div>
          </div>
        </nav>

        {/* {!userContext.isAuthenticated && !location.href.includes("members/upload-files") && (
          <div className='bg-white border-b border-gray-200 dark:bg-gray-900 lg:block dark:border-gray-800 pt-2 pb-2'>
            <Container>
              <Link to="/members/upload-requests" className="btn btn-outline-success">
                Secure File Upload
              </Link>
              <Link to="/sra" className="btn btn-outline-success">
                Apply for Retirement
              </Link>
            </Container>
          </div>
        )} */}
      </header>
    </>
  );
});
