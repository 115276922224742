import { action, makeObservable, observable } from 'mobx';
import HomeModel from './HomeModel';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../core/services/BaseViewModel';
import { Appl } from '../../../core/Appl';

@Service()
export default class HomeViewModel extends BaseViewModel {
  @observable public model: HomeModel | undefined;
  @observable menuToggle = false;
  @observable themeToggle = false;

  constructor() {
    super();
    makeObservable(this);
  }

  @action public async initAsync(): Promise<void> {
    if (!Appl.User.isAuthenticated) {
      this.navigate("/login");
    } else {
      this.navigate("/sra");
    }
  }
}
