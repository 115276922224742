import { ErrorBoundary } from 'react-error-boundary';
import { lazy, Suspense } from 'react';
import { ErrorView } from '../pages/ErrorView';

export function lazyComponent(path: string) {
  // const LazyElement = lazy(() => import(`../../modules/admin/questionBank/document/list/DocumentListView`));
  const LazyElement = lazy(() => import(`../../${path}.tsx`));
  return (
    <ErrorBoundary
      FallbackComponent={ErrorView}
      onError={(error: Error) => console.log(error.message)}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      <Suspense>
        <LazyElement />
      </Suspense>
    </ErrorBoundary>
  );
}


