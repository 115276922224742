import React from 'react';
import { observer } from 'mobx-react';
import { Container, View } from '../../../../core/components/Index';
import useAsyncEffect from 'use-async-effect';
import { AuthContext } from '../../../../core/services/AuthContext';

const LogoutView: React.FC = observer(() => {

  useAsyncEffect(async () => {
    await AuthContext.instance.signOutAsync();
  }, []);

  return (
    <>
      <View id="LogoutView" pageTitle="Logout" authorized={true} showMenuBar={false} container={false}>
        <Container>
          <div className="p-10 max-w-lg mx-auto ">
            <h1 className="text-3xl font-semibold mb-4">Logout Success!</h1>
          </div>
        </Container>
      </View>
    </>
  );
});

export default LogoutView;
