import React, {ReactNode, useEffect, useRef} from 'react';
import {Input, InputType} from '../inputs/Inputs';
import {SearchIcon, XIcon} from 'lucide-react';
import {Button} from 'primereact/button';

type SearchToolbarProps = {
  filter?: string;
  filterPlaceholder?: string;
  filterVisible?: boolean;
  addNewVisible?: boolean;
  exportVisible?: boolean;
  refreshVisible?: boolean;
  showButton?: boolean;
  newButtonText?: string;
  children?: ReactNode;
  onRefresh?: () => void;
  onExport?: (type: string) => void;
  onAddNew?: () => void;
  onFilterChange?: (e: object) => void;
  searchOnInputChange?: boolean;
};

export const SearchToolbar: React.FC<SearchToolbarProps> = ({
  filterPlaceholder = 'Search',
  filterVisible = true,
  addNewVisible = true,
  refreshVisible = true,
  showButton = true,
  searchOnInputChange = false,
  // exportVisible = true,
  newButtonText = 'Add New',
  ...props
}) => {
  const [search, setSearch] = React.useState<string>(props.filter ?? '');

  const checkFirstRender = useRef(true);

  useEffect(() => {
    //dont run the first render to avoid flickering on page
    if (checkFirstRender.current) {
      checkFirstRender.current = false;
      return;
    }

    const handler = setTimeout(() => {
      props?.onRefresh && props?.onRefresh();
    }, 300); //   debounce (300ms)

    return () => clearTimeout(handler); // Cleanup the timeout if filter changes before delay
  }, [props.filter]);

  return (
    <>
      {filterVisible && (
        <>
          <div className="relative w-full md:min-w-[400px]">
            <Input
              id="filter"
              inputType={InputType.Text}
              placeholder={filterPlaceholder}
              title={`Search By ${filterPlaceholder}`}
              value={search}
              maxLength={255}
              autoFocus={true}
              hidden={!filterVisible}
              style={{width: '100%'}}
              onChange={(e) => {
                setSearch(e.target.value);
                if (searchOnInputChange) {
                  props.onFilterChange && props.onFilterChange(e);
                }
              }}
            />
            <Button
              type="button"
              plain
              className="!border-none !rounded-xl !px-4 !bg-transparent absolute inset-y-0 right-0 flex items-center top-1"
              onClick={() => {
                setSearch('');
                props.onFilterChange &&
                  props.onFilterChange({
                    target:
                      //copy in target from the input element to avoid errors in the onFilterChange function
                      {value: '', type: 'text', id: 'filter'},
                  });
              }}>
              {search && search.length > 0 ? (
                <XIcon className="h-4 w-4 block text-neutral-700" />
              ) : (
                <SearchIcon className="h-4 w-4 block text-neutral-700" />
              )}
              <span className="sr-only">Clear</span>
            </Button>
          </div>
          {showButton && (
            <Button onClick={props.onRefresh} id="searchButton" className="ml-2" icon="search">
              Search
            </Button>
          )}
        </>
      )}
      {addNewVisible && (
        <Button icon="circle-plus" onClick={props.onAddNew}>
          {newButtonText}
        </Button>
      )}
      {/* {props.exportVisible! && <Button icon={<Font icon="download"/>} items={downloadSource}>Export</Button>} */}
      {refreshVisible && (
        <Button onClick={props.onRefresh} className="ml-2" severity="info">
          Refresh
        </Button>
      )}
    </>
  );
};
