import { Service } from 'typedi';
import { ChangeSubmissionStatusModel, RequireDocumentDetailModel, SubmissionCommentModel, SubmissionListModel } from './SubmissionListModel';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { ISraService } from '../../SraService';
import { action, makeObservable, observable, runInAction } from 'mobx';
import Helper from '../../../../../core/services/Helper';
import { ListItem } from '../../../../../core/services/BaseService';
import { Appl } from '../../../../../core/Appl';

@Service() // eslint-disable-next-line
export default class SubmissionListViewModel extends BaseListViewModel<SubmissionListModel> {
  private service: ISraService;

  @observable public changeStatusModel: ChangeSubmissionStatusModel = {};
  @observable public changeStatusDialogVisible = false;
  @observable public documentListDialogVisible = false;
  @observable public commentsDialogVisible = false;
  @observable public printDialogVisible = false;
  @observable public documentDetailsModel: Array<RequireDocumentDetailModel> = new Array<RequireDocumentDetailModel>();
  @observable public submissionCommentsModels?: Array<SubmissionCommentModel> = [];
  @observable public submissionStatusListItems?: Array<ListItem> = [];
  @observable public afrFile?: any;
  @observable public lovFormTypes?: Array<ListItem> = [];
  @observable public lovSelectedFormTypesId?: number = 1;

  constructor() {
    super('Retirement Notifications Queue', 'afrApplicationId', false);
    this.service = Appl.Services.get<ISraService>('ISraService');
    makeObservable(this);
  }

  @action
  public async initAsync() {
    const result = await this.service?.getListOfValuesAsync('form_types');
    if (result.success) {
      runInAction(() => {
        this.lovFormTypes = result.data as Array<ListItem>;
      })
    }
  }

  public async getPagedAsync(): Promise<void> {
    if (this.lovSelectedFormTypesId === undefined) {
      this.lovSelectedFormTypesId = 1;
    }
    const response = await this.service.getPagedAsync(`/retirement/Submission/search/${this.lovSelectedFormTypesId}`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

  @action public async showSubmissionStatusDialog(row: SubmissionListModel): Promise<void> {
    this.changeStatusModel.submissionId = row.submissionId;
    this.changeStatusModel.submissionStatusId = row.submissionStatusId;
    this.changeStatusModel.afrComment = row.afrComment;
    const result = await this.service?.getListOfValuesAsync('afr_submission_status');
    if (result.success) {
      runInAction(() => {
        this.submissionStatusListItems = result.data as Array<ListItem>;
      })
    }
    this.changeStatusDialogVisible = true;
  }

  @action public async hideChangeSubmissionStatusDialog(): Promise<void> {
    this.changeStatusDialogVisible = false;
  }

  @action
  public async changeSubmissionStatusAsync(): Promise<void> {
    const response = await this.service.changeSubmissionStatusAsync(this.changeStatusModel);
    if (response.success) {
      this.hideChangeSubmissionStatusDialog();
      this.getPagedAsync();
    } else {
      Appl.Error.handle(response.error);
    }
  }

  @action
  public async validateChangeStatusAsync(model: ChangeSubmissionStatusModel): Promise<boolean> {
    Appl.Validator.init();
    await Appl.Validator.validateNumber('Submission Status', 'submissionStatusId', model.submissionStatusId, true);
    await Appl.Validator.validateString('Comment', 'afrComment', model.afrComment, true, 2000);
    return Appl.Validator.isValid();
  }

  @action public async showDocumentListDialog(row: SubmissionListModel): Promise<void> {
    const result = await this.service?.getSubmittedDocumentsAsync(row.submissionId!);
    if (result.success) {
      runInAction(() => {
        this.documentDetailsModel = result.data as Array<RequireDocumentDetailModel>;
      })
    }
    this.documentListDialogVisible = true;
  }

  @action public async hideDocumentListDialog(): Promise<void> {
    this.documentListDialogVisible = false;
  }

  @action public async downloadDocumentAsync(id: number, fileName: string): Promise<void> {
    const result = await this.service?.downloadAsync(`/retirement/Retiree/download/${id}`);
    if (result.success) {
      Helper.downloadFile(result.data, fileName, '');
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action public async showCommentsDialog(submissionId: number): Promise<void> {
    const result = await this.service?.getSubmissionCommentsAsync(submissionId);
    if (result.success) {
      runInAction(() => {
        this.submissionCommentsModels = result.data as Array<SubmissionCommentModel>;
      })
    }
    this.commentsDialogVisible = true;
  }

  @action public async hideCommentsDialog(): Promise<void> {
    this.commentsDialogVisible = false;
  }

  @action public async showPrintDialog(submissionId: number): Promise<void> {
    let formId = '6105b';
    if (this.lovSelectedFormTypesId === 1) {
      formId = '6105b';
    } else if (this.lovSelectedFormTypesId === 2) {
      formId = '6019a';
    }
    const result = await this.service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${submissionId}/${formId}`);
    if (result.success) {
      this.afrFile = result.data
    } else {
      Appl.Error.handle(result.error);
    }
    this.printDialogVisible = true;
  }

  @action public async hidePrintDialog(): Promise<void> {
    this.printDialogVisible = false;
  }

  @action public async onFormTypeChanged(): Promise<void> {

  }

  @action
  public async export(format: string): Promise<void> {
    this.searchRequest = { skip: 0, take: 10000, sortColumn: this.searchRequest!.sortColumn, sortAscending: this.searchRequest!.sortAscending, filter: '' };
    const result = await this.service.getQueueAsFileAsync(format, this.searchRequest);
    if (result.success) {
      Helper.downloadFile(result.data, 'RetirementNotifications.csv', 'text/csv');
    } else {
      Appl.Error.handle(result.error);
    }
  }
}
