import { Button, View, ViewHeader } from "../components/Index";

export const ErrorView: React.FC<{ error: Error; resetErrorBoundary?: any; }> = (props) => {
    return (
        <View id="UnhandledErrorView">
            <ViewHeader title="Error: Something went wrong">
                {props.resetErrorBoundary && <Button title="Try Again" className="btn-secondary" onClick={props.resetErrorBoundary} />}
            </ViewHeader>
            <div className="p-8">
                <div className="bg-white rounded-xl mb-4 text-center py-16 px-6 shadow-card border border-gray-25 space-y-8">
                    <div>
                        <h2 className="font-medium text-lg md:text-2xl text-gray-800">Sorry! Something went wrong</h2>
                        <p className="text-gray-500 max-w-2xl mx-auto text-sm md:text-base">
                            {props.error.message}
                        </p>
                    </div>
                </div>
            </div>
        </View>
    );
};