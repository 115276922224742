import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

export const ProgressBar: React.FC = observer(() => {
  const vm = ProgressBarService.instance;
  return (
    <>
      {vm.progress > 0 && vm.progress !== 100 && (
        <div
          style={{ width: '80%' }}
          className="progress"
          role="progressbar"
          aria-label="Progress Bar"
          aria-valuenow={vm.progress}
          aria-valuemin={0}
          aria-valuemax={100}>
          <div className="progress-bar" style={{ width: `${vm.progress}%` }}>
            {vm.message}
          </div>
        </div>
      )}
    </>
  );
});

export interface IProgressBarService {
  progress: number;
  message: string;
  reset(): void
}

export class ProgressBarService implements IProgressBarService {
  private static _instance: ProgressBarService;
  @observable public progress = 0;
  @observable public message = '';
  constructor() {
    makeAutoObservable(this);
  }
  public static get instance() {
    return this._instance ?? (this._instance = new ProgressBarService());
  }

  @action public reset(): void {
    this.progress = 0;
    this.message = '';
  }
}
