import { Tooltip } from 'primereact/tooltip';
import React, { ReactNode } from 'react';
import './InputOptionBox.css';
import { observer } from 'mobx-react';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import { InputErrors } from './InputErrors';
import { Appl } from '../../../core/Appl';
//https://medium.com/@sandupa.egodage/react-form-with-typescript-c74510b2f9d3
//https://blog.risingstack.com/handling-react-forms-with-mobx-observables/
///https://giselamirandadifini.com/creating-a-react-input-component-in-typescript
//https://dev.indooroutdoor.io/how-to-extend-any-html-element-with-react-and-typescript

interface OptionBoxProps extends CheckboxProps {
  label?: ReactNode;
  helpText?: string;
  className?: string;
}

export const InputOptionBox: React.FC<OptionBoxProps> = observer(({ className = '', ...props }) => {
  return (
    <>
      <Checkbox
        value={props.value}
        {...props}
      />
      <label htmlFor={props.inputId} className="ml-2 text-15px font-normal text-black leading-26px tracking--1px">{props.label}</label>
      {props.helpText && (
        <>
          <Tooltip target={`.${props.id}-help-text`} content={props.helpText} />
          <i className={`pi pi-question-circle ml-2 ${props.id}-help-text`} style={{ fontSize: '0.8em' }}></i>
        </>
      )}
      <InputErrors errors={Appl.Error?.items} viewId={props?.id} />
    </>
  );
});