import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { Appl } from '../../../../core/Appl';
import { ChangeOfAddressModel } from './addEdit/ChangeOfAddressModel';

export interface IChangeOfAddressService extends IBaseService {

    createOrUpdateAsync(model: ChangeOfAddressModel): Promise<ServerResponse>;
    getQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class ChangeOfAddressService extends BaseService implements IChangeOfAddressService {
    constructor() {
        super(`${Appl.Setting.Services.MemberServiceUrl}`);
    }

    public async createOrUpdateAsync(model: ChangeOfAddressModel): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/members/ChangeOfAddress/createOrUpdateChangeOfAddress`, model);
            return this.getResponse(response);
        } catch (error: unknown) {
            return this.getErrorResponse(error);
        }
    }

    public async getQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/members/ChangeOfAddress/download-change-of-address-queue/${format}`, searchModel, { responseType: 'blob' });
            return this.getResponse(response);
        } catch (error: unknown) {
            return this.getErrorResponse(error);
        }
    }

}
