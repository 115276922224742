export interface ILogger {
    logTrace(message: string): void;
    logWarn(message: string): void;
    logError(message: string): void;
    logInfo(message: string): void;
    logJsonValue(message: string, value: object): void;
}

export class ConsoleLogger implements ILogger {

    private static _instance: ConsoleLogger;
    public static get instance() {
        return this._instance ?? (this._instance = new ConsoleLogger());
    }

    logTrace(message: string) {
        console.trace(message);
    }
    logInfo(message: string) {
        console.info(message);
    }

    logWarn(message: string) {
        console.warn(message);
    }

    logError(message: string) {
        console.error(message);
    }

    logJsonValue(message: string, value: any) {
        console.info(`${message}:${JSON.stringify(value)}`);
    }
}