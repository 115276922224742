import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { IAccountService } from '../AccountService';
import { ProfileModel } from './ProfileModel';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { Appl } from '../../../../core/Appl';

@Service()
export default class ProfileViewModel extends BaseViewModel {
  @observable public model: ProfileModel = new ProfileModel();
  private _service: IAccountService;
  constructor() {
    super();
    makeObservable(this);
    this._service = Appl.Services.get<IAccountService>('IAccountService');
  }

  @action public async loadAsync() {
    this.pageTitle = 'My Profile';
  }

  @action public async registerAsync() {
    // this.setLoader(true);
    this.navigate('LoginView');
    // const result = await this._service?.loginAsync(this.model);
    // if(result.success){
    //     let userContext = Dependency.getService<IUserContext>("IUserContext")
    //     await userContext.saveTokenAsync(result?.data?.access_token);
    // }else{
    //     //TODO: Log Error
    // }
  }

  @action public async helpAsync() {
    this.navigate('HelpView');
  }

  @action public async loginAsync() {
    this.navigate('LoginView');
  }
}
