import './MessageBox.css';
import { observer } from 'mobx-react';
import { action, makeAutoObservable, observable } from 'mobx';
import { Appl } from '../../Appl';
import { X } from 'lucide-react';
import parse from 'html-react-parser';
import { Button } from '../actions/Button';

export const MessageBox: React.FC = observer(() => {
    const vm = Appl.MessageBox
    return (
        <>
            {vm.visible &&
                <>
                    <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75" style={{ zIndex: 4000 }}></div>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto drop-shadow-sm" style={{ zIndex: 5000 }}>
                        <div className="flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                <div className="flex items-start bg-cream-50 border-b-2 border-orange-500 p-0 py-4 px-6 justify-between items-center">
                                    <h3 className="text-2xl font-semibold leading-6 text-black tracking--1percent" id="modal-title">{vm.title}</h3>
                                    <div className='ml-4'>
                                        <button type="button" className="bg-orange-500 flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 h-6 w-6 hover:text-white-500 inline-flex items-center justify-center rounded-full text-white" data-pc-section="closebutton"
                                            onClick={() => { vm.hide() }}>
                                            <span className="sr-only">Close</span>
                                            <X className="h-4 w-4" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                                <div className="bg-white px-6 p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:text-left">
                                            <div>
                                                <p className="text-15px text-gray-800">{parse(vm.message)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-x-4 px-6 py- pb-6 ">
                                    <Button title={vm.acceptLabel} onClick={() => vm.hide()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
});

export interface IMessageBoxService {
    title: string;
    subTitle: string;
    message: string;
    visible: boolean;
    acceptLabel: string;
    show(message: string, title?: string, subTitle?: string, acceptLabel?: string): void;
    hide(): void;
}

export class MessageBoxService implements IMessageBoxService {

    private static _instance: MessageBoxService;
    public static get instance() {
        return this._instance ?? (this._instance = new MessageBoxService());
    }

    @observable public title = '';
    @observable public subTitle = '';
    @observable public message = '';
    @observable public visible = false;
    @observable public acceptLabel = 'Okay';
    constructor() {
        makeAutoObservable(this);
    }

    @action public show(message: string, title: string | undefined = 'Information', subTitle = '',
        acceptLabel = "Okay"): void {
        this.title = title;
        this.message = message;
        this.subTitle = subTitle;
        this.visible = true;
        this.acceptLabel = acceptLabel;
    }

    @action public hide(): void {
        this.title = '';
        this.message = '';
        this.subTitle = '';
        this.visible = false;
    }
}
