import { observer } from 'mobx-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadRemoveEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { Input, InputType } from './inputs/Inputs';
import { X, File } from 'lucide-react';
import { useState } from 'react';
import { Tag } from 'primereact/tag';
import { ListItem } from '../services/BaseService';
import { InputDropdown } from './inputs/InputDropdown';

export interface DocumentsModel {
  docId?: number;
  docTypeId: number;
  docType?: string;
  docFileSize?: string;
  docFilename?: string;
  otherDocName?: string;
  isNew?: boolean;
  file?: any;
}

export const UploadUtility: React.FC<{
  onHide: () => void;
  onOk: () => void;
  visible: boolean;
  dialogHeader: string;
  emptyTemplateInfoText?: string
  documentList: Array<ListItem>;
  activeDocument: DocumentsModel;
  maxFileSize: number;
  chooseLabel: string;
  allowedFileExtensions: string;
  docTypeId: number;
}> = observer((props) => {


  let activeDocument: DocumentsModel = props.activeDocument;
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [docSelectionValid, setDocSelectionValid] = useState(true);
  // let docSelectionValid = true;

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;
    activeDocument.docFilename = file.name;
    activeDocument.file = file;
    activeDocument.docFileSize = props.formatSize;
    activeDocument.isNew = true;
    return (
      <div className="w-full flex justify-between items-center">
        <div className='flex items-center'>
          <File size={18} className='w-5 h-5 mr-2' />
          <span className="font-medium">{activeDocument.docFilename} ({activeDocument.docFileSize})</span>
        </div>
        <div className="flex items-center space-x-2">
          <Tag severity="danger" style={{ cursor: 'pointer' }} value="Remove" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      </div >
    );
  };

  const onTemplateRemove = (file: File, callback: Function) => {
    // setTotalSize(totalSize - file.size);
    callback();
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    // const value = totalSize / 10000;
    // const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={`${options.className} mb-2`}>
        {options.chooseButton}
        {/* {uploadButton}
            {cancelButton} */}
        {/* <div className="flex align-items-center gap-3 ml-auto">
                <span>{formatedValue} / 1 MB</span>
                <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
            </div> */}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div>
        <p className="text-center text-gray-800 font-medium">Choose a file or drag & drop it here</p>
        <p className="text-center text-gray-400">{props.emptyTemplateInfoText}</p>
      </div>
    );
  };

  // const onFileRemove = (e: FileUploadRemoveEvent) => {
  //   const fileInd = props.files.findIndex((x: any) => x.fileName === e.file.name);
  //   props.files.splice(fileInd, 1);
  // };

  // const chooseOptions = { icon: 'pi pi-plus' };
  return (
    <>
      <Dialog visible={props.visible} modal
        draggable={false}
        onHide={() => props.onHide()}
        content={({ hide }) => (
          <div className="w-full row-start-2 rounded-t-3xl bg-white mx-auto shadow-lg ring-1 ring-zinc-950/10  sm:rounded-2xl forced-colors:outline transition duration-100  ">
            <div className="px-8 py-3 border-b border-gray-200 flex items-center justify-between">
              <h2 className="text-balance text-lg/6 font-semibold text-zinc-950 sm:text-base/6 dark:text-white">{props.dialogHeader}</h2>
              <button className="bg-gray-50 rounded-full w-10 h-10 flex items-center justify-center" onClick={hide}>
                <X size={18} />
              </button>
            </div>
            <div className='p-6'>
              <div className='grid grid-cols-2 gap-4 mb-2'>
                <div>
                  <InputDropdown
                    id='docTypeId'
                    type="number"
                    value={selectedDocId}
                    label='Select Document to Upload'
                    options={props.documentList}
                    optionValue="key"
                    optionLabel="value"
                    placeholder="Select a Document"
                    style={{ width: '375px' }}
                    className="w-full"
                    required={true}
                    onChange={async (e) => {
                      setSelectedDocId(e.value);
                      props.activeDocument.docTypeId = e.value as number;
                      props.activeDocument.docType = props.documentList.find(o => o.key === e.value)?.value;
                    }}
                  />
                  {!docSelectionValid && <span className="text-red-600 text-sm font-medium mt-1 block">Document Type is required.</span>}
                </div>
                <div>
                  <Input
                    id="otherDocName"
                    value={props.activeDocument.otherDocName}
                    inputType={InputType.Text}
                    onChange={(e) => { props.activeDocument.otherDocName = e.target.value; }}
                    label="Other Document Name"
                    required={(props.activeDocument.docTypeId === props.docTypeId)}
                    hidden={!(props.activeDocument.docTypeId === props.docTypeId)}
                  // errors={errors}
                  />
                </div>
              </div>
              <label className="font-medium text-base block mb-2">Upload {(props.documentList.find(o => o.key === props.activeDocument.docTypeId?.toString()))?.value} Document</label>
              <FileUpload
                mode="advanced"
                name={`files${props.activeDocument?.docTypeId}`}
                accept={props.allowedFileExtensions}
                maxFileSize={props.maxFileSize}
                chooseLabel={props.chooseLabel}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                className='max-h-60 overflow-y-auto'
              />
              <div className='justify-end flex'>
                <Button severity='secondary' className="mt-4 me-2" label="Close" onClick={() => props.onHide()} />
                <Button className="mt-4" label="Add" onClick={() => {
                  // alert(props.activeDocument.docTypeId);
                  if (props.activeDocument.docTypeId === 1) {
                    //errors?.set("docTypeId", ['Document Type is required']);
                    setDocSelectionValid(false);
                  }
                  // setErrors(errors);
                  if (docSelectionValid) {
                    props.onOk();
                    setSelectedDocId(null);
                    setDocSelectionValid(true);
                  }
                }
                } />
              </div>
            </div>
          </div>
        )}>
      </Dialog>
    </>
  );
});
