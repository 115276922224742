import Tailwind from 'primereact/passthrough/tailwind';
import { twMerge } from 'tailwind-merge';
import { classNames } from 'primereact/utils';

const TRANSITIONS = {
  overlay: {
    timeout: 150,
    classNames: {
      enter: 'opacity-0 !scale-100',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
};

export const SCERSTheme = {
  unstyled: true,
  pt: {
    ...Tailwind,
    fileupload: {
      input: 'hidden',
      buttonbar: {
        className: classNames('flex flex-wrap', 'bg-gray-50 dark:bg-gray-800 p-5 border border-solid border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-tr-lg rounded-tl-lg gap-2 border-b-0')
      },
      basicButton: {
        className: classNames('text-white bg-blue-500 border border-blue-500 p-3 px-5 rounded-md text-base', 'overflow-hidden relative')
      },
      chooseButton: {
        className: classNames('text-white bg-blue-500 border border-blue-500 p-3 px-5 rounded-md text-base', 'overflow-hidden relative')
      },
      chooseIcon: 'mr-2 inline-block',
      chooseButtonLabel: 'flex-1 font-bold',
      uploadButton: {
        icon: 'mr-2'
      },
      cancelButton: {
        icon: 'mr-2'
      },
      content: {
        className: classNames('relative', 'bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-b-lg')
      },
      file: {
        className: classNames('flex items-center flex-wrap', 'p-4 border border-gray-300 dark:border-blue-900/40 rounded gap-2 mb-2', 'last:mb-0')
      },
      thumbnail: 'shrink-0',
      fileName: 'mb-2',
      fileSize: 'mr-2',
      uploadIcon: 'mr-2'
    },
    radiobutton: {
      root: {
        className: classNames('relative inline-flex cursor-pointer select-none align-bottom', 'w-6 h-6')
      },
      input: {
        className: classNames(
          'absolute appearance-none top-0 left-0 size-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer'
        ),
      },
      box: ({ props }) => ({
        className: classNames(
          'flex justify-center items-center',
          'border w-6 h-6 text-gray-700 rounded-full transition duration-200 ease-in-out',
          {
            'border-gray-300 bg-white dark:border-blue-900/40 dark:bg-gray-900 dark:text-white/80': !props.checked,
            'border-primary-500 bg-white': props.checked
          },
          {
            'hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !props.disabled,
            'cursor-default opacity-60': props.disabled
          }
        )
      }),
      icon: ({ props }) => ({
        className: classNames('transform rounded-full', 'block w-4 h-4 transition duration-200 bg-primary-500 dark:bg-gray-900', {
          'backface-hidden scale-10 invisible': !props.checked,
          'transform scale-100 visible': props.checked
        })
      })
    },
    multiselect: {
      root: ({ props }) => ({
        className: classNames('inline-flex cursor-pointer select-none mt-1', 'bg-white border border-neutral-300 rounded-lg', 'w-full md:w-80', {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled
        })
      }),
      labelContainer: 'overflow-hidden flex flex-auto cursor-pointer',
      label: ({ props }) => ({
        className: classNames('block overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis', 'text-gray-800 dark:text-white/80', 'p-3 transition duration-200', {
          '!px-3 !py-3': props.display !== 'chip' && (props.value == null || props.value == undefined),
          '!py-2 px-3': props.display === 'chip' && props.value !== null
        })
      }),
      token: {
        className: classNames('-my-1 py-1 px-2 mr-2 bg-neutral-200 text-neutral-700 text-sm rounded-full', 'cursor-default inline-flex items-center')
      },
      removeTokenIcon: 'ml-2',
      trigger: {
        className: classNames('flex items-center justify-center shrink-0', 'bg-transparent text-gray-600 dark:text-white/70 w-12 rounded-tr-lg rounded-br-lg')
      },
      panel: {
        className: classNames('bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-0 rounded-xl max-w-lg shadow-sm')
      },
      header: {
        className: classNames('flex items-center justify-between', 'p-3 text-gray-700  bg-gray-100 border-b border-gray-50 font-semibold m-0 pr-5  rounded-t-xl')
      },
      headerCheckboxContainer: {
        className: classNames('hidden cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6')
      },
      headerCheckbox: {
        root: ({ props }) => ({
          className: classNames(
            ' items-center justify-center bg-neutral-50 ',
          )
        })
      },
      headerCheckboxIcon: 'w-6 h-6 text-white text-base',
      closeButton: {
        className: classNames(
          'flex items-center overflow-hidden relative',
        )
      },
      closeIcon: 'w-4 h-4 inline-block',
      wrapper: {
        className: classNames('max-h-[200px] overflow-auto', 'bg-white text-gray-700 border-0 rounded-md shadow-lg', 'dark:bg-gray-900 dark:text-white/80')
      },
      list: 'py-0 list-none m-0',
      item: ({ context }) => ({
        className: classNames('tracking--1percent cursor-pointer font-semibold text-15px overflow-hidden relative whitespace-nowrap', 'm-0 p-3 py-2 border-0 rounded-none', {
          'text-[#323D4C] hover:text-gray-700 hover:bg-gray-200': !context.focused && !context.selected,
          'bg-gray-100 text-gray-700  hover:text-gray-700 hover:bg-gray-200  ': context.focused && !context.selected,
          'font-semibold  text-black': context?.focused && context?.selected,
          'font-semibold text-black': !context.focused && context?.selected
        })
      }),
      checkboxContainer: {
        className: classNames('  inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6')
      },
      checkbox: {
        className: classNames(
          'flex items-center justify-center',
          '!border w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
        ),
        icon: {
          className: 'w-4 h-4 transition-all duration-200 text-primary-500 text-base dark:text-gray-900 stroke-[0.75px] stroke-current',

        }
      },
      itemGroup: {
        className: classNames('m-0 p-3 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto')
      },
      filterContainer: 'relative flex-1 w-full mr-4',
      filterInput: {
        root: {
          className: classNames(' mt-0 w-full h-9')
        }
      },
      filterIcon: '-mt-2 absolute top-1/2 right-3 text-neutral-400 w-4 h-4',
      clearIcon: 'text-gray-500 right-12 -mt-2 absolute top-1/2',
      transition: TRANSITIONS.overlay
    },
    confirmdialog: {
      message: 'ml-0',
    },
    dialog: {
      root: ({ state }) => ({
        className: classNames('rounded-2xl bg-white shadow-lg  border-0', 'max-h-[90%] transform scale-100', 'm-0 w-[60w]', 'dark:border dark:border-blue-900/40', {
          'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized
        })
      }),
      header: {
        className: classNames('flex items-center justify-between shrink-0', ' text-gray-800 p-6', 'dark:bg-gray-900  dark:text-white/80')
      },

      headerTitle: 'text-2xl tracking--1percent text-gray-800 font-semibold',
      headerIcons: 'flex items-center',
      closeButton: {
        className: classNames(
          'flex items-center justify-center overflow-hidden relative',
          'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
          'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]', // focus
          'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]'
        )
      },
      closeButtonIcon: 'w-4 h-4 inline-block',
      content: ({ state }) => ({
        className: classNames('overflow-y-auto', 'bg-white text-gray-700 px-6 pb-8 pt-0', 'rounded-bl-2xl rounded-br-2xl', 'dark:bg-gray-900  dark:text-white/80 ', {
          grow: state.maximized
        }),

      }),
      message: {
        root: {
          className: 'bg-red-500'
        }
      },
      footer: {
        className: classNames('shrink-0 ', 'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-2xl', 'dark:bg-gray-900  dark:text-white/80')
      },
      mask: ({ state }) => ({
        className: classNames('transition duration-200', { 'bg-black/40': state.containerVisible })
      }),
      transition: ({ props }) => {
        return props.position === 'top'
          ? {
            enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0'
          }
          : props.position === 'bottom'
            ? {
              enterFromClass: 'opacity-0 scale-75 translate-y-full',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0'
            }
            : props.position === 'left' || props.position === 'top-left' || props.position === 'bottom-left'
              ? {
                enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0'
              }
              : props.position === 'right' || props.position === 'top-right' || props.position === 'bottom-right'
                ? {
                  enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
                }
                : {
                  enterFromClass: 'opacity-0 scale-75',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass: 'opacity-0 scale-75'
                };
      }
    },
    button: {
      root: ({ props, context }) => ({
        className: classNames(
          '  inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300',
          {
            'bg-primary-500 text-neutral-50 hover:bg-primary-700 dark:bg-green-900 dark:text-neutral-50 dark:hover:bg-green-900/90 border-primary-700':
              props.severity === null && !props.text && !props.outlined && !props.plain,

          },

          // { 

          //   'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(176,185,198,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(203,213,225,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
          //     props.severity === 'secondary' || props.severity === 'primary',
          //   'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(136,234,172,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(134,239,172,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
          //     props.severity === 'success',
          //   'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
          //     props.severity === 'info',
          //   'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(250,207,133,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,211,77,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
          //     props.severity === 'warning',
          //   'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(212,170,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(216,180,254,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
          //     props.severity === 'help',
          //   'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(247,162,162,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,165,165,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
          //     props.severity === 'danger',
          // },
          {

            'text-white bg-white hover:bg-[#F2F2F2] border border-[#666] text-[#353535]  hover:text-black  ':
              props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
            'text-white dark:text-gray-900 bg-green-500 dark:bg-green-400 border border-green-500 dark:border-green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:border-green-600 dark:hover:border-green-500':
              props.severity === 'success' && !props.text && !props.outlined && !props.plain,
            'bg-gradient-to-b from-[#FBFBFB] to-[#EBEBEB] hover:from-[#EAEAEA] hover:to-[#D8D8D8] border-[#CFCFCF] border text-neutral-900 hover:bg-neutral-300 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-700':
              props.severity === 'info' && !props.text && !props.outlined && !props.plain,
            ' bg-orange-500 text-neutral-50 hover:bg-orange-700 dark:bg-orange-900 dark:text-neutral-50 dark:hover:bg-orange-900/90 border-orange-700':
              props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
            'text-white dark:text-gray-900 bg-purple-500 dark:bg-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:border-purple-600 dark:hover:border-purple-500':
              props.severity === 'help' && !props.text && !props.outlined && !props.plain,
            'text-white dark:text-gray-900 bg-red-600 dark:bg-red-400 border border-red-600 dark:border-red-400 hover:bg-red-700 dark:hover:bg-red-500 hover:border-red-600 dark:hover:border-red-500':
              props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
          },
          { 'shadow-lg': props.raised },
          { 'rounded': !props.rounded, 'rounded-full': props.rounded },
          {
            'bg-transparent border-transparent': props.text && !props.plain,
            'text-primary-500 dark:text-primary-400 hover:bg-primary-300/20':
              props.text && (props.severity === null || props.severity === 'info') && !props.plain,
            'text-gray-500 dark:text-grayy-400 hover:bg-gray-300/20':
              props.text && props.severity === 'secondary' && !props.plain,
            'text-green-500 dark:text-green-400 hover:bg-green-300/20':
              props.text && props.severity === 'success' && !props.plain,
            'text-orange-500 dark:text-orange-400 hover:bg-orange-100/10':
              props.text && props.severity === 'warning' && !props.plain,
            'text-purple-500 dark:text-purple-400 hover:bg-purple-300/20':
              props.text && props.severity === 'help' && !props.plain,
            'text-red-500 dark:text-red-400 hover:bg-red-300/20':
              props.text && props.severity === 'danger' && !props.plain,
          },
          { 'shadow-lg': props.raised && props.text },
          {
            'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
            'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.plain && props.outlined,
            'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600':
              props.plain && !props.outlined && !props.text,
          },
          {
            'bg-transparent border': props.outlined && !props.plain,
            'text-primary-500 dark:text-primary-400 border border-primary-500 dark:border-primary-400 hover:bg-primary-300/20':
              props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
            'text-neutral-900 hover:bg-neutral-100/80 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-800/80 bg-white':
              props.outlined && props.severity === 'secondary' && !props.plain,
            'text-green-500 dark:text-green-400 border border-green-500 dark:border-green-400 hover:bg-green-300/20':
              props.outlined && props.severity === 'success' && !props.plain,
            'text-orange-500 dark:text-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-300/20':
              props.outlined && props.severity === 'warning' && !props.plain,
            'text-purple-500 dark:text-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-300/20':
              props.outlined && props.severity === 'help' && !props.plain,
            'text-red-500 dark:text-red-400 border border-red-500 dark:border-red-400 hover:bg-red-300/20':
              props.outlined && props.severity === 'danger' && !props.plain,
          },
          {
            'h-10 px-5 py-2 rounded-full': props.size === null,
            'text-xs py-2 px-3 rounded-full': props.size === 'small',
            'text-xl py-3 px-4': props.size === 'large',
          },
          { 'flex-column': props.iconPos == 'top' || props.iconPos == 'bottom' },
          { 'opacity-60 pointer-events-none cursor-default': context.disabled }
        ),
      }),
      label: ({ props }) => ({
        className: classNames(
          'flex-1',
          'font-semibold',
          {
            'hover:underline': props.link,
          },
          { 'invisible w-0': props.label == null }
        ),
      }),
      icon: ({ props }) => ({
        className: classNames('mx-0', {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2 order-2': props.iconPos == 'bottom' && props.label != null,
        }),
      }),
      loadingIcon: ({ props }) => ({
        className: classNames('mx-0', {
          'mr-2': props.loading && props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.loading && props.iconPos == 'right' && props.label != null,
          'mb-2': props.loading && props.iconPos == 'top' && props.label != null,
          'mt-2 order-2': props.loading && props.iconPos == 'bottom' && props.label != null,
        }),
      }),
      badge: ({ props }) => ({
        className: classNames({ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }),
      }),
    },
    dropdown: {
      root: ({ props }) => ({
        className: classNames(
          'h-10  shadow-sm  w-full rounded-lg border border-neutral-300 bg-white  text-sm ring-offset-white cursor-pointer inline-flex relative select-none',
          'bg-white border border-neutral-300 transition-colors duration-200 ease-in-out rounded-lg',
          'dark:bg-gray-900 dark:border-primary-900/40 dark:hover:border-primary-300',
          'w-full md:w-48',
          'hover:border-primary-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
          { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
        )
      }),
      input: ({ props }) => ({
        className: classNames(
          'cursor-pointer block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
          'bg-transparent border-0 text-black',
          'dark:text-white/80',
          'px-3  items-center  transition duration-200 bg-transparent rounded appearance-none  font-normal text-sm',
          'focus:outline-none focus:shadow-none',
          { 'pr-7': props.showClear }
        )
      }),
      trigger: {
        className: classNames('flex items-center justify-center shrink-0', 'bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg')
      },
      wrapper: {
        className: classNames('max-h-[200px] overflow-auto', 'bg-white text-gray-700 border-0 rounded-md shadow-lg', 'dark:bg-gray-900 dark:text-white/80')
      },
      list: 'py-3 list-none m-0',
      item: ({ context }) => ({
        className: classNames(
          'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
          'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
          'dark:text-white/80 dark:hover:bg-gray-800',
          'hover:text-gray-700 hover:bg-gray-200',
          {
            'text-gray-700': !context.focused && !context.selected,
            'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.selected,
            'text-primary-700 dark:text-white/80': context.focused && context.selected,
            'bg-primary-50 text-primary-700 dark:bg-primary-300 dark:text-white/80': !context.focused && context.selected,
            'opacity-60 select-none pointer-events-none cursor-default': context.disabled
          }
        )
      }),
      itemgroup: {
        className: classNames('m-0 p-3 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto')
      },
      header: {
        className: classNames('p-3 border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg', 'dark:bg-gray-800 dark:text-white/80 dark:border-primary-900/40')
      },
      filtercontainer: 'relative',
      filterinput: {
        className: classNames(
          'pr-7 -mr-7',
          'w-full',
          'font-sans text-base text-gray-700 bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
          'dark:bg-gray-900 dark:border-primary-900/40 dark:hover:border-primary-300 dark:text-white/80',
          'hover:border-primary-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]'
        )
      },
      filtericon: '-mt-2 absolute top-1/2',
      clearicon: 'text-gray-500 right-12 -mt-2 absolute top-1/2',
      transition: TRANSITIONS.overlay
    },
    overlaypanel: {
      root: {
        className: classNames(
          'bg-white text-gray-700 border-0 rounded-md shadow-lg',
          'z-40 transform origin-center',
          'absolute left-0 top-0 mt-3',
          'before:absolute before:w-0 before:-top-3 before:h-0 before:border-transparent before:border-solid before:ml-6 before:border-x-[0.75rem] before:border-b-[0.75rem] before:border-t-0 before:border-b-white dark:before:border-b-gray-900',
          'dark:border dark:border-blue-900/40 dark:bg-gray-900  dark:text-white/80'
        )
      },
      closeButton: 'flex items-center justify-center overflow-hidden absolute top-0 right-0 w-6 h-6',
      content: 'p-5 items-center flex',
      transition: TRANSITIONS.overlay
    },
    inputswitch: {
      root: ({ props }) => ({
        className: classNames('inline-block relative flex-shrink-0', 'w-12 h-7', {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled
        })
      }),
      input: {
        className: classNames('absolute appearance-none top-0 left-0 size-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer')
      },
      slider: ({ props }) => ({
        className: classNames(
          'absolute cursor-pointer top-0 left-0 right-0 bottom-0 border border-transparent',
          'transition-colors duration-200 rounded-2xl',
          'focus:outline-none focus:outline-offset-0 ',
          "before:absolute before:content-'' before:top-1/2 before:bg-white before:dark:bg-gray-900 before:w-5 before:h-5 before:left-1 before:-mt-2.5 before:rounded-full before:transition-duration-200",
          {
            'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 hover:dark:bg-gray-700 ': !props.checked,
            'bg-primary-500 before:transform before:translate-x-5': props.checked
          }
        )
      })
    },
    checkbox: {
      root: {
        className: classNames('cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6')
      },
      input: {
        className: classNames('absolute appearance-none top-0 left-0 size-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer')
      },
      box: ({ props, context }) => ({
        className: classNames(
          'flex items-center justify-center',
          'border-[1.5px] border-[#CCCCCC] bg-white w-6 h-6 text-primary-500 rounded-lg transition-colors duration-200',
          {
            '': !context.checked,
            'border-primary-500 bg-white dark:border-primary-400 dark:bg-primary-400': context.checked
          },
          {
            'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !props.disabled,
            'cursor-default opacity-60': props.disabled
          }
        ),

      }),
      icon: {
        className: 'w-4 h-4 transition-all duration-200 text-primary-500 text-base dark:text-gray-900 stroke-[0.75px] stroke-current',
      }
    },
    inputtext: {
      root: ({ props, context }) => ({
        className: classNames(
          'm-0',
          'mt-1  flex h-10 w-full rounded-lg border border-neutral-300 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-primary-500 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300'
        ),
      }),
    },
    datatable: {
      root: ({ props }) => ({
        className: classNames('relative text-sm', {
          'flex flex-col h-full': props.scrollable && props.scrollHeight === 'flex',
        }),
      }),
      loadingoverlay: {
        className: classNames(
          'fixed w-full h-full t-0 l-0 bg-gray-100/40',
          'transition duration-200',
          'absolute flex items-center justify-center z-2',
          'dark:bg-gray-950/40' // Dark Mode
        ),
      },
      loadingicon: 'w-8 h-8',
      wrapper: ({ props }) => ({
        className: classNames({
          relative: props.scrollable,
          'flex flex-col grow h-full': props.scrollable && props.scrollHeight === 'flex',
        }),
      }),
      header: ({ props }) => ({
        className: classNames(
          'bg-gray-50 text-gray-700 border-gray-300 font-bold p-4',
          'dark:border-primary-900/40 dark:text-white/80 dark:bg-gray-900', // Dark Mode
          props.showGridlines ? 'border-x border-t border-b-0' : 'border-y border-x-0'
        ),
      }),
      table: 'w-full border-spacing-0',
      thead: ({ context }) => ({
        className: classNames({
          'bg-gray-50 top-0 z-[1]': context.scrollable,
        }),
      }),
      tbody: ({ props, context }) => ({
        className: classNames({
          'sticky z-[1]': props.frozenRow && context.scrollable,
        }),
      }),
      tfoot: ({ context }) => ({
        className: classNames({
          'bg-gray-50 bottom-0 z-[1]': context.scrollable,
        }),
      }),
      footer: {
        className: classNames(
          'bg-gray-50 text-gray-700 border-t-0 border-b border-x-0 border-gray-300 font-bold p-4',
          'dark:border-primary-900/40 dark:text-white/80 dark:bg-gray-900' // Dark Mode
        ),
      },
      column: {
        headercell: ({ context, props }) => ({
          className: classNames(
            'text-left border-0 border-b border-solid border-gray-300 dark:border-primary-900/40 font-bold text-15px cursor-pointer hover:bg-gray-100 h-[64px]',
            'transition duration-200',
            context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-2 md:p-4', // Size
            context.sorted ? 'bg-primary-50 text-primary-700' : 'bg-gray-50 text-gray-700', // Sort
            context.sorted ? 'dark:text-white/80 dark:bg-primary-300' : 'dark:text-white/80 dark:bg-gray-900', // Dark Mode
            {
              'sticky z-[1]': props.frozen || props.frozen === '', // Frozen Columns
              'border-x border-y': context?.showGridlines,
              'overflow-hidden space-nowrap border-y relative bg-clip-padding': context.resizable, // Resizable
            }
          ),
        }),
        headercontent: 'flex items-center',
        bodycell: ({ props, context }) => ({
          className: classNames(
            'text-15px tracking--1_5percent text-left border-0 border-b border-solid border-gray-300 h-[64px]',
            context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-2 md:p-4', // Size
            'dark:text-white/80 dark:border-primary-900/40', // Dark Mode
            {
              'sticky bg-inherit': props && (props.frozen || props.frozen === ''), // Frozen Columns
              'border-x border-y': context.showGridlines,
            }
          ),
        }),
        footercell: ({ context }) => ({
          className: classNames(
            'text-left border-0 border-b border-solid border-gray-300 font-bold',
            'bg-gray-50 text-gray-700',
            'transition duration-200',
            context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4', // Size
            'dark:text-white/80 dark:bg-gray-900 dark:border-primary-900/40', // Dark Mode
            {
              'border-x border-y': context.showGridlines,
            }
          ),
        }),
        sorticon: ({ context }) => ({
          className: classNames(
            'ml-2 w-3 h-3',
            context.sorted ? 'text-black' : 'text-gray-700 dark:text-white/70'
          ),
        }),
        sortbadge: {
          className: classNames(
            'flex items-center justify-center align-middle',
            'rounded-[50%] w-[1.143rem] leading-[1.143rem] ml-2',
            'text-primary-700 bg-primary-50',
            'dark:text-white/80 dark:bg-primary-400' // Dark Mode
          ),
        },
        columnfilter: 'inline-flex items-center ml-auto',
        filteroverlay: {
          className: classNames(
            'bg-white text-gray-600 border-0 rounded-md min-w-[12.5rem]',
            'dark:bg-gray-900 dark:border-primary-900/40 dark:text-white/80' //Dark Mode
          ),
        },
        filtermatchmodedropdown: {
          root: 'min-[0px]:flex mb-2',
        },
        filterrowitems: 'm-0 p-0 py-3 list-none ',
        filterrowitem: ({ context }) => ({
          className: classNames(
            'm-0 py-3 px-5 bg-transparent',
            'transition duration-200',
            context?.highlighted
              ? 'text-primary-700 bg-primary-100 dark:text-white/80 dark:bg-primary-300'
              : 'text-gray-600 bg-transparent dark:text-white/80 dark:bg-transparent'
          ),
        }),
        filteroperator: {
          className: classNames(
            'px-5 py-3 border-b border-solid border-gray-300 text-gray-700 bg-gray-50 rounded-t-md',
            'dark:border-primary-900/40 dark:text-white/80 dark:bg-gray-900' // Dark Mode
          ),
        },
        filteroperatordropdown: {
          root: 'min-[0px]:flex',
        },
        filterconstraint: 'p-5 border-b border-solid border-gray-300 dark:border-primary-900/40',
        filteraddrule: 'py-3 px-5',
        filteraddrulebutton: {
          root: 'justify-center w-full min-[0px]:text-sm',
          label: 'flex-auto grow-0',
          icon: 'mr-2',
        },
        filterremovebutton: {
          root: 'ml-2',
          label: 'grow-0',
        },
        filterbuttonbar: 'flex items-center justify-between p-5',
        filterclearbutton: {
          root: 'w-auto min-[0px]:text-sm border-primary-500 text-primary-500 px-4 py-3',
        },
        filterapplybutton: {
          root: 'w-auto min-[0px]:text-sm px-4 py-3',
        },
        filtermenubutton: ({ context }) => ({
          className: classNames(
            'inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative ml-2',
            'w-8 h-8 rounded-[50%]',
            'transition duration-200',
            'hover:text-gray-700 hover:bg-gray-300/20', // Hover
            'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]', // Focus
            'dark:text-white/70 dark:hover:text-white/80 dark:bg-gray-900', // Dark Mode
            {
              'bg-primary-50 text-primary-700': context.active,
            }
          ),
        }),
        headerfilterclearbutton: ({ context }) => ({
          className: classNames(
            'inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative',
            'text-left bg-transparent m-0 p-0 border-none select-none ml-2',
            {
              invisible: !context.hidden,
            }
          ),
        }),
        columnresizer: 'block absolute top-0 right-0 m-0 w-2 h-full p-0 cursor-col-resize border border-transparent',
        rowreordericon: 'cursor-move',
        roweditorinitbutton: {
          className: classNames(
            'inline-flex items-center justify-center overflow-hidden relative',
            'text-left cursor-pointer select-none',
            'w-8 h-8 border-0 rounded-[50%]',
            'transition duration-200',
            'text-gray-700 border-transparent',
            'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]', //Focus
            'hover:text-gray-700 hover:bg-gray-300/20', //Hover
            'dark:text-white/70' // Dark Mode
          ),
        },
        roweditorsavebutton: {
          className: classNames(
            'inline-flex items-center justify-center overflow-hidden relative',
            'text-left cursor-pointer select-none',
            'w-8 h-8 border-0 rounded-[50%]',
            'transition duration-200',
            'text-gray-700 border-transparent',
            'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]', //Focus
            'hover:text-gray-700 hover:bg-gray-300/20', //Hover
            'dark:text-white/70' // Dark Mode
          ),
        },
        roweditorcancelbutton: {
          className: classNames(
            'inline-flex items-center justify-center overflow-hidden relative',
            'text-left cursor-pointer select-none',
            'w-8 h-8 border-0 rounded-[50%]',
            'transition duration-200',
            'text-gray-700 border-transparent',
            'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]', //Focus
            'hover:text-gray-700 hover:bg-gray-300/20', //Hover
            'dark:text-white/70' // Dark Mode
          ),
        },
        radiobuttonwrapper: {
          className: classNames('relative inline-flex cursor-pointer select-none align-bottom', 'w-6 h-6'),
        },
        radiobutton: ({ context }) => ({
          className: classNames(
            'flex justify-center items-center',
            'border-2 w-6 h-6 text-gray-700 rounded-full transition duration-200 ease-in-out',
            context.checked
              ? 'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400'
              : 'border-gray-300 bg-white dark:border-primary-900/40 dark:bg-gray-900',
            {
              'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]':
                !context.disabled,
              'cursor-default opacity-60': context.disabled,
            }
          ),
        }),
        radiobuttonicon: ({ context }) => ({
          className: classNames(
            'transform rounded-full',
            'block w-3 h-3 transition duration-200 bg-white dark:bg-gray-900',
            {
              'backface-hidden scale-10 invisible': context.checked === false,
              'transform scale-100 visible': context.checked === true,
            }
          ),
        }),
        headercheckboxwrapper: {
          className: classNames('cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6'),
        },
        headercheckbox: ({ context }) => ({
          className: classNames(
            'flex items-center justify-center',
            'border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
            context.checked
              ? 'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400'
              : 'border-gray-300 bg-white dark:border-primary-900/40 dark:bg-gray-900',
            {
              'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]':
                !context.disabled,
              'cursor-default opacity-60': context.disabled,
            }
          ),
        }),
        headercheckboxicon: 'w-4 h-4 transition-all duration-200 text-white text-base dark:text-gray-900',
        checkboxwrapper: {
          className: classNames('cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6'),
        },
        checkbox: ({ context }) => ({
          className: classNames(
            'flex items-center justify-center',
            'border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
            context.checked
              ? 'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400'
              : 'border-gray-300 bg-white dark:border-primary-900/40 dark:bg-gray-900',
            {
              'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]':
                !context.disabled,
              'cursor-default opacity-60': context.disabled,
            }
          ),
        }),
        checkboxicon: 'w-4 h-4 transition-all duration-200 text-white text-base dark:text-gray-900',
        transition: TRANSITIONS.overlay,
      },
      bodyrow: ({ context }) => ({
        className: classNames(
          context.selected ? 'bg-primary-50 text-primary-700 dark:bg-primary-300' : 'bg-white text-gray-600 dark:bg-gray-900',
          context.stripedRows
            ? context.index % 2 === 0
              ? 'bg-white text-gray-600 dark:bg-gray-900'
              : 'bg-white text-gray-600 dark:bg-gray-900'
            : '',
          'transition duration-200',
          'focus:outline focus:outline-[0.15rem] focus:outline-primary-200 focus:outline-offset-[-0.15rem]', // Focus
          'dark:text-white/80 dark:focus:outline dark:focus:outline-[0.15rem] dark:focus:outline-primary-300 dark:focus:outline-offset-[-0.15rem]', // Dark Mode
          {
            'cursor-pointer': context.selectable,
            'hover:bg-gray-300/20 hover:text-gray-600': context.selectable && !context.selected, // Hover
          }
        ),
      }),
      rowexpansion: 'bg-white text-gray-600 dark:bg-gray-900 dark:text-white/80',
      rowgroupheader: {
        className: classNames('sticky z-[1]', 'bg-white text-gray-600', 'transition duration-200'),
      },
      rowgroupfooter: {
        className: classNames('sticky z-[1]', 'bg-white text-gray-600', 'transition duration-200'),
      },
      rowgrouptoggler: {
        className: classNames(
          'text-left m-0 p-0 cursor-pointer select-none',
          'inline-flex items-center justify-center overflow-hidden relative',
          'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-[50%]',
          'transition duration-200',
          'dark:text-white/70' // Dark Mode
        ),
      },
      rowgrouptogglericon: 'inline-block w-4 h-4',
      resizehelper: 'absolute hidden w-px z-10 bg-primary-500 dark:bg-primary-300',
    },
    paginator: {
      root: {
        className: classNames(
          'flex items-center justify-center flex-wrap',
          'bg-transparent text-gray-500 border-0 px-4 py-6 rounded-md',
          'dark:bg-gray-900 dark:text-white/60 dark:border-primary-900/40' // Dark Mode
        )
      },

      firstpagebutton: ({ context }) => ({
        className: classNames(
          'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
          'border-0 text-gray-500  min-w-[3rem] h-12 m-[0.143rem] rounded-md',
          'transition duration-200',
          'dark:text-white', //Dark Mode
          {
            'cursor-default pointer-events-none opacity-60': context.disabled,
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]': !context.disabled // Focus
          }
        )
      }),
      previouspagebutton: ({ context }) => ({
        className: classNames(
          'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
          'border-0 text-gray-500 min-w-[3rem] h-12 m-[0.143rem] rounded-md',
          'transition duration-200',
          'dark:text-white', //Dark Mode
          {
            'cursor-default pointer-events-none opacity-60': context.disabled,
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]': !context.disabled // Focus
          }
        )
      }),
      nextpagebutton: ({ context }) => ({
        className: classNames(
          'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
          'border-0 text-gray-500 min-w-[3rem] h-12 m-[0.143rem] rounded-md',
          'transition duration-200',
          'dark:text-white', //Dark Mode
          {
            'cursor-default pointer-events-none opacity-60': context.disabled,
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]': !context.disabled // Focus
          }
        )
      }),
      lastpagebutton: ({ context }) => ({
        className: classNames(
          'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
          'border-0 text-gray-500 min-w-[3rem] h-12 m-[0.143rem] rounded-md',
          'transition duration-200',
          'dark:text-white', //Dark Mode
          {
            'cursor-default pointer-events-none opacity-60': context.disabled,
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-2': !context.disabled // Focus
          }
        )
      }),
      pagebutton: ({ context }) => ({
        className: classNames(
          'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
          'border-0 text-gray-500 min-w-[3rem] h-12 m-[0.143rem] rounded-md',
          'transition duration-200',
          'dark:border-primary-300 dark:text-white', // Dark Mode
          'focus:outline-none focus:outline-offset-0 focus:ring-0 focus:ring-offset-0', // Focus
          {
            'bg-primary-50 border-primary-50 text-black dark:bg-primary-300 font-medium': context.active
          }
        )
      }),
      RPPDropdown: {
        root: ({ props, state }) => ({
          className: classNames(
            'inline-flex relative cursor-pointer user-none',
            ' border rounded-lg',
            'h-12 mx-2 md:w-[5.5rem]'
          )
        }),
        input: {
          className: classNames(
            'font-sans text-base text-gray-600 p-3 m-0 rounded-md apperance-none',
            'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0',
            'focus:outline-none focus:outline-offset-0',
            'dark:text-white' //Dark Mode
          )
        },
        trigger: {
          className: classNames('flex items-center justify-center shrink-0', 'text-gray-500 dark:text-white w-8 rounded-r-md')
        },
        panel: {
          className: classNames(
            'bg-white text-gray-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]',
            'dark:bg-gray-900 dark:text-white/80 dark:border-primary-900/40' //Dark Mode
          )
        },
        wrapper: 'overflow-auto',
        list: 'm-0 p-0 py-3 list-none',
        item: ({ context }) => ({
          className: classNames(
            'relative font-normal cursor-pointer space-nowrap overflow-hidden',
            'm-0 py-3 px-5 border-none text-gray-600 rounded-none',
            'transition duration-200',
            'dark:text-white/80', // Dark Mode
            {
              'text-primary-700 bg-primary-50 dark:text-white/80 dark:bg-primary-300': !context.focused && context.selected,
              'bg-primary-50 text-black': context.focused && context.selected,
              'text-gray-600 bg-gray-300 dark:text-white/80 dark:bg-primary-900/40': context.focused && !context.selected
            }
          )
        })
      },
      jumptopageinput: {
        root: 'inline-flex mx-2',
        input: {
          className: classNames(
            'font-sans text-base text-gray-600 p-3 m-0 rounded-md apperance-none',
            'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border border-gray-300 pr-0',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] focus:border-primary-300',
            'dark:text-white dark:bg-gray-950 dark:border-primary-900/40', //Dark Mode
            'm-0 flex-auto max-w-[3rem]'
          )
        }
      },
      jumptopagedropdown: {
        root: ({ props, state }) => ({
          className: classNames(
            'inline-flex relative cursor-pointer user-none',
            'bg-white border rounded-md',
            'transition duration-200',
            'h-12 mx-2',
            'dark:bg-gray-950 dark:border-primary-900/40', //DarkMode
            {
              'outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] border-primary-500': state.focused && !props.disabled, //Focus
              'border-gray-300': !state.focused,
              'hover:border-primary-500': !props.disabled //Hover
            }
          )
        }),
        input: {
          className: classNames(
            'font-sans text-base text-gray-600 p-3 m-0 rounded-md apperance-none',
            'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0',
            'focus:outline-none focus:outline-offset-0',
            'dark:text-white' //Dark Mode
          )
        },
        trigger: {
          className: classNames('flex items-center justify-center shrink-0', 'text-gray-500 dark:text-white w-12 rounded-r-md')
        },
        panel: {
          className: classNames(
            'bg-white text-gray-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]',
            'dark:bg-gray-900 dark:text-white/80 dark:border-primary-900/40' //Dark Mode
          )
        },
        wrapper: 'overflow-auto',
        list: 'm-0 p-0 py-3 list-none',
        item: ({ context }) => ({
          className: classNames(
            'relative font-normal cursor-pointer space-nowrap overflow-hidden',
            'm-0 py-3 px-5 border-none text-gray-600 rounded-none',
            'transition duration-200',
            'dark:text-white/80', // Dark Mode
            {
              'text-primary-700 bg-primary-50 dark:text-white/80 dark:bg-primary-300': !context.focused && context.selected,
              'bg-primary-300/40': context.focused && context.selected,
              'text-gray-600 bg-gray-300 dark:text-white/80 dark:bg-primary-900/40': context.focused && !context.selected
            }
          )
        })
      }
    },
    calendar: {
      root: ({ props }) => ({
        className: classNames('inline-flex max-w-full relative rounded-none', {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled
        })
      }),
      input: {
        root: ({ parent }) => ({
          className: classNames('!mt-0 font-sans text-base text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-300 dark:border-primary-900/40 transition-colors duration-200 appearance-none', 'hover:border-primary-500', {
            'rounded-lg': !parent.props.showIcon,
            'border-r-0 rounded-l-lg rounded-r-none': parent.props.showIcon
          })
        })
      },
      dropdownButton: {
        root: ({ props }) => ({
          className: classNames({ 'px-3 rounded-l-none rounded-r-lg  mt-0 bg-white text-gray-500 border border-gray-300 hover:text-black hover:bg-gray-200': props.icon })
        })
      },
      panel: ({ props }) => ({
        className: classNames('max-w-md bg-white dark:bg-gray-900 rounded-xl', '', {
          'shadow-md border-0 absolute': !props.inline,
          'inline-block overflow-x-auto border border-gray-300 dark:border-primary-900/40 p-2 rounded-lg': props.inline
        })
      }),
      header: {
        className: classNames('flex items-center justify-between', 'p-2 text-gray-700  bg-neutral-50 font-semibold m-0   rounded-t-xl')
      },
      previousButton: {
        className: classNames(
          'flex items-center justify-center cursor-pointer overflow-hidden relative',
          'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
          'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
        )
      },
      title: 'leading-8 mx-auto',
      monthTitle: {
        className: classNames('text-gray-700 dark:text-white/80 transition duration-200 font-semibold p-1', 'mr-1', 'hover:text-primary-500')
      },
      yearTitle: {
        className: classNames('text-gray-700 dark:text-white/80 transition duration-200 font-semibold p-1', 'hover:text-primary-500')
      },
      nextButton: {
        className: classNames(
          'flex items-center justify-center cursor-pointer overflow-hidden relative',
          'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
          'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
        )
      },
      table: {
        className: classNames('border-collapse w-full', 'my-2')
      },
      tableHeaderCell: 'p-1',
      weekday: 'text-gray-600 dark:text-white/70',
      day: 'p-0.5',
      dayLabel: ({ context }) => ({
        className: classNames(
          'w-10 h-10 rounded-md transition-shadow duration-200 border-transparent border',
          'flex items-center justify-center mx-auto overflow-hidden relative',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)]',
          {
            'opacity-60 cursor-default': context.disabled,
            'cursor-pointer': !context.disabled
          },
          {
            'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
            'text-white bg-primary-500 hover:bg-primary-500': context.selected && !context.disabled
          }
        )
      }),
      monthPicker: 'my-2',
      month: ({ context }) => ({
        className: classNames(
          'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
          'p-1 transition-shadow duration-200 rounded-lg',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
          { 'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled, 'text-primary-700 bg-primary-100 hover:bg-primary-200': context.selected && !context.disabled }
        )
      }),
      yearPicker: {
        className: classNames('my-2')
      },
      year: ({ context }) => ({
        className: classNames(
          'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
          'p-1 transition-shadow duration-200 rounded-lg',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(85,113,34,.1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
          {
            'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
            'text-primary-700 bg-primary-100 hover:bg-primary-200': context.selected && !context.disabled
          }
        )
      }),
      timePicker: {
        className: classNames('flex justify-center items-center', 'border-t-1 border-solid border-gray-300 p-2')
      },
      separatorContainer: 'flex items-center flex-col px-2',
      separator: 'text-xl',
      hourPicker: 'flex items-center flex-col px-2',
      minutePicker: 'flex items-center flex-col px-2',
      ampmPicker: 'flex items-center flex-col px-2',
      incrementButton: {
        className: classNames(
          'flex items-center justify-center cursor-pointer overflow-hidden relative',
          'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
          'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
        )
      },
      decrementButton: {
        className: classNames(
          'flex items-center justify-center cursor-pointer overflow-hidden relative',
          'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
          'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
        )
      },
      groupContainer: 'flex',
      group: {
        className: classNames('flex-1', 'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:border-l-0')
      },
      transition: TRANSITIONS.overlay
    },
    tag: {
      root: ({ props }) => ({
        className: classNames(
          'inline-flex items-center justify-center',
          'bg-blue-500 text-white text-xs font-semibold px-2 h-6  uppercase ',
          {
            'bg-gray-500 ': props.severity == 'secondary',
            'bg-primary-500 ': props.severity == 'success',
            'bg-neutral-400 ': props.severity == 'info',
            'bg-orange-500 ': props.severity == 'warning',
            'bg-purple-500 ': props.severity == 'help',
            'bg-red-500 ': props.severity == 'danger'
          },
          {
            'rounded-md': !props.rounded,
            'rounded-full': props.rounded
          }
        )
      }),
      value: 'leading-6',
      icon: 'mr-1 text-sm'
    },

  },
  ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge },
};
