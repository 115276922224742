import { Appl } from "../../../../core/Appl";
import DeathReportViewModel from "./deathReport/addEdit/DeathReportViewModel";
import DeathReportListViewModel from "./deathReport/list/DeathReportListViewModel";
import { DeathService } from "./DeathService";

export default class DeathModule {
    public static init(): void {
        Appl.Services.add('IDeathService', DeathService);
        Appl.Services.add('DeathReportListViewModel', DeathReportListViewModel);
        Appl.Services.add('DeathReportViewModel', DeathReportViewModel);
    }
}