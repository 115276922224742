import React from 'react';
import { observer } from 'mobx-react';
import SraModule from '../SraModule';
import { View, ViewHeader } from '../../../../core/components/Index';

export const PlanningCheckListView: React.FC = observer(() => {
  return (
    <>
      <View id="PlanningCheckListView" authorized={true} showMenuBar={false}
        menuItems={SraModule.MenuItems()}>
        <ViewHeader
          title="RETIREMENT PLANNING CHECKLIST"
          description="RETIREMENT PLANNING CHECKLIST"
        />
        <p><b>Twelve Months Prior to Retirement Date</b></p>
        <ul className="list-disc ml-4 pb-6">
          <li>Use the Benefit Calculator at www.scers.org to create your retirement benefit estimate.</li>
          <li>If you are purchasing additional service credit, submit to SCERS a written Request for Service Purchase Calculation.</li>
          <li>Contact the Social Security Administration for Social Security benefit information.</li>
          <li>
            Contact SCERS if you have a Community Property issue. A copy of the domestic relations order (DRO) must be on file with SCERS
            prior to any retirement payments. Delays in resolving your community property issues and submitting the required documents to
            SCERS will delay your retirement process
          </li>
        </ul>
        <p><b>Six Months Prior to Retirement Date</b></p>
        <ul className="list-disc ml-4 pb-6">
          <li>
            Submit to SCERS copies of birth certificates for you and any named beneficiaries, yourmarriage certificate, or your registered
            domestic partnership certificate.
          </li>
          <li>
            Are you a reciprocal member? Contact other public retirement systems to which you are a member for information concerning their
            benefits and retirement processes.
          </li>
        </ul>
        <p><b>Three Months Prior to Retirement Date</b></p>
        <ul className="list-disc ml-4 pb-6">
          <li>Obtain a service retirement application packet available at www.scers.org.</li>
          <li>
            Contact SCERS if you have existing payroll deductions to purchase additional service credit that will not be completed before your
            retirement effective date.
          </li>
        </ul>
        <p><b>At Least Two Months Prior to Retirement Date</b></p>
        <ul className="list-disc ml-4 pb-6">
          <li>Complete the required and optional forms (if applicable) contained in the service retirement application packet.</li>
          <li>
            Submit your completed application to SCERS. Your retirement application may be submitted to SCERS no earlier than 60 days prior to
            your retirement effective date.
          </li>
          <li>
            Are you a reciprocal member? To retain reciprocal benefits you must retire concurrently by submitting a retirement application
            with all other public retirement systems for which you are a member.
          </li>
        </ul>
        <p><b>At Least One Month Prior to Retirement Date</b></p>
        <ul className="list-disc ml-4 pb-6">
          <li>Attend a retirement orientation.</li>
          <li>Complete and sign your option election form – normally done during the retirement orientation.</li>
          <li>Submit any additional forms not already submitted.</li>
        </ul>
        <p><b>Your Retirement Date</b></p>
        <ul className="list-disc ml-4 pb-6">
          <li>Congratulations on your retirement and thank you for you service!</li>
          <li>
            SCERS aims to pay the first retirement check within 45 days of your retirement date, however it may take longer under certain
            circumstances and during high-volume retirement periods.
          </li>
        </ul>
      </View>
    </>
  );
});
