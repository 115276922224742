import { Dropdown, DropdownProps } from 'primereact/dropdown';
import React from 'react';
import { observer } from 'mobx-react';
import './InputDropdown.css';
import { Icon } from '../Icon';
import { InputErrors } from './InputErrors';
import { Appl } from '../../../core/Appl';

export interface DropdownExProps extends DropdownProps {
  label?: string;
  helpText?: string;
  suffixid?: string;
}

export const InputDropdown: React.FC<DropdownExProps> = observer(({ ...props }) => {

  if (props.readOnly) {
    props.className = props.className + ' !bg-gray-100 !cursor-not-allowed';
  }
  function getLabel(label?: string) {
    if (label && label !== '') {
      return (
        <label className='font-bold text-15px text-black mb-1 block'>
          {props.label}
          {props.required && <span style={{ color: 'red' }}> * </span>}
          {props.helpText && <Icon name="question-circle" color="black" size={16} />}
        </label>
      )
    } else {
      return <></>;
    }
  }

  return (
    <>
      {getLabel(props.label)}
      {props.disabled ? (
        <>
          <div>{props.value?.toString()}</div>
        </>
      ) : (
        <>
          <Dropdown
            className={props.className}
            id={props.id}
            inputId={props.id}
            value={props.value}
            placeholder={props.placeholder}
            options={props.options}
            optionValue={props.optionValue}
            optionLabel={props.optionLabel}
            readOnly={props.readOnly}
            disabled={props.readOnly}
            data-val-field-label={`${props.id}-feedback`}
            {...props}
          />
          <InputErrors errors={Appl.Error?.items} viewId={props?.id} suffixid={props.suffixid} />
        </>
      )}
    </>
  );
});
