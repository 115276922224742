import { NavItem } from '../../../../core/services/Models';
import { Appl } from '../../../../core/Appl';
import { MemberFileService } from './MemberFileService';
import MemberLookupViewModel from './invite/docUpload/MemberLookupViewModel';
import UploadRequestListViewModel from './list/UploadRequestListViewModel';

export default class MemberFileModule {

  public static MenuItems: Array<NavItem> = [
    { id: 'members-upload-requests', label: 'Secure File Upload Queue', path: '/members/upload-requests' },
    { id: 'members-invite', label: 'Secure File Upload Request Form', path: '/members/invite/doc-upload' }
  ]

  // public static SidebarMenuItems: Array<NavItem> = [
  //   { text: 'Secure File Upload Queue', href: '/members/upload-requests' },
  //   { text: 'Secure File Upload Request Form', href: '/members/invite/doc-upload' }
  // ]

  public static init(): void {
    Appl.Services.add('IMemberFileService', MemberFileService);
    Appl.Services.add('MemberLookupViewModel', MemberLookupViewModel);
    Appl.Services.add('UploadRequestListViewModel', UploadRequestListViewModel);
  }
}
