import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from '../Icon';
import { Input, InputType } from './Inputs';
import { InputErrors } from './InputErrors';
import { Appl } from '../../../core/Appl';

interface DateProps {
  id: string;
  label?: string;
  year?: number;
  month?: number;
  day?: number;
  helpText?: string;
  hidden?: boolean;
  value?: Date;
  className?: string;
  suffixid?: string;
  required?: boolean;
  disabled?: boolean;
}

export const InputDate: React.FC<DateProps> = observer((props) => {
  let {
    id,
    label,
    helpText,
    hidden,
    year,
    month,
    day,
    value,
    // onChange,
    className = '',
    suffixid,
    ...rest
  } = props;

  const renderLabel = () => {
    if (label && !hidden) {
      return (
        <label htmlFor={`cal-${id}`} className="form-label">
          {label}
          {props.required && <span style={{ color: 'red' }}> * </span>}
          {helpText && <Icon name="question-circle" />}
        </label>
      );
    }
    return null;
  };

  const onMonthChange = (e: any) => {
    return month = e.value;
  };

  const onDayChange = (e: any) => {
    return day = e.value;
  };
  const onYearChange = (e: any) => {
    return year = e.value;
  };

  return (
    <div>
      {renderLabel()}
      {props.disabled ? (
        <div>{month?.toString()}/{day?.toString()}/{year?.toString()}</div>
      ) : (
        <>
          <div className="flex gap-x-3 items-center max-w-md">
            <Input
              id="month"
              value={month}
              inputType={InputType.Number}
              placeholder="MM"
              maxLength={2}
              onChange={(e: any) => onMonthChange(e)}
              required={true}
            />
            <div>/</div>
            <Input
              id="day"
              value={day}
              inputType={InputType.Number}
              placeholder="DD"
              onChange={(e: any) => onDayChange(e)}
              required={true}
              maxLength={2}
            />
            <div>/</div>
            <Input
              id="yaer"
              value={year}
              inputType={InputType.Number}
              placeholder="YYYY"
              onChange={(e: any) => onYearChange(e)}
              required={true}
              maxLength={4}
            />
          </div>
          <InputErrors errors={Appl.Error?.items} viewId={id} suffixid={suffixid} />
        </>
      )}
    </div>
  );
});
