import { ListItem } from "../../../../../core/services/BaseService";
import { DocumentsModel } from "../../../../../core/components/UploadUtility";
import { StepStatus } from "./SidebarFormNavigation";

export enum Steps {
    GettingStarted = 'getting-started',
    RetirementDate = 'retirement-date',
    SelectDate = 'select-date',
    PersonalInformation = 'your-information',
    ConfirmYourInformation = 'confirm-your-information',
    AddressVerification = 'address-verification',
    ContactInformation = 'contact-information',
    PersonalStatus = 'personal-status',
    BeneficiaryDesignation = 'beneficiary-designation',
    ManageBeneficiary = 'manage-beneficiary',
    MemberDeclaration = 'member-declaration',
    TaxWithholding = 'tax-withholding',
    FederalTaxWithholding = 'federal-tax-withholding',
    StateTaxWithholding = 'state-tax-withholding',
    DeclarationTaxWithholding = 'declaration-tax-withholding',
    DirectDeposit = 'direct-deposit',
    DirectDepositAuthorization = 'direct-deposit-authorization',
    SickLeave = 'sick-leave',
    DispositionOfAccruedSickLeave = 'disposition-of-accrued-sick-leave',
    ScreaMembership = 'screa-membership',
    MembershipApplication = 'membership-application',
    ServiceRetirementSignOff = 'service-retirement-sign-off',
    ServiceRetirementChecklist = 'service-retirement-check-list',
    ReviewApplication = 'review-application',
    UploadDocuments = 'upload-documents',
    SubmitApplication = 'submit-application'
}

export interface StepInfoModel {
    slug: string,
    status: StepStatus;
    nextButtonLabel: string;
    cancelButtonLabel: string;
    hideNextButton: boolean,
    hideCancelButton: boolean
}
export interface SubmissionModel {
    submissionId?: number;
    submissionDate?: Date;
    lovSubmissionStatusId?: number
    submissionStatusDesc?: string;
    submissionType?: string;
}

export interface AppForRetirementModel {
    submissionId: number;
    submissionStatusId: number;
    stepStatus: StepStatuses;
    personalInformation: PersonalInformationModel,
    beneficiaryDesignation: BeneficiaryDesignationModel,
    taxWithHolding: TaxWithHoldingModel,
    directDeposit: DirectDepositModel;
    accruedSickLeave: AccruedSickLeaveModel;
    screaMembership: ScreaMembershipModel;
    signOffModels: Array<ServiceRetirementSignOffModel>
    uploadedDocuments: Array<DocumentsModel>,
    phoneTypeListItems?: Array<ListItem>,
    personalStatusListItems?: Array<ListItem>,
    beneficiaryRelationListItems?: Array<ListItem>,
    withholdFederalIncomeTaxStatusListItems?: Array<ListItem>,
    withholdStateIncomeTaxStatusListItems?: Array<ListItem>,
    typeOfDepositListItems?: Array<ListItem>,
    associateMemberTypeListItems?: Array<ListItem>,
    documentList?: Array<ListItem>

}

export interface PersonalInformationModel {
    submissionId: number;
    retirementDate?: Date;

    personalInfoVerified?: string
    firstName?: string;
    lastName?: string;
    middleName?: string;
    birthDate?: Date;
    birthMonth?: number;
    birthDay?: number;
    birthYear?: number;
    ssn?: number;
    ssnMasked?: string;
    employer?: string;
    department?: string;
    retirementEffMonth?: number;
    retirementEffDay?: number;
    retirementEffYear?: number;
    retirementEffDate?: Date;
    email?: string;
    phone?: string;
    phoneType?: string;
    alternatePhone?: string;
    alternatePhoneType?: string;
    lovMembershipId?: number;
    membershipCd?: string;
    membershipDesc?: string;

    lovPersonalStatusId?: number;
    personalStatusCd?: string;
    lovPersonalStatusDesc?: string;

    validateAddress?: boolean
    homeRawAddress?: string;
    homeAddress?: string;
    homeCity?: string;
    homeState?: string;
    homeZip?: number;
    homeCountry?: string;

    mailingRawAddress?: string;
    mailingAddress?: string;
    mailingCity?: string;
    mailingState?: string;
    mailingZip?: number;
    mailingCountry?: string;
}

export interface BeneficiaryDesignationModel {
    submissionId?: number;
    afrMbrAffidavitId?: number;
    afrDecleration: boolean;
    beneficiaries?: Array<BeneficiaryModel>;
}

export interface BeneficiaryModel {
    afrBeneId?: number;
    afrMbrAffidavitId?: number;
    beneFirstName?: string;
    beneMiddleName?: string;
    beneLastName?: string;
    beneSsn?: string;
    beneBirthDate?: Date;
    beneBirthDateYear?: number;
    beneBirthDateMonth?: number;
    beneBirthDateDay?: number;
    beneRelationship?: string;
    percentage?: number;
    beneStreetAddress?: string;
    beneCity?: string;
    beneState?: string;
    beneZip?: string;
    beneCountry?: string
}

export interface TaxWithHoldingModel {
    submissionId?: number;
    afrTaxWithholdId?: number;
    taxwithholdDecleration: boolean;
    federalTaxWithHolding?: FederalTaxWithHoldingModel;
    stateTaxWithHolding?: StateTaxWithHoldingModel;
}

export interface FederalTaxWithHoldingModel {
    submissionId?: number;
    afrTaxWithholdId?: number;
    federalWithholdIncomeTax: boolean;
    federalDonotWithholdIncomeTax: boolean;
    federalWithholdIncomeTaxStatus?: string;
    federalClaimsDependant?: number;
    federalDeductionsClaimed?: number;
    federalAdditionalWithold?: number;
    federalIncomeNotFromJob?: number;
}

export interface StateTaxWithHoldingModel {
    submissionId?: number;
    afrTaxWithholdId?: number;
    stateWithholdIncomeTax: boolean;
    stateDonotWithholdIncomeTax: boolean;
    stateWithholdIncomeTaxStatus?: string;
    stateMarriedWithholdIncomeAllowance?: number;
    stateSingleWithholdIncomeAllowance?: number;
    stateHeadOfHouseHoldIncomeAllowance?: number;
    stateAdditionalTaxToWithheldBasedOnTax?: number;
    stateAdditionalTaxToWithheldBasedOnBenefitPayment?: number;
}

export interface DirectDepositModel {
    submissionId?: number;
    afrDirectDepositId?: number;
    institutionName?: string;
    lovAccountTypeId?: number;
    bankRoutingNumeric?: number
    bankAccountNumeric?: string
    directDepositConsent: boolean
}

export interface AccruedSickLeaveModel {
    afrAccruedSickLeaveId?: number;
    submissionId?: number;
    sickLeaveCredit: boolean;
    sickLeaveCreditN: boolean;
    sickLeaveHours?: number;
}

export interface ScreaMembershipModel {
    afrAccruedSickLeaveId?: number;
    submissionId?: number;
    regularMembership: boolean;
    associateMembership: boolean;
    acceptMembershipConsent: boolean;
    associateMbrType?: string;
    associateMbrName?: string;
}

export interface ServiceRetirementSignOffModel {
    submissionId?: number;
    afrServiceSignOffId?: number;
    active: boolean
    lovServiceSignOffId?: number;
    lovServiceSignOffCd?: string;
    lovServiceSignOffDesc?: string
}

export interface StepStatuses {
    gettingStarted?: string;
    retirementDate?: string;
    selectDate?: string;
    personalInformation?: string;
    confirmYourInformation?: string;
    addressVerification?: string;
    contactInformation?: string;
    personalStatus?: string;
    beneficiaryDesignation?: string;
    manageBeneficiary?: string;
    memberDeclaration?: string;
    taxWithholding?: string;
    federalTaxWithholding?: string;
    stateTaxWithholding?: string;
    declarationTaxWithholding?: string;
    directDeposit?: string;
    directDepositAuthorization?: string;
    sickLeave?: string;
    dispositionOfAccruedSickLeave?: string;
    screaMembership?: string;
    membershipApplication?: string;
    serviceRetirementSignOff?: string;
    serviceRetirementChecklist?: string;
    reviewApplication?: string;
    uploadDocuments?: string;
    submitApplication?: string;
}
