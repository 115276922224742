import { RouteObject } from "react-router-dom";
import { Appl } from "../../../../core/Appl";
import ChangeOfAddressViewModel from "./addEdit/ChangeOfAddressViewModel";
import { ChangeOfAddressService } from "./ChangeOfAddressService";
import ChangeOfAddressListViewModel from "./list/ChangeOfAddressListViewModel";

export default class ChangeOfAddressModule {
    public static init(): void {
        Appl.Services.add('IChangeOfAddressService', ChangeOfAddressService);
        Appl.Services.add('ChangeOfAddressListViewModel', ChangeOfAddressListViewModel);
        Appl.Services.add('ChangeOfAddressViewModel', ChangeOfAddressViewModel);

    }
}