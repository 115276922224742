import { AuthenticationResult, Configuration, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { IUserContext, Permission, UserInfo } from './IUserContext';
import { UserContextBase } from './UserContextBase';
import { ServerResponse } from './BaseService';
import { Appl } from '../Appl';
import { jwtDecode } from 'jwt-decode';
export class UserContextAzure extends UserContextBase implements IUserContext {

    private static _instance: UserContextAzure;
    private static _msalInstance?: IPublicClientApplication;
    public static get instance() {
        if (!this._instance) {
            this._msalInstance = this.getMsalInstance()
            return this._instance = new UserContextAzure();
        }
        return this._instance;
    }

    private static scope?: { scopes: string[] };

    public async initAsync(): Promise<void> {
        let sessionStarted = this._cache.getCookie<boolean>("userSession");
        if (!sessionStarted) {
            console.log('UserContextAzure Init Async - Azure Inside');
            this._cache.removeAllCache();
            this._cache.addOrUpdateCookie<boolean>("userSession", true);
            window.location.reload();
        }
        await UserContextAzure._msalInstance?.initialize();
        // UserContextAzure._msalInstance?.enableAccountStorageEvents();
        // UserContextAzure._msalInstance?.addEventCallback((event: EventMessage) => {
        //     if (event.eventType === EventType.LOGIN_SUCCESS ||
        //         event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        //         event.eventType === EventType.SSO_SILENT_SUCCESS) {
        //         const payload = event.payload as AuthenticationResult;
        //         this._cache.addOrUpdate(this.tokenKey, payload?.accessToken);
        //         const account = payload.account;
        //         console.log(`0. Sign In to Azure - Login Success ${payload.accessToken}`);
        //         UserContextAzure._msalInstance?.setActiveAccount(account);
        //     }
        // });
    }

    public async signInAsync(username?: string, password?: string): Promise<ServerResponse> {
        console.log(`1. Sign In to AzureAD`);
        const usePopup = true;
        Appl.Cache.addOrUpdate("AuthProvider", "AzureAD");
        try {
            let instance = UserContextAzure._msalInstance;
            await instance?.initialize();
            if (usePopup) {
                let authResult = await instance?.loginPopup(UserContextAzure.scope)
                if (authResult?.account) {
                    await this.storeUserInfo(authResult?.accessToken, true);
                    return {
                        success: true,
                        data: ''
                    };
                } else {
                    return {
                        success: false,
                        data: 'Error Logging...'
                    };
                }
            }
            await instance?.handleRedirectPromise();
            await instance?.loginRedirect(UserContextAzure.scope);
            console.log(`1.1. After Sign In to Azure`);
            return {
                success: true,
                data: ''
            };
        } catch (e: any) {
            console.log(`1.2. Sign In to Azure Error: ${e}`);
            return {
                success: false,
                data: e
            };
        }
    }

    public async signOutAsync(): Promise<void> {
        Appl.Cache.removeAllCache();
        let instance = UserContextAzure._msalInstance;
        await instance?.handleRedirectPromise();
        await instance?.logout();
    }
    public getUser(): UserInfo {
        const user = this._cache.get<UserInfo>(this.userInfoKey) as UserInfo;
        if (user) {
            return user;
        }
        console.log(`2.1 Token Not Exists`);
        let instance = UserContextAzure._msalInstance;
        instance?.initialize().then(async () => {
            await instance?.handleRedirectPromise();
            const accounts = instance?.getAllAccounts();
            if (accounts?.length === 0) {
                console.log(`2.2 Account Not Exists`);
                return user;
            }
            if (accounts) {
                console.log(`2.3 Account Exists`);
                let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "AzureAD");
                let scopes = authSetting ? authSetting.Scopes : [];
                const request = {
                    scopes: scopes,
                    account: accounts[0]
                };
                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                let authResult: AuthenticationResult | undefined = await instance?.acquireTokenSilent(request);
                this.storeUserInfo(authResult?.accessToken!, true);
                console.log(`2.4 Aqquired the Access Token with State: ${authResult?.state} and Token ${authResult?.accessToken}`);
            }
        })
        return user;
    }

    private static getMsalInstance(): IPublicClientApplication {
        let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "AzureAD");
        this.scope = {
            scopes: authSetting?.Scopes!
        }
        const msalConfig: Configuration = {
            auth: {
                clientId: authSetting?.ClientId!,
                authority: authSetting?.AuthorityUrl,
                redirectUri: authSetting?.LoginRedirectUrl,
                postLogoutRedirectUri: authSetting?.LogoutUrl,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            }
        }
        let msalInstance = new PublicClientApplication(msalConfig);
        return msalInstance;
    }

    private async storeUserInfo(accessToken: string, requiresPermissions: boolean): Promise<void> {
        let user = {
            userId: undefined, email: undefined,
            displayName: undefined, photo: undefined, roles: new Array<string>,
            permissions: new Array<Permission>, accessToken: undefined
        } as UserInfo;
        let accountInfo = jwtDecode(accessToken) as any;
        user.userId = accountInfo.unique_name;
        user.email = accountInfo.unique_name;
        user.displayName = `${accountInfo.given_name} ${accountInfo.family_name}`;
        user.accessToken = accessToken;
        await Appl.User.saveUserAsync(user);
        if (requiresPermissions) {
            let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "AzureAD");
            const meUrl = `${authSetting?.UserInfoUrl!}/${user.userId}`
            const request = new Request(meUrl, {
                method: "GET",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const meResponse = await fetch(request)
            if (meResponse.ok) {
                let userData = await meResponse.json();
                await Appl.User.saveUserAsync(userData);
            } else {
                throw new Error(meResponse.statusText);
            }
        }
    }
}

