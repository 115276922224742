import { Service } from 'typedi';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { action, makeObservable, observable, runInAction } from 'mobx';
import Helper from '../../../../../core/services/Helper';
import { DocumentDetailModel, ResendUploadFileInvitationModel, UploadRequestListModel } from './UploadRequestListModel';
import { IMemberFileService } from '../MemberFileService';
import { Appl } from '../../../../../core/Appl';

@Service() // eslint-disable-next-line
export default class UploadRequestListViewModel extends BaseListViewModel<UploadRequestListModel> {
  private service: IMemberFileService;

  @observable public documentListDialogVisible = false;
  @observable public documentDetailsModel: Array<DocumentDetailModel> = new Array<DocumentDetailModel>();
  @observable public reSendModel: ResendUploadFileInvitationModel;
  @observable public selectAllDocs = false;

  constructor() {
    super('Secure File Upload Queue', 'requestId', false);
    this.service = Appl.Services.get<IMemberFileService>('IMemberFileService');
    this.reSendModel = { requestId: 0 }
    makeObservable(this);
  }

  public async getPagedAsync(): Promise<void> {
    const response = await this.service.getPagedAsync('/members/member/search', this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

  @action public async showDocumentListDialog(row: UploadRequestListModel): Promise<void> {
    this.documentListDialogVisible = true;
    this.selectAllDocs = false;
    this.documentDetailsModel = [];
    this.selectedRow = row;
    const result = await this.service?.getRequestedFilesAsync(row.requestId!);
    if (result.success) {
      runInAction(() => {
        this.documentDetailsModel = result.data as Array<DocumentDetailModel>;
      })
    }
  }

  @action public async hideDocumentListDialog(): Promise<void> {
    this.documentListDialogVisible = false;
  }

  @action public async onSelectUnSelectAll(selected: boolean): Promise<void> {
    this.selectAllDocs = selected;
    this.documentDetailsModel.map((document: DocumentDetailModel, index) => {
      if (document.virusScanStatus === "C") {
        document.selected = selected;
      }
    });
  }

  @action public async downloadDocumentAsync(docDtlId: number, fileName: string): Promise<void> {
    const result = await this.service?.downloadAsync(`/members/member/download/${docDtlId}`);
    if (result.success) {
      Helper.downloadFile(result.data, fileName, '');
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action public async updateRequestStatusAsync(status: string): Promise<void> {
    this.selectedRow.reqStatus = status;
    const result = await this.service?.updateRequestStatusAsync(this.selectedRow.requestId, status);
    if (!result.success) {
      Appl.Error.handle(result.error);
    }
  }

  @action public async downloadDocumentsAsync(): Promise<void> {
    let docDtlIds = '';
    let docCount = 0;
    let fileName = "Documents.zip";
    this.documentDetailsModel.map((document: DocumentDetailModel, index) => {
      if (document.selected) {
        docDtlIds += `${document.docDtlId}|`;
        fileName = document.docFilename!;
        docCount++;
      }
    });
    if (docDtlIds === '') {
      this.showWarning("Please select minimum one document!");
      return;
    }
    if (docCount > 1) {
      fileName = "Documents.zip";
    }
    const result = await this.service?.downloadAsync(`/members/member/downloadAsZip/${docDtlIds}`);
    if (result.success) {
      Helper.downloadFile(result.data, fileName, '');
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action public async sendInvitationAsync(row: UploadRequestListModel): Promise<void> {
    this.selectedRow = row;
    this.reSendModel.requestId = row.requestId;
    this.confirmDialogOperation = 'SEND_INVITATION';
    this.confirmDialogMessage = "Would you like to resend the file upload invitation to the requester?"
    this.showConfirmDialog = true;
  }

  @action public async onConfirmDialogAsync(): Promise<void> {
    if (this.confirmDialogOperation === 'SEND_INVITATION') {
      const result = await this.service?.reSendMemberFileUploadInvitationAsync(this.reSendModel);
      if (result.success) {
        await this.getPagedAsync();
      } else {
        Appl.Error.handle(result.error);
      }
    }
    this.showConfirmDialog = false;
  }

  @action public async exportCSV(): Promise<void> {
    this.searchRequest = { skip: 0, take: 10000, sortColumn: this.searchRequest!.sortColumn, sortAscending: this.searchRequest!.sortAscending, filter: '' };
    const result = await this.service.getUploadQueueAsFileAsync("CSV", this.searchRequest);
    if (result.success) {
      Helper.downloadFile(result.data, 'Secure_file_upload.csv', 'text/csv');
    } else {
      Appl.Error.handle(result.error);
    }
  }

}
