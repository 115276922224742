import { Appl } from '../../core/Appl';
import { PublicService } from './PublicService';
import HomeViewModel from './home/HomeViewModel';

export default class PublicModule {
  public static init(): void {
    Appl.Services.add('IPublicService', PublicService);
    Appl.Services.add('HomeViewModel', HomeViewModel);
  }
}
