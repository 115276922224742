import React from 'react';
import { observer } from 'mobx-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HeaderView } from './modules/common/HeaderView';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'primereact/resources/primereact.min.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { SCERSTheme } from './SCERSPrimeReactTheme.jsx';
import './index.css';

// Open Sans Variable Font 300-800
import '@fontsource-variable/open-sans';
import { AppConfig } from './config/AppConfig';
import useAsyncEffect from 'use-async-effect';
import { Appl } from './core/Appl';
import { AlertBox, AlertPlacement } from './core/components/dialogs/AlertBox';
import { Spinner } from './core/components/dialogs/Spinner';
import { ConfirmBox } from './core/components/dialogs/ConfirmBox';
import { MessageBox } from './core/components/dialogs/MessageBox';
import SidebarViewNavigation from './modules/common/SidebarViewNavigation';
import { Role } from './core/services/Enums';

AppConfig.init();
library.add(fab, fas, far);

const Footer = () => <div className="h-0" />;
const App: React.FC = observer(() => {
  useAsyncEffect(async () => {
    Appl.Logger.logInfo(`App Version: ${Appl.Setting.AppVersion}`);
    await Appl.User.initAsync();
  }, []);

  const [menuCollapsed, setMenuCollapsed] = React.useState(false);

  return (
    <PrimeReactProvider value={SCERSTheme as any}>
      <HeaderView />
      <div
        className="mt-[66px] group/sidebar-wrapper flex min-h-svh-header w-full has-[[data-variant=inset]]:bg-sidebar "
        style={
          {
            '--sidebar-width': '16rem',
            '--sidebar-width-icon': '4rem',
          } as React.CSSProperties
        }>
        {Appl.User.isAuthenticated && Appl.User.hasRole(Role.Admin) &&
          <>
            <div className={`flex-shrink-0 duration-200 h-[calc(100vh-66px)] relative ${menuCollapsed ? 'w-[--sidebar-width-icon]' : 'w-[--sidebar-width]'} hidden md:block  transition-[width] ease-linear`}></div>
            <SidebarViewNavigation collapsed={menuCollapsed} setCollapsed={setMenuCollapsed} />
          </>
        }
        <div className="relative flex min-h-svh-header flex-1 flex-col overflow-hidden">
          <Spinner />
          <AlertBox placement={AlertPlacement.TopRight} />
          <ConfirmBox />
          <MessageBox />
          <Outlet />
          <Footer />
        </div>
      </div>
    </PrimeReactProvider >
  );
});

export default App;
