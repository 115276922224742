import './PdfViewer.css';
import { observer } from 'mobx-react';
type PdfProps = {
  data?: any;
};

export const PdfViewer: React.FC<PdfProps> = observer((props: { data?: any }) => {
  function url() {
    let blob = new Blob([props.data], { type: 'application/pdf' });
    let blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }
  return (
    <>
      {props.data && <iframe src={url()} className="pdf-viewer-container" />}
    </>
  );
});


