import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { ForgotPassowrdModel } from './ForgotPassowrdModel';
import { IAccountService } from '../AccountService';
import { Appl } from '../../../../core/Appl';

@Service()
export default class ForgotPasswordViewModel extends BaseViewModel {
  @observable public model: ForgotPassowrdModel = new ForgotPassowrdModel();
  private _service: IAccountService;
  constructor() {
    super();
    makeObservable(this);
    this._service = Appl.Services.get<IAccountService>('IAccountService');
  }

  @action public async loadAsync() {
    this.pageTitle = 'Forgot Password';
  }

  @action public async loginAsync() {
    this.navigate('LoginView');
  }

  @action public async helpAsync() {
    this.navigate('HelpView');
  }

  @action public async forgotPassowrdAsync() {
    this.navigate('ForgotPasswordView');
  }

  @action public async registerAsync() {
    this.navigate('RegisterView');
  }
}
