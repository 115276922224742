import React from 'react';
import { observer } from 'mobx-react';
import { View, ViewHeader } from '../../../../core/components/Index';
import SraModule from '../SraModule';

export const RetirementConsiderationsView: React.FC = observer(() => {
  return (
    <>
      <View id="RetirementConsiderationsView" authorized={true}
        showMenuBar={false} menuItems={SraModule.MenuItems()}>
        <ViewHeader
          title="CONSIDERATIONS FOR SETTING A RETIREMENT DATE"
          description="CONSIDERATIONS FOR SETTING A RETIREMENT DATE"
        />
        <p className='pb-4'>When contemplating retirement, there are factors you should consider during your planning:</p>
        <ul className="list-disc ml-4 pb-6">
          <li>Your retirement date cannot be effective until the day after your last day of paid employment.</li>
          <li>Your retirement date cannot be earlier than the date SCERS receives your application.</li>
          <li>
            It is your responsibility to coordinate your retirement and resignation from your employer. As SCERS processes your retirement, and when
            necessary, SCERS will contact your employer for any necessary information to facilitate and administer your retirement process.
          </li>
          <li>
            You can receive an incremental age factor adjustment for each quarter of a year increase in your age, depending on your benefit tier.
          </li>
          <li>
            The annual retiree cost of living adjustment (COLA) is effective on April 1st of each year. As long as you are retired prior to April 1st,
            you will receive the appropriate COLA associated with your respective benefit tier. Note: the 2-3 months prior to April of each year are
            typically high-volume months and you may experience delays in the processing and payment of your first retirement check.
          </li>
          <li>If you are purchasing service credit, be sure to factor in the time necessary to facilitate your purchase.</li>
          <li>
            If you are divorced or have a community property issue you should contact SCERS early in your retirement process. A copy of the domestic
            relations order (DRO) must be on file with SCERS prior to any retirement payments. Delays in resolving your community property issue and
            submitting the required documents to SCERS will delay your retirement process.
          </li>
          <li>
            If you are planning to make a major purchase that requires income verification, such as buying a new home, you should factor in adequate
            time for SCERS to process your retirement. SCERS cannot provide written income verification notices until after your first pension payment
            has been issued. While SCERS aims to process your retirement timely, there are circumstances and variables that may delay the processing
            of your retirement.
          </li>
        </ul>
      </View>
    </>
  );
});
