import { observer } from 'mobx-react';
import { action, makeAutoObservable, observable } from 'mobx';
import { SpinnerIcon } from './SpinnerIcon';
import { Appl } from '../../Appl';
import "./Spinner.css"

export const Spinner: React.FC<{ width?: string }> = observer(({ width = '100px' }) => {
  const vm = Appl.Spinner;

  return (
    <>
      {vm.isbusy && (
        <div className="bg-white/10 fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center backdrop-blur-[0.5px]">
          <SpinnerIcon className="w-6 h-6 text-black" size="4" />
        </div>
      )}
    </>
  );
});

export interface ISpinnerService {
  message: string;
  isbusy: boolean;
  hide(): void;
  show(message?: string): void;
}

export class SpinnerService implements ISpinnerService {

  private static _instance: SpinnerService;
  public static get instance() {
    return this._instance ?? (this._instance = new SpinnerService());
  }

  @observable public message = '';
  @observable public isbusy = false;
  constructor() {
    makeAutoObservable(this);
  }
  @action public hide(): void {
    this.message = '';
    this.isbusy = false;
  }

  @action public show(message = 'Please Wait...'): void {
    this.message = message;
    this.isbusy = true;
  }
}
