import { Service } from 'typedi';
import { BaseAddEditViewModel } from '../../../../../../core/services/BaseViewModel';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { MemberLookupModel } from './MemberLookupModel';
import { IMemberFileService } from '../../MemberFileService';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { ListItem } from '../../../../../../core/services/BaseService';
import { Appl } from '../../../../../../core/Appl';

@Service() // eslint-disable-next-line
export default class MemberLookupViewModel extends BaseAddEditViewModel {
  private service: IMemberFileService;
  @observable public model: MemberLookupModel;
  @observable public documentTypeListItems?: Array<ListItem> = [];
  @observable public otherDocTextBoxVisible = false;
  @observable public sameAsRequesterSelected = false;
  @observable public enableSendInvitationButton = false;

  constructor() {
    super('Secure File Upload Request Form');
    this.service = Appl.Services.get<IMemberFileService>('IMemberFileService');
    this.model = {};
    makeObservable(this);
  }

  @action public async initAsync(): Promise<void> {
    let response = await this.service.getListOfValuesAsync('lov_doc_type');
    if (response.success) {
      runInAction(() => {
        this.documentTypeListItems = response.data;
      })
    } else {
      Appl.Error.handle(response.error);
    }
  }

  @action public async sendInvitationAsync(): Promise<void> {
    Appl.Validator.init();
    await Appl.Validator.validateString('First Name', 'reqFirstName', this.model.reqFirstName, true, 50);
    await Appl.Validator.validateString('Last Name', 'reqLastName', this.model.reqLastName, true, 50);
    await Appl.Validator.validateEmail('Email', 'reqEmail', this.model.reqEmail, true, 100);

    await Appl.Validator.validateString('First Name', 'memberFirstName', this.model.memberFirstName, true, 50);
    await Appl.Validator.validateString('Last Name', 'memberLastName', this.model.memberLastName, true, 50);
    await Appl.Validator.validateEmail('Email', 'memberEmail', this.model.memberEmail, false, 100);
    await Appl.Validator.validateNumber('SSN', 'mbrSsn', this.model.mbrSsn, false, 10000);

    if (!this.model.requestedDocuments || this.model.requestedDocuments?.length === 0) {
      Appl.Validator.setError('requestedDocuments', 'Please select documents to be uploaded.')
    } else {
      if (this.model.requestedDocuments.indexOf('Other') > -1) {
        await Appl.Validator.validateString('Other Document Name', 'otherDocumentName', this.model.otherDocumentName, true, 100);
      }
    }
    if (Appl.Validator.isValid()) {
      const result = await this.service?.sendMemberFileUploadInvitationAsync(this.model);
      if (result.success) {
        this.model = {};
        this.sameAsRequesterSelected = false;
        this.enableSendInvitationButton = false;
        Appl.MessageBox.show('Your secure file upload invitation has been queued to send.', 'Secure File Upload Request Queued.');
        this.navigate(`/members/upload-requests`)
      } else {
        Appl.Error.handle(result.error);
      }
    }
  }

  @action onRequiredDocumentsChange = (e: any) => {
    let target = e.target;
    if (!target) {
      target = e.originalEvent.currentTarget;
    }
    (this.model)['requestedDocuments'] = target?.value;
    if (this.model.requestedDocuments) {
      this.otherDocTextBoxVisible = this.model.requestedDocuments.indexOf('Other') > -1;
    } else {
      this.otherDocTextBoxVisible = false;
    }
    this.verifyMandatoryFieldsFilled();
  };

  @action onSameAsRequesterChange = (e: CheckboxChangeEvent) => {
    this.sameAsRequesterSelected = e.checked!;
    if (this.sameAsRequesterSelected) {
      this.model.memberFirstName = this.model.reqFirstName;
      this.model.memberLastName = this.model.reqLastName;
      this.model.memberEmail = this.model.reqEmail;
    }
    this.verifyMandatoryFieldsFilled();
  };

  @action onMemberInfoChange = (e: any, id: string) => {
    (this.model as any)[id] = e.target?.value;
    let reqValue = `${this.model.reqFirstName}${this.model.reqLastName}${this.model.reqEmail}`;
    let memberValue = `${this.model.memberFirstName}${this.model.memberLastName}${this.model.memberEmail}`;
    if (reqValue !== memberValue) {
      this.sameAsRequesterSelected = false;
    } else {
      this.sameAsRequesterSelected = true;
    }
    this.verifyMandatoryFieldsFilled();
  }

  @action public verifyMandatoryFieldsFilled(): void {
    if (this.model.reqFirstName && this.model.reqLastName && this.model.reqEmail
      && this.model.memberFirstName && this.model.memberLastName && this.model.requestedDocuments?.length! > 0
    ) {
      this.enableSendInvitationButton = true;
      if (this.otherDocTextBoxVisible && !this.model.otherDocumentName) {
        this.enableSendInvitationButton = false;
      }
    } else {
      this.enableSendInvitationButton = false;
    }
  };

}
