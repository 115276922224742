import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { MemberLookupModel } from './invite/docUpload/MemberLookupModel';
import { ResendUploadFileInvitationModel } from './list/UploadRequestListModel';
import { Appl } from '../../../../core/Appl';

export interface IMemberFileService extends IBaseService {

  sendMemberFileUploadInvitationAsync(model: MemberLookupModel): Promise<ServerResponse>;
  reSendMemberFileUploadInvitationAsync(model: ResendUploadFileInvitationModel): Promise<ServerResponse>;
  updateRequestStatusAsync(requestId: number, requestStatus: string): Promise<ServerResponse>;
  getRequestedFilesAsync(requestId: number): Promise<ServerResponse>;
  createOrUpdateMemberFilesAsync(userId: string | undefined, models: any): Promise<ServerResponse>;
  getListOfValuesAsync(tableName: string | undefined): Promise<ServerResponse>;
  getUploadQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class MemberFileService extends BaseService implements IMemberFileService {
  constructor() {
    super(`${Appl.Setting.Services.MemberServiceUrl}`);
  }

  public async sendMemberFileUploadInvitationAsync(model: MemberLookupModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/member/sendMemberFileUploadInvitation`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  public async reSendMemberFileUploadInvitationAsync(model: ResendUploadFileInvitationModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/member/resendMemberFileUploadInvitation`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  public async updateRequestStatusAsync(requestId: number, requestStatus: string): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/member/updateRequestStatus/${requestId}/${requestStatus}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  public async getRequestedFilesAsync(requestId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/members/member/requested-files/${requestId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  public async createOrUpdateMemberFilesAsync(userId: string | undefined, models: any): Promise<ServerResponse> {
    try {
      const response = await this.uploadAsync(`/members/member/createOrUpdateMemberFiles/${userId}`, models);
      return response;
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  public async getListOfValuesAsync(tableName: string | undefined): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/members/ListOfValue/byTableName/${tableName}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  public async getUploadQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/Member/downloadUploadQueue/${format}`, searchModel, { responseType: 'blob' });
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }
}
