import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { MessageBoxService } from "../components/dialogs/MessageBox";

export class ProblemDetails {
    methodName?: string;
    errors?: Array<ValidationError>;
    status?: number;
    title?: string;
    detail?: string;
    instance?: string;
    traceId?: string;
    tileStamp?: string;
    stackTrace?: string;
    route?: string;
}

export class ValidationError {
    pointer?: string;
    detail?: string;
}


export interface IErrorService {
    items: Map<string, string>;
    clear(): void;
    add(key: string, value: string): void;
    remove(key: string): void;
    count(): number;
    handle(problem?: ProblemDetails): void;
}

export class ErrorService implements IErrorService {

    private static _instance: ErrorService;
    public static get instance() {
        return this._instance ?? (this._instance = new ErrorService());
    }

    @observable public items = new Map<string, string>();

    constructor() {
        makeAutoObservable(this);
    }

    @action public clear(): void {
        this.items.clear();
    }

    @action public add(key: string, value: string): void {
        this.items.set(key, value);
    }

    @action public remove(key: string): void {
        this.items.delete(key);
    }

    @action public count(): number {
        return this.items.size;
    }

    @action public handle(problem?: ProblemDetails): void {
        runInAction(() => {
            this.items.clear();
            if (problem) {
                if (problem.errors) {
                    problem.errors.map((error) => {
                        var element = document.getElementById(error.pointer!)
                        if (element) {
                            this.items.set(error.pointer!, error.detail!);
                        } else {
                            MessageBoxService.instance.show(error.detail!, problem.title ?? 'Error');
                        }
                    })
                } else {
                    MessageBoxService.instance.show(problem.detail!, problem.title ?? 'Error');
                }
            }
        })
    }
}