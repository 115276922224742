import React from 'react';
import { observer } from 'mobx-react';
import { InputErrors } from './InputErrors';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Icon } from '../Icon';
import { Appl } from '../../../core/Appl';
import { InputTextarea } from 'primereact/inputtextarea';

export enum InputType {
  Text = 'text',
  Password = 'password',
  Search = 'search',
  Number = 'number',
  Email = 'email',
  Multiline = 'multiline',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Color = 'color',
  Date = 'date',
  Time = 'time',
  Week = 'week',
  Month = 'month',
  DateTime = 'datetime-local',
  File = 'file',
  Hidden = 'hidden',
  Range = 'range',
  Phone = 'tel',
  Url = 'url',
  Masked = 'masked',
}

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  inputType?: InputType;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  required?: boolean;
  rows?: number;
  helpText?: string;
  className?: string | undefined;
  readOnly?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  minNumber?: number;
  maxNumber?: number;
  minRange?: number;
  maxRange?: number;
  maxLength?: number;
  numberStep?: number;
  pattern?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  returnKeyType?: 'done' | 'go' | 'next' | 'search' | 'send';
  keyboardAppearance?: 'default' | 'light' | 'dark' | undefined;
  iconName?: string;
  suffixid?: string;
  mask?: string;
  maskChar?: string;
}

export const Input: React.FC<InputProps> = observer(({
  inputType = InputType.Text,
  className = '',
  ...props
}) => {

  const readOnlyClasses = '[&>input]:!bg-gray-100 [&>input]:cursor-not-allowed';
  const renderLabel = () => {
    if (props.label && !props.hidden) {
      return (
        <label htmlFor={props.id} className='font-bold text-15px text-black mb-1 block'>
          {props.label}
          {props.required && <span style={{ color: 'red' }}> * </span>}
          {props.helpText && <Icon name="question-circle" color="black" size={16} />}
        </label>
      );
    }
    return null;
  };

  return (
    <>
      {renderLabel()}
      {props.disabled ? (
        <div >{props.value?.toString()}</div>
      ) : (
        <>
          {inputType === InputType.Number ? (
            <>
              {!props.hidden && <InputNumber
                id={props.id}
                className={`${className} ${props.readOnly && readOnlyClasses}`}
                // @ts-ignore: Unreachable code error
                value={parseInt(props.value)}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                format={false}
                {...props}
              />}
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}

            </>
          ) : (inputType === InputType.Masked) ? (
            <>
              {!props.hidden && <InputMask
                id={props.id}
                mask={props.mask}
                slotChar={props.maskChar ? props.maskChar : '_'}
                className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                // @ts-ignore: Unreachable code error
                value={props.value ? String(props.value) : ''}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                {...props}
              />}
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          ) : (inputType === InputType.Multiline) ? (
            <>
              {!props.hidden && <InputTextarea
                id={props.id}
                type={inputType}
                className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                // @ts-ignore: Unreachable code error
                value={props.value ? String(props.value) : ''}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                {...props}
              />}
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          ) : (
            <>
              {!props.hidden && <InputText
                id={props.id}
                type={inputType}
                className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                // @ts-ignore: Unreachable code error
                value={props.value ? String(props.value) : ''}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                {...props}
              />
              }
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          )}
        </>
      )
      }
    </>
  );
});
