import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, View, ViewHeader } from '../components/Index';

export const PageNotImplementedView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <View id="PageNotImplementedView">
        <ViewHeader title="Page Not Implemented!">
          <Button title="Back" className="btn-secondary" icon="arrow-left" iconColor="white" onClick={() => navigate(-1)} />
        </ViewHeader>
        <div className="p-8">
          <div className="bg-white rounded-xl mb-4 text-center py-16 px-6 shadow-card border border-gray-25 space-y-8">
            <div>
              <h2 className="font-medium text-lg md:text-2xl text-gray-800">Sorry!</h2>
              <p className="text-gray-500 max-w-2xl mx-auto text-sm md:text-base">
                You&apos;re looking for a page which is not implemented to view. Please contact system administrator for more information.
              </p>
            </div>
          </div>
        </div>
      </View>
    </>
  );
};
