import { Role } from '../../../core/services/Enums';
import { Appl } from '../../../core/Appl';
import { SraService } from './SraService';
import DashboardViewModel from './dashboard/DashboardViewModel';
import ApplyViewModel from './retiree/addEdit/ApplyViewModel';
import { NavItem } from '../../../core/services/Models';
import SubmissionListViewModel from './submission/list/SubmissionListViewModel';

export default class SraModule {

  public static MenuItems(): Array<NavItem> {

    const userContext = Appl.User;
    let isAdmin = userContext.hasRole(Role.Admin);
    let menuItems: Array<NavItem> = [];
    menuItems.push({ id: 'sra', label: 'Dashboard', path: '/sra' });
    if (isAdmin) {
      menuItems.push({ id: 'sra-retiree', label: 'Submissions', path: '/sra/retiree' });
    } else {
      menuItems.push({ id: 'sra-retiree-application', label: 'My Retirement', path: `/sra/retiree/application/${userContext.userId}` });
      menuItems.push({ id: 'sra-retirement-considerations', label: 'Considerations', path: '/sra/retirement-considerations' });
      menuItems.push({ id: 'sra-planning-checklist', label: 'Retirement Checklist', path: '/sra/planning-checklist' });
    }
    return menuItems
  }

  public static init(): void {
    Appl.Services.add('ISraService', SraService);
    Appl.Services.add('DashboardViewModel', DashboardViewModel);
    Appl.Services.add('ApplyViewModel', ApplyViewModel);
    Appl.Services.add('SubmissionListViewModel', SubmissionListViewModel);
  }
}
