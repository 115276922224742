import AccountModule from '../modules/admin/account/AccountModule';
import ConfigData from './AppConfig.json';
import PublicModule from '../modules/public/PublicModule';
import SraModule from '../modules/admin/sra/SraModule';
import RapModule from '../modules/admin/rap/RapModule';
import MemberFileModule from '../modules/admin/members/memberFiles/MemberFileModule';
import ChangeOfAddressModule from '../modules/admin/members/changeOfAddress/ChangeOfAddressModule';
import DeathModule from '../modules/admin/members/deaths/DeathModule';

export interface ISetting {
  AppVersion: string;
  EnvironmentName: string;
  UseModelDialogForEdits: boolean;
  GridPageSize: number;
  AllowedFileExtensions: string;
  AllowedFileSizeBytes: number;
  Services: {
    MbaseServiceUrl?: string;
    RetirementServiceUrl?: string;
    MemberServiceUrl?: string;
    RapServiceUrl?: string;
  }
  AuthProviders?: [
    {
      Provider: string;
      ClientId?: string;
      AuthorityUrl?: string;
      UserInfoUrl?: string;
      LoginRedirectUrl?: string;
      LogoutUrl?: string;
      Scopes: Array<string>;
      UserAttributes: {
        DisplayName?: string;
        UserId?: string;
        Email?: string;
      }
    }
  ];
}

export class AppConfig {
  private static _instance: AppConfig;
  public static get instance() {
    return this._instance ?? (this._instance = new AppConfig());
  }

  public Setting?: ISetting;

  constructor() {
    let envName = this.getEnvironment();
    this.Setting = ConfigData.Setting.find(o => o.EnvironmentName === envName) as ISetting;
    this.Setting.AppVersion = "0.0.5";
  }

  private getEnvironment(): string {
    let env = 'local';
    const location = window.location.host.toLowerCase();
    if (location.includes('localhost')) {
      env = 'local';
    } else if (location.includes('red-dune')) {
      env = 'qual';
    }
    else if (location.includes('test.scers')) {
      env = 'test';
    } else if (location.includes('uat.scers')) {
      env = 'uat';
    } else {
      env = 'prod';
    }
    // env = 'qual';
    return env;
  }


  public static init(): void {
    AccountModule.init();
    PublicModule.init();
    SraModule.init();
    RapModule.init();
    MemberFileModule.init();
    ChangeOfAddressModule.init();
    DeathModule.init();
  }
}