import './AlertBox.css';
import { observer } from 'mobx-react';
import { action, makeAutoObservable, observable } from 'mobx';
import { Check, CircleAlert, CircleCheck, Info, TriangleAlert, X } from 'lucide-react';
import Helper from '../../services/Helper';
import { Appl } from '../../Appl';
import parse from 'html-react-parser';

//ALERT
export enum AlertType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export enum AlertPlacement {
  TopLeft = 'top-0 start-0',
  TopCenter = 'top-0 start-50 translate-middle-x',
  TopRight = 'top-0 end-0',
  MiddleLeft = 'top-50 start-0 translate-middle-y',
  MiddleCenter = 'top-50 start-50 translate-middle',
  MiddleRight = 'top-50 end-0 translate-middle-y',
  BottomLeft = 'bottom-0 start-0',
  BottomCenter = 'bottom-0 start-50 translate-middle-x',
  BottomRight = 'bottom-0 end-0',
}

type AlertProps = {
  placement?: AlertPlacement;
};

export const AlertBox: React.FC<AlertProps> = observer(({ placement = AlertPlacement.TopRight }) => {
  const vm = Appl.AlertBox;
  let titleBackground = 'text-bg-info';
  let titleColor = 'black';
  let iconName = 'info';
  let icon = <Check />;

  if (vm.type === AlertType.Success) {
    titleBackground = 'text-bg-success';
    titleColor = 'text-primary-600';
    iconName = 'check';
    icon = <CircleCheck />;
  } else if (vm.type === AlertType.Warning) {
    titleBackground = 'text-bg-warning';
    titleColor = 'text-orange-500';
    iconName = 'triangle-alert';
    icon = <CircleAlert />;
  } else if (vm.type === AlertType.Error) {
    titleBackground = 'text-bg-danger';
    titleColor = 'text-red-600';
    iconName = 'circle-x';
    icon = <TriangleAlert />;
  } else if (vm.type === AlertType.Info) {
    titleBackground = 'text-bg-info';
    titleColor = 'text-gray-500';
    iconName = 'info';
    icon = <Info />;
  }

  Helper.usePoll(async () => {
    vm.visible = false;
  }, 20);

  return (
    <>
      {vm.visible && (
        <div className="p-component p-toast-top-right"
          style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1229 }}>
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
            <div className="p-4 flex items-start">
              <div className='flex-shrink-0'>
                <div className={`flex items-center justify-center h-7 w-7 rounded-full ${titleColor}`}>
                  {icon}
                </div>
              </div>
              <div className="ml-3 flex-1 pt-0.5" data-pc-section="text">
                <span className={`text-base font-medium ${titleColor}`}>{vm.title}</span>
                <div className={`mt-1 text-sm text-gray-500 `}>{parse(vm.message)}</div>
              </div>
              <div className='ml-4 flex flex-shrink-0'>
                <button type="button" className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" data-pc-section="closebutton"
                  onClick={() => { vm.hide() }}>
                  <span className="sr-only">Close</span>
                  <X className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export interface IAlertBoxService {
  title: string;
  subTitle: string;
  message: string;
  type: AlertType;
  visible: boolean;
  hide(): void;
  showWarning(message?: string, title?: string, subTitle?: string): void;
  showInfo(message?: string, title?: string, subTitle?: string): void;
  showError(message?: string, title?: string, subTitle?: string): void;
  showSuccess(message?: string, title?: string, subTitle?: string): void;
}

export class AlertBoxService implements IAlertBoxService {

  private static _instance: AlertBoxService;
  public static get instance() {
    return this._instance ?? (this._instance = new AlertBoxService());
  }

  @observable public title = '';
  @observable public subTitle = '';
  @observable public message = '';
  @observable public type: AlertType = AlertType.Info;
  @observable public visible = false;
  constructor() {
    makeAutoObservable(this);
  }
  @action public hide(): void {
    this.title = '';
    this.message = '';
    this.visible = false;
  }

  @action public showWarning(message: string, title: string | undefined = 'Warning', subTitle = ''): void {
    this.show(message, title, subTitle, AlertType.Warning);
  }

  @action public showInfo(message: string, title: string | undefined = 'Info', subTitle = ''): void {
    this.show(message, title, subTitle, AlertType.Info);
  }

  @action public showError(message: string, title: string | undefined = 'Error', subTitle = ''): void {
    this.show(message, title, subTitle, AlertType.Error);
  }

  @action public showSuccess(message: string, title: string | undefined = 'Success', subTitle = ''): void {
    this.show(message, title, subTitle, AlertType.Success);
  }

  @action public show(message: string, title: string | undefined = 'Success', subTitle = '', type: AlertType = AlertType.Success): void {
    this.title = title;
    this.message = message;
    this.type = type;
    this.subTitle = subTitle;
    this.visible = true;
  }
}
