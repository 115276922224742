import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import Helper from '../../../../../core/services/Helper';
import UploadRequestListViewModel from './UploadRequestListViewModel';
import { ConfirmDialog } from 'primereact/confirmdialog';
import MemberFileModule from '../MemberFileModule';
import { DocumentDetailModel, UploadRequestListModel } from './UploadRequestListModel';
import { BugOff, FileInput, FolderDown, TriangleAlert } from 'lucide-react';
import { Appl } from '../../../../../core/Appl';
import { Button, DataGrid, InputOptionBox, SearchToolbar, View, ViewHeader } from '../../../../../core/components/Index';
import { RadioButton } from 'primereact/radiobutton';
import { Button as PrimeButton } from 'primereact/button';

export const UploadRequestListView: React.FC = observer(() => {
  const vm = Appl.Services.get<UploadRequestListViewModel>('UploadRequestListViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.getPagedAsync();
  }, []);

  const documentList = () => {
    const rows: Array<ReactNode> = [];

    vm.documentDetailsModel.map((document: DocumentDetailModel, index) => {
      let message = 'Info';
      let color = '';
      // document.virusScanStatus = 'E';
      if (document.virusScanStatus === 'P') {
        message = 'Pending';
      } else if (document.virusScanStatus === 'I') {
        message = 'Scanning In Progress';
      } else if (document.virusScanStatus === 'C') {
        message = 'No Virus Found';
      } else if (document.virusScanStatus === 'E') {
        message = 'Error';
        color = 'text-red-500 line-through';
      }

      rows.push(
        <tr key={index + 1} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-black text-15px tracking--1_5percent">
          <td scope="col" className="p-2">
            {document.virusScanStatus === 'C' && (
              <InputOptionBox
                inputId={`selected`}
                checked={document.selected!}
                onChange={(e: any) => {
                  document.selected = e.target.checked;
                }}
              />
            )}
          </td>
          <td scope="col" className={`p-2 ${color}`}>
            {document.docType}
          </td>
          <td scope="col" className={`p-2 ${color}`}>
            {document.docName}
          </td>
          <td scope="col" className={`p-2 ${color}`}>
            {document.virusScanStatus === 'C' && (
              <span
                className="cursor-pointer"
                title="View/Download"
                onClick={async () => await vm.downloadDocumentAsync(document.docDtlId!, document.docFilename ?? '')}>
                {document.docFilename}
              </span>
            )}
            {document.virusScanStatus !== 'C' && (
              <span className="mx-2" title={document.virusScanProcessMsg}>
                {document.docFilename}
              </span>
            )}
          </td>
          <td scope="col" className="p-2 text-center flex justify-center">
            {document.virusScanStatus === 'C' && (
              <span title="No Virus Found">
                <BugOff size={18} className={`w-5 h-5 mr-2 text-primary-500 `} />
              </span>
            )}
            {document.virusScanStatus === 'E' && (
              <span title="Virus Found!">
                <TriangleAlert size={18} className={`w-5 h-5 mr-2 text-red-500 `} />
              </span>
            )}
            {(document.virusScanStatus === 'P' || document.virusScanStatus === 'I') && <span title={document.virusScanProcessMsg}>{message}</span>}
          </td>
        </tr>,
      );
    });
    return (
      <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-gray-700 uppercase text-sm bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr key={0}>
            <th scope="col" className="px-2 py-3">
              <InputOptionBox inputId={`selectAllDocs`} checked={vm.selectAllDocs} onChange={(e: any) => vm.onSelectUnSelectAll(e.checked)} />
            </th>
            <th scope="col" className="px-2 py-2">
              Document Type
            </th>
            <th scope="col" className="px-2 py-2">
              Label
            </th>
            <th scope="col" className="px-2 py-2">
              File Name
            </th>
            <th scope="col" className="px-2 py-2 whitespace-nowrap  text-center">
              Virus Check
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const reqDateTemplate = (row: UploadRequestListModel) => {
    return Helper.formatDateTime(row.reqDate);
  };

  const reqCompleteDateTemplate = (row: UploadRequestListModel) => {
    return Helper.formatDateTime(row.reqCompleteDate);
  };

  const reqStatusTemplate = (row: UploadRequestListModel) => {
    const textClassName = 'bg-white-100 text-black tracking--1_5percent px-1 py-0.5 rounded';
    if (row.canResend) {
      return (
        <span
          className={`bg-white-800 px-1 py-0.5 rounded text-black tracking--1_5percent`}
          title="Resend Email"
          style={{ cursor: 'pointer' }}
          onClick={async () => await vm.sendInvitationAsync(row)}>
          Resend
        </span>
      );
    }
    if (row.reqStatus === 'P') {
      return (
        <span className={textClassName} title="Email is not sent yet">
          Email Pending
        </span>
      );
    } else if (row.reqStatus === 'I') {
      return (
        <span className={textClassName} title="Email Sending in progress">
          Email In Progress
        </span>
      );
    } else if (row.reqStatus === 'S') {
      return (
        <span className={textClassName} title="Email Sent">
          Email Sent
        </span>
      );
    } else if (row.reqStatus === 'R') {
      return (
        <span
          className={textClassName}
          title="Files Received. Click to View Files"
          style={{ cursor: 'pointer' }}
          onClick={async () => await vm.showDocumentListDialog(row)}>
          Files Rcvd
        </span>
      );
    } else if (row.reqStatus === 'C') {
      return (
        <span
          className={`bg-primary-500 font-bold tracking--1_5percent text-white px-2 py-0.5 rounded uppercase text-[13px]`}
          title="Document Submission Completed"
          style={{ cursor: 'pointer' }}
          onClick={async () => await vm.showDocumentListDialog(row)}>
          Completed
        </span>
      );
    } else if (row.reqStatus === 'E') {
      return (
        <span className={`bg-green-800 text-white px-1 py-0.5 rounded tracking--1_5percent`} title={row.processMessage}>
          Email Error
        </span>
      );
    }
  };

  return (
    <>
      <View
        id="MemberListView"
        pageTitle={vm.pageTitle}
        authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
        menuItems={MemberFileModule.MenuItems}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="">
          <div />
          <SearchToolbar
            filterPlaceholder="Search"
            filter={vm.searchRequest?.filter}
            onFilterChange={(e) => vm.onInputChange(e, 'filter', vm.searchRequest)}
            onRefresh={async () => await vm.getPagedAsync()}
            addNewVisible={false}
            refreshVisible={false}
            showButton={false}
            // run search on input change
            searchOnInputChange={true}
            onAddNew={async () => await vm.getPagedAsync()}
          />
        </ViewHeader>
        <div className="flex space-x-1">
          <Button title="Request Form" className='!rounded-lg' onClick={() => vm.navigate('/members/invite/doc-upload')} />
          <PrimeButton outlined severity="secondary" onClick={async () => await vm.exportCSV()} className="!border-transparent !bg-transparent">
            <FileInput className="w-4 h-4 mr-1.5" />
            Export
          </PrimeButton>
        </div>
        <div className="my-6">
          <DataGrid
            pageSize={vm.pageSize}
            dataSource={vm.items}
            totalRowsCount={vm.totalRowsCount}
            searchRequest={vm.searchRequest}
            onRefresh={() => vm.getPagedAsync()}
            dataKey="requestId">
            <Column
              field="requestId"
              header="ID"
              sortable={true}
              hidden={false}
              style={{
                width: '50px',
              }}
            />
            <Column field="reqName" header="CONTACT NAME" sortable={true} />
            <Column field="reqEmail" header="EMAIL ADDRESS" sortable={true} />
            <Column field="reqDate" header="DATE SENT" sortable={true} body={reqDateTemplate} />
            <Column field="reqCompleteDate" header="DATE FILE(S) RECEIVED" sortable={true} body={reqCompleteDateTemplate} />
            <Column field="reqStatus" header="STATUS" sortable={true} body={reqStatusTemplate} />
          </DataGrid>
        </div>
        {vm.showConfirmDialog && (
          <ConfirmDialog
            visible={vm.showConfirmDialog}
            message={vm.confirmDialogMessage}
            header={vm.confirmDialogTitle}
            blockScroll={true}
            rejectClassName="mr-2 !bg-gradient-to-b from-[#FBFBFB] to-[#EBEBEB] hover:from-[#EAEAEA] hover:to-[#D8D8D8] !border-[#CFCFCF] !border !text-neutral-900 !hover:bg-neutral-300 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-700"
            icon="pi pi-exclamation-triangle"
            draggable={false}
            accept={async () => await vm.onConfirmDialogAsync()}
            reject={async () => await vm.hideConfirmDialogAsync()}
            onHide={async () => await vm.hideConfirmDialogAsync()}
          />
        )}
        <Dialog
          header={`Secure File Upload Request #${vm.selectedRow?.requestId}`}
          visible={vm.documentListDialogVisible}
          closable={true}
          modal
          blockScroll={true}
          draggable={false}
          className="max-w-5xl !w-full"
          // contentClassName='overflow-y-auto'
          onHide={() => vm.hideDocumentListDialog()}>
          <div className="flex flex-row flex-wrap md:flex-nowrap md:gap-10 gap-y-4 tracking--1_5percent">
            <div className="w-1/2 md:w-auto">
              <p className="text-base text-black font-semibold">Contact Name</p>
              <p>{vm.selectedRow?.reqName}</p>
              <p>{vm.selectedRow?.reqEmail}</p>
            </div>
            <div className="w-1/2 md:w-auto">
              <p className="text-base text-black font-semibold">Member Name</p>
              <p>
                {vm.selectedRow?.mbrFname} {vm.selectedRow?.mbrLname}
              </p>
              <p>{vm.selectedRow?.mbrEmail}</p>
            </div>
            <div className="w-1/3 md:w-auto">
              <p className="text-base text-black font-semibold">SSN</p>
              <p>{vm.selectedRow?.mbrSsn}</p>
            </div>
            <div className="w-1/3 md:w-auto">
              <p className="text-base text-black font-semibold">DOB</p>
              <p>{Helper.formatDate(vm.selectedRow?.mbrDob)}</p>
            </div>
            <div className="w-1/3 md:w-auto">
              <p className="text-base text-black font-semibold">Member ID</p>
              <p>{vm.selectedRow?.mbrId}</p>
            </div>
          </div>
          <div className="my-6 pt-6 border-t border-b border-gray-200 overflow-y-auto min-h-[100px] max-h-[50vh]">
            <div className="text-xl font-semibold mb-3">Documents</div>
            <div className="flex flex-col gap-4">
              <div>{documentList()}</div>
            </div>
            <div className="flex space-x-4 justify-center mt-6 mb-6">
              <Button
                disabled={!vm.documentDetailsModel.find((o) => o.selected)}
                onClick={async () => vm.downloadDocumentsAsync()}
                title="Download File(s)"
              />
              <Button severity="secondary" onClick={() => vm.hideDocumentListDialog()} title="Cancel" />
            </div>
          </div>
          <div className="flex justify-start gap-4 mt-4 tracking--1_5percent">
            <div>
              These documents have been filed.&nbsp;&nbsp;&nbsp;
              <RadioButton
                inputId="reqStatusNo"
                name="reqStatus"
                value="R"
                className="mt-1"
                checked={vm.selectedRow?.reqStatus === 'R'}
                onChange={async (e) => await vm.updateRequestStatusAsync('R')}
              />
              <label htmlFor="reqStatusNo" className="ml-2 font-bold text-black">
                No
              </label>
            </div>
            <div className="">
              <RadioButton
                inputId="reqStatusYes"
                name="reqStatus"
                value="C"
                className="mt-1"
                checked={vm.selectedRow?.reqStatus === 'C'}
                onChange={async (e) => await vm.updateRequestStatusAsync('C')}
              />
              <label htmlFor="reqStatusYes" className="ml-2 text-black">
                Yes, mark as complete.
              </label>
            </div>
          </div>
        </Dialog>
      </View>
    </>
  );
});
