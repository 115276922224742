export interface ViewFooterProps {
    children?: React.ReactNode;
}
export const ViewFooter: React.FC<ViewFooterProps> = ({ children }) => {
    return (
        <>
            <div className="row mt-5 mb-3 justify-content-between align-items-center">
                <div className="col-lg-6 mr-auto mt-3 d-flex gap-2 justify-content-end">{children}</div>
            </div>
        </>
    );
};