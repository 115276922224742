import { BaseViewModel } from "../../../../../core/services/BaseViewModel";
import { action, makeObservable, observable, runInAction } from "mobx";
import { Service } from "typedi";
import { ISraService } from "../../SraService";
import { AppForRetirementModel, BeneficiaryModel, Steps, SubmissionModel } from "./ApplyModels";
import { ListItem, ServerResponse } from "../../../../../core/services/BaseService";
import Helper from "../../../../../core/services/Helper";
import { DocumentsModel } from "../../../../../core/components/UploadUtility";
import { StepStatus } from "./SidebarFormNavigation";
import { Appl } from "../../../../../core/Appl";

import { AccountType, PersonalStatus, SupportedDocuments } from "../../SraEnums";

@Service() // eslint-disable-next-line
export default class ApplyViewModel extends BaseViewModel {

    @observable public memberId?: string;
    @observable public submissionId?: number;
    @observable public printDialogVisible = false;
    @observable public submission?: SubmissionModel = {}
    @observable public model: AppForRetirementModel = {
        submissionId: 0,
        submissionStatusId: 1,
        stepStatus: {
            gettingStarted: StepStatus.Active,
            retirementDate: StepStatus.NotStarted,
            selectDate: StepStatus.NotStarted,
            personalInformation: StepStatus.NotStarted,
            confirmYourInformation: StepStatus.NotStarted,
            addressVerification: StepStatus.NotStarted,
            contactInformation: StepStatus.NotStarted,
            personalStatus: StepStatus.NotStarted,
            beneficiaryDesignation: StepStatus.NotStarted,
            manageBeneficiary: StepStatus.NotStarted,
            memberDeclaration: StepStatus.NotStarted,
            taxWithholding: StepStatus.NotStarted,
            federalTaxWithholding: StepStatus.NotStarted,
            stateTaxWithholding: StepStatus.NotStarted,
            declarationTaxWithholding: StepStatus.NotStarted,
            directDeposit: StepStatus.NotStarted,
            directDepositAuthorization: StepStatus.NotStarted,
            sickLeave: StepStatus.NotStarted,
            dispositionOfAccruedSickLeave: StepStatus.NotStarted,
            screaMembership: StepStatus.NotStarted,
            membershipApplication: StepStatus.NotStarted,
            serviceRetirementSignOff: StepStatus.NotStarted,
            serviceRetirementChecklist: StepStatus.NotStarted,
            reviewApplication: StepStatus.NotStarted,
            uploadDocuments: StepStatus.NotStarted,
            submitApplication: StepStatus.NotStarted,
        },
        personalInformation: { submissionId: 0, validateAddress: false },
        beneficiaryDesignation: { afrDecleration: false },
        taxWithHolding: {
            federalTaxWithHolding: { federalWithholdIncomeTax: false, federalDonotWithholdIncomeTax: true },
            stateTaxWithHolding: { stateWithholdIncomeTax: false, stateDonotWithholdIncomeTax: true },
            taxwithholdDecleration: false
        },
        directDeposit: { directDepositConsent: false },
        accruedSickLeave: { sickLeaveCreditN: true, sickLeaveCredit: false },
        screaMembership: { regularMembership: false, associateMembership: false, acceptMembershipConsent: false },
        signOffModels: [],
        uploadedDocuments: []
    };
    @observable public modelLoaded = false;
    @observable public retirementDateConfirmed = false;
    @observable public personalInformationCompleted = false;
    @observable public beneficiaryDesignationCompleted = false;
    @observable public addressEditable = false;
    @observable public alternativeNumberEditable = false;
    @observable public isAddingBeneficiary = false;
    @observable public isEditingBeneficiary = false;
    @observable public beneficiaryModel: BeneficiaryModel;
    @observable public selectedBeneficiary = 0;
    @observable public taxWithHoldingModelCompleted = false;
    @observable public directDepositModelCompleted = false;
    @observable public accruedSickLeaveModelCompleted = false;
    @observable public screaMembershipModelCompleted = false;
    @observable public signOffModelCompleted = false;
    @observable public signOffCheckedItemsCount = 0;
    @observable public submitApplicationCompleted = false;
    @observable public documentUploadModelCompleted = false;
    @observable public activeDocument: DocumentsModel = { docTypeId: 0 };
    @observable public uploadDialogVisible: boolean = false;

    @observable public addressListItems?: Array<ListItem> = [];

    @observable public afrFile?: any

    private _service: ISraService;

    constructor() {
        super();
        this._service = Appl.Services.get<ISraService>('ISraService');
        this.beneficiaryModel = {};
        makeObservable(this);
    }

    @action public async getSubmissionIdAsync(): Promise<number> {
        const submissionId = parseInt(Appl.Cache.get("submission_id")!);
        return submissionId;
    }

    @action public async loadStepAsync(step: Steps, memberId: string): Promise<void> {
        this.memberId = memberId;
        let result: ServerResponse;
        Appl.Cache.remove("submission_id");
        this.modelLoaded = false;
        result = await this._service?.getSubmissionByMemberIdAsync(memberId, true);
        if (result.success) {
            runInAction(async () => {
                this.submission = result.data;
                this.submissionId = this.submission?.submissionId;
                if (this.submission?.lovSubmissionStatusId !== 1) {
                    this.navigate(`/sra/retiree/afr-status/${this.memberId}`)
                }
                Appl.Cache.addOrUpdate("submission_id", this.submissionId!.toString());

                this.submissionId = await this.getSubmissionIdAsync();
                if (!this.modelLoaded) {
                    result = await this._service?.getAppForRetirementBySubmissionIdAsync(this.submissionId);
                    if (result.success) {
                        runInAction(() => {
                            this.model = result.data;
                            if (this.model) {
                                //Personal Information
                                this.model.personalInformation.birthYear = Helper.getDayMonthYearModel(this.model.personalInformation.birthDate).year;
                                this.model.personalInformation.birthMonth = Helper.getDayMonthYearModel(this.model.personalInformation.birthDate).month;
                                this.model.personalInformation.birthDay = Helper.getDayMonthYearModel(this.model.personalInformation.birthDate).day;
                                this.model.personalInformation.retirementEffYear = Helper.getDayMonthYearModel(this.model.personalInformation.retirementEffDate).year;
                                this.model.personalInformation.retirementEffMonth = Helper.getDayMonthYearModel(this.model.personalInformation.retirementEffDate).month;
                                this.model.personalInformation.retirementEffDay = Helper.getDayMonthYearModel(this.model.personalInformation.retirementEffDate).day;
                                this.model.personalInformation.ssnMasked = `XXXX-XX-${this.model.personalInformation.ssn}`
                                if (this.model.personalInformation.alternatePhone) {
                                    this.alternativeNumberEditable = true;
                                }

                                //Beneficiary Designation
                                if (this.model.beneficiaryDesignation.beneficiaries) {
                                    this.selectedBeneficiary = 0;
                                    this.beneficiaryModel = this.model.beneficiaryDesignation.beneficiaries[this.selectedBeneficiary];
                                }

                                //Tax Withholding
                                if (this.model.taxWithHolding.federalTaxWithHolding) {
                                    this.model.taxWithHolding.federalTaxWithHolding.federalDonotWithholdIncomeTax = !this.model.taxWithHolding.federalTaxWithHolding.federalWithholdIncomeTax;
                                }
                                if (this.model.taxWithHolding.stateTaxWithHolding) {
                                    this.model.taxWithHolding.stateTaxWithHolding.stateDonotWithholdIncomeTax = !this.model.taxWithHolding.stateTaxWithHolding.stateWithholdIncomeTax;
                                }

                                //Direct Deposit

                                //Accrued Sick Leave
                                this.model.accruedSickLeave.sickLeaveCreditN = !this.model.accruedSickLeave.sickLeaveCredit;

                                //SCREA Membership

                                //Sign Off Model
                                this.signOffCheckedItemsCount = this.model.signOffModels.filter(o => o.active)?.length

                                //Uploaded Documents

                                //Submission

                            }

                            this.modelLoaded = true;
                        })
                    } else {
                        Appl.Error.handle(result.error);
                        return;
                    }
                }
                switch (step) {
                    case Steps.GettingStarted: {
                        this.model.stepStatus.gettingStarted = StepStatus.Active;
                        break;
                    }
                    case Steps.RetirementDate: {
                        this.model.stepStatus.retirementDate = StepStatus.Active;
                        break;
                    }
                    case Steps.SelectDate: {
                        this.model.stepStatus.selectDate = StepStatus.Active;
                        this.retirementDateConfirmed = false;
                        break;
                    }
                    case Steps.PersonalInformation: {
                        this.model.stepStatus.personalInformation = StepStatus.Active;
                        break;
                    }
                    case Steps.ConfirmYourInformation: {
                        this.model.stepStatus.confirmYourInformation = StepStatus.Active;
                        break;
                    }
                    case Steps.AddressVerification: {
                        this.model.stepStatus.addressVerification = StepStatus.Active;
                        break;
                    }
                    case Steps.ContactInformation: {
                        this.model.stepStatus.contactInformation = StepStatus.Active;
                        break;
                    }
                    case Steps.PersonalStatus: {
                        this.personalInformationCompleted = false;
                        this.model.stepStatus.personalStatus = StepStatus.Active;
                        break;
                    }
                    case Steps.BeneficiaryDesignation: {
                        this.model.stepStatus.beneficiaryDesignation = StepStatus.Active;
                        this.navigate(`/sra/retiree/application/${this.memberId}/${Steps.BeneficiaryDesignation}/${Steps.ManageBeneficiary}`)
                        break;
                    }
                    case Steps.ManageBeneficiary: {
                        this.model.stepStatus.manageBeneficiary = StepStatus.Active;
                        break;
                    }
                    case Steps.MemberDeclaration: {
                        this.model.stepStatus.memberDeclaration = StepStatus.Active;
                        this.beneficiaryDesignationCompleted = false;
                        break;
                    }
                    case Steps.TaxWithholding: {
                        this.model.stepStatus.taxWithholding = StepStatus.Active;
                        break;
                    }
                    case Steps.FederalTaxWithholding: {
                        this.model.stepStatus.federalTaxWithholding = StepStatus.Active;
                        break;
                    }
                    case Steps.StateTaxWithholding: {
                        this.model.stepStatus.stateTaxWithholding = StepStatus.Active;
                        break;
                    }
                    case Steps.DeclarationTaxWithholding: {
                        this.model.stepStatus.declarationTaxWithholding = StepStatus.Active;
                        this.taxWithHoldingModelCompleted = false;
                        break;
                    }
                    case Steps.DirectDeposit: {
                        this.model.stepStatus.directDeposit = StepStatus.Active;
                        break;
                    }
                    case Steps.DirectDepositAuthorization: {
                        this.model.stepStatus.directDepositAuthorization = StepStatus.Active;
                        this.directDepositModelCompleted = false;
                        break;
                    }
                    case Steps.SickLeave: {
                        this.model.stepStatus.sickLeave = StepStatus.Active;
                        this.accruedSickLeaveModelCompleted = false;
                        break;
                    }
                    case Steps.DispositionOfAccruedSickLeave: {
                        this.model.stepStatus.dispositionOfAccruedSickLeave = StepStatus.Active;
                        this.accruedSickLeaveModelCompleted = false;
                        break;
                    }
                    case Steps.ScreaMembership: {
                        this.model.stepStatus.screaMembership = StepStatus.Active;
                        this.screaMembershipModelCompleted = false;
                        break;
                    }
                    case Steps.MembershipApplication: {
                        this.model.stepStatus.membershipApplication = StepStatus.Active;
                        break;
                    }
                    case Steps.ServiceRetirementSignOff: {
                        this.model.stepStatus.serviceRetirementSignOff = StepStatus.Active;
                        this.signOffModelCompleted = false;
                        break;
                    }
                    case Steps.ServiceRetirementChecklist: {
                        this.model.stepStatus.serviceRetirementChecklist = StepStatus.Active;
                        break;
                    }
                    case Steps.ReviewApplication: {
                        this.model.stepStatus.reviewApplication = StepStatus.Active;
                        const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${this.submissionId}/6105b`);
                        if (result.success) {
                            this.afrFile = result.data
                        } else {
                            Appl.Error.handle(result.error);
                        }
                        break;
                    }
                    case Steps.UploadDocuments: {
                        this.model.stepStatus.uploadDocuments = StepStatus.Active;
                        this.documentUploadModelCompleted = false;
                        break;
                    }
                    case Steps.SubmitApplication: {
                        this.model.stepStatus.submitApplication = StepStatus.Active;
                        break;
                    }
                    default: {
                        break;
                    }
                }
                await this._service?.updateSubmissionStepStatus(this.submissionId, this.model.stepStatus);

            })
        } else {
            Appl.Error.handle(result.error);
            return;
        }
    }

    @action public async onGettingStartedStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.gettingStarted = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onRetirementDateStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.retirementDate = StepStatus.InProgress;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onSelectDateChangeAsync(): Promise<boolean> {
        this.submissionId = await this.getSubmissionIdAsync();
        this.model.personalInformation.submissionId = this.submissionId;
        Appl.Validator.init();
        Appl.Validator.validateDateOnly("Retirement Date", "retirementEffDate", this.model.personalInformation.retirementEffYear, this.model.personalInformation.retirementEffMonth, this.model.personalInformation.retirementEffDay, true);
        if (!Appl.Validator.isValid()) { return false; }

        this.model.personalInformation.retirementEffDate = Helper.getDateModel(this.model.personalInformation.retirementEffYear!, this.model.personalInformation.retirementEffMonth!, this.model.personalInformation.retirementEffDay!);
        if (this.model.personalInformation.retirementEffDate <= new Date()) {
            await Appl.Validator.setError("retirementEffDate", "Retirement Date should be future date.");
            return false;
        }
        this.model.personalInformation.retirementEffDate = Helper.toServerDateTime(this.model.personalInformation.retirementEffDate);
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (result.success) {
            this.retirementDateConfirmed = true;
            this.model.stepStatus.retirementDate = StepStatus.Completed;
            this.model.stepStatus.selectDate = StepStatus.Completed;
            await this._service?.updateSubmissionStepStatus(this.submissionId, this.model.stepStatus);
            return true;
        } else {
            await Appl.Validator.setError("retirementEffDate", result.error?.detail!);
            return false;
        }
    }

    @action public async onPersonalInformationStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.personalInformation = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onConfirmYourInformationStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validateString('First Name', 'firstName', this.model.personalInformation.firstName, true, 100);
        await Appl.Validator.validateString('Middle Name', 'middleName', this.model.personalInformation.middleName, false, 10);
        await Appl.Validator.validateString('Last Name', 'lastName', this.model.personalInformation.lastName, true, 100);
        Appl.Validator.validateDateOnly("Birth Date", "BirthDate", this.model.personalInformation.birthYear, this.model.personalInformation.birthMonth, this.model.personalInformation.birthDay, true);
        if (!Appl.Validator.isValid()) { return false; }
        this.model.personalInformation.birthDate = Helper.getDateModel(this.model.personalInformation.birthYear!, this.model.personalInformation.birthMonth!, this.model.personalInformation.birthDay!);
        this.model.personalInformation.birthDate = Helper.toServerDateTime(this.model.personalInformation.birthDate);
        this.model.personalInformation.personalInfoVerified = 'Y';
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.model.stepStatus.confirmYourInformation = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async setAddressEditable(editable: boolean): Promise<void> {
        this.addressEditable = editable;
    }

    @action public async onAddressStepChangeAsync(): Promise<boolean> {
        if (!(this.model.personalInformation.homeAddress || this.model.personalInformation.mailingAddress)) {
            this.showError("Please add address.");
            return false;
        }
        this.navigate(`/sra/retiree/application/${this.memberId}/${Steps.PersonalInformation}/${Steps.ContactInformation}`);
        this.model.stepStatus.addressVerification = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onAddressAddEditAsync(): Promise<boolean> {

        Appl.Validator.init();
        await Appl.Validator.validateString('Home Address', 'homeRawAddress', this.model.personalInformation.homeRawAddress, true, 200);
        // await Appl.Validator.validateString('Home Street', 'homeAddress', this.model.personalInformation.homeAddress, true, 200);
        // await Appl.Validator.validateString('Home City', 'homeCity', this.model.personalInformation.homeCity, true, 100);
        // await Appl.Validator.validateString('Home State', 'homeState', this.model.personalInformation.homeState, true, 50);
        // await Appl.Validator.validateZip('Home Zip', 'homeZip', this.model.personalInformation.homeZip, true, 5);
        await Appl.Validator.validateString('Home Country', 'homeCountry', this.model.personalInformation.homeCountry, true, 50);

        await Appl.Validator.validateString('Mailing Address', 'mailingRawAddress', this.model.personalInformation.mailingRawAddress, false, 200);
        // await Appl.Validator.validateString('Mailing Street', 'mailingAddress', this.model.personalInformation.mailingAddress, false, 200);
        // await Appl.Validator.validateString('Mailing City', 'mailingCity', this.model.personalInformation.mailingCity, false, 100);
        // await Appl.Validator.validateString('Mailing State', 'mailingState', this.model.personalInformation.mailingState, false, 50);
        // await Appl.Validator.validateZip('Mailing Zip', 'mailingZip', this.model.personalInformation.mailingZip, false, 5);
        await Appl.Validator.validateString('Mailing Country', 'mailingCountry', this.model.personalInformation.mailingCountry, false, 50);

        if (!Appl.Validator.isValid()) { return false; }
        this.model.personalInformation.validateAddress = true;
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        let data = result.data;
        this.model.personalInformation.validateAddress = false;
        this.model.personalInformation.homeRawAddress = data.homeRawAddress;
        this.model.personalInformation.homeAddress = data.homeAddress;
        this.model.personalInformation.homeCity = data.homeCity;
        this.model.personalInformation.homeState = data.homeState;
        this.model.personalInformation.homeZip = data.homeZip;
        this.model.personalInformation.mailingRawAddress = data.mailingRawAddress;
        this.model.personalInformation.mailingAddress = data.mailingAddress;
        this.model.personalInformation.mailingCity = data.mailingCity;
        this.model.personalInformation.mailingState = data.mailingState;
        this.model.personalInformation.mailingZip = data.mailingZip;
        this.model.stepStatus.addressVerification = StepStatus.Completed;
        this.addressEditable = false;
        return true;
    }

    @action public async setAlternativeNumberEditable(editable: boolean): Promise<void> {
        this.alternativeNumberEditable = editable;
    }

    @action public async onContactStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validatePhone('Primary Phone Number', 'phone', this.model.personalInformation.phone, true, 12);
        await Appl.Validator.validateString('Phone Type', 'phoneType', this.model.personalInformation.phoneType, true, 10);
        await Appl.Validator.validateString('Alternative Phone Type', 'alternatePhoneType', this.model.personalInformation.alternatePhoneType, false, 10);
        await Appl.Validator.validateEmail('Email', 'email', this.model.personalInformation.email, true, 200);
        if (!Appl.Validator.isValid()) { return false; }
        this.model.personalInformation.phone = this.model.personalInformation.phone?.toString().replaceAll('-', '');
        if (this.model.personalInformation.alternatePhone && this.model.personalInformation.alternatePhone !== '') {
            this.model.personalInformation.alternatePhone = this.model.personalInformation.alternatePhone?.toString().replaceAll('-', '');
        }
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.model.stepStatus.contactInformation = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onPersonalStatusStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validateString('Personal Status', 'lovPersonalStatusId', this.model.personalInformation.lovPersonalStatusId, true, 10);
        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.personalInformationCompleted = true;
        this.model.stepStatus.personalStatus = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onBeneficiaryDesignationStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.beneficiaryDesignation = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action async addBeneficiaryAsync(isSpouse: boolean = false): Promise<void> {
        this.isAddingBeneficiary = true;
        this.beneficiaryModel = {};
        if (this.model.beneficiaryDesignation.beneficiaries) {
            this.model.beneficiaryDesignation.beneficiaries.push({ afrMbrAffidavitId: this.model.beneficiaryDesignation.afrMbrAffidavitId });
            this.selectedBeneficiary = this.model.beneficiaryDesignation.beneficiaries.length - 1;
            this.beneficiaryModel = this.model.beneficiaryDesignation.beneficiaries[this.selectedBeneficiary];
        }
        this.beneficiaryModel.beneRelationship = isSpouse ? 'Spouse' : 'Other';
    }

    @action async editBeneficiaryAsync(index: number): Promise<void> {
        this.isEditingBeneficiary = true;
        this.selectedBeneficiary = index;
        if (this.model.beneficiaryDesignation.beneficiaries) {
            this.beneficiaryModel = this.model.beneficiaryDesignation.beneficiaries[index] as BeneficiaryModel;
            this.beneficiaryModel.beneBirthDateYear = Helper.getDayMonthYearModel(this.beneficiaryModel.beneBirthDate).year;
            this.beneficiaryModel.beneBirthDateMonth = Helper.getDayMonthYearModel(this.beneficiaryModel.beneBirthDate).month;
            this.beneficiaryModel.beneBirthDateDay = Helper.getDayMonthYearModel(this.beneficiaryModel.beneBirthDate).day;
        }
    }

    @action async removeBeneficiaryAsync(index: number): Promise<void> {
        this.selectedBeneficiary = index;
        this.confirmDialogOperation = 'REMOVE_BENEFICIARY';
        this.confirmDialogTitle = "Remove Beneficiary";
        this.confirmDialogMessage = 'Are you sure you want to remove this beneficiary?';
        this.showConfirmDialog = true;
    }

    @action async cancelAddBeneficiaryAsync(): Promise<void> {
        if (this.isAddingBeneficiary) {
            if (this.selectedBeneficiary > -1 && this.model.beneficiaryDesignation.beneficiaries && this.model.beneficiaryDesignation.beneficiaries.length > 0) {
                this.model.beneficiaryDesignation.beneficiaries.splice(this.selectedBeneficiary, 1);
            }
        }
        this.isAddingBeneficiary = false;
        this.isEditingBeneficiary = false;
        this.selectedBeneficiary = -1;
    }

    @action
    public async createOrUpdateBeneficiaryAsync(): Promise<boolean> {
        Appl.Validator.init();
        this.model.beneficiaryDesignation.submissionId = this.submissionId;
        await Appl.Validator.validateString('Relationship', `beneRelationship`, this.beneficiaryModel.beneRelationship, true, 50);
        await Appl.Validator.validateNumber('Percentage', `percentage`, this.beneficiaryModel.percentage, true, 100, 1);
        if (this.beneficiaryModel.beneRelationship !== "Trust/Organization") {
            Appl.Validator.validateDateOnly("Birth Date", "beneBirthDate", this.beneficiaryModel.beneBirthDateYear, this.beneficiaryModel.beneBirthDateMonth, this.beneficiaryModel.beneBirthDateDay, true);
            if (Appl.Validator.isValid()) {
                this.beneficiaryModel.beneBirthDate = Helper.getDateModel(this.beneficiaryModel.beneBirthDateYear!, this.beneficiaryModel.beneBirthDateMonth!, this.beneficiaryModel.beneBirthDateDay!);
                if (this.beneficiaryModel.beneBirthDate >= new Date()) {
                    await Appl.Validator.setError("beneBirthDate", "Birth Date should not be greater than today date.");
                }
                if (this.beneficiaryModel.beneRelationship === "Child") {
                    let ageDifMs = this.beneficiaryModel.beneBirthDate.getTime();
                    let ageDate = new Date(ageDifMs); // miliseconds from epoch
                    let age = Math.abs(ageDate.getUTCFullYear() - new Date().getFullYear());
                    if (age > 21) {
                        await Appl.Validator.setError("beneBirthDate", "Child should be less than 21 years old.");
                    }

                }

            }
            await Appl.Validator.validateNumber('Social Security Number', `beneSsn`, this.beneficiaryModel.beneSsn, true, 999999999);
        } else {
            await Appl.Validator.validateNumber('Tax Identification Number', `beneSsn`, this.beneficiaryModel.beneSsn, true, 999999999);
        }
        await Appl.Validator.validateString('First Name', `beneFirstName`, this.beneficiaryModel.beneFirstName, true, 100);
        await Appl.Validator.validateString('Last Name', `beneLastName`, this.beneficiaryModel.beneLastName, true, 100);
        await Appl.Validator.validateString('Address', `beneStreetAddress`, this.beneficiaryModel.beneStreetAddress, true, 250);
        await Appl.Validator.validateString('City', `beneCity`, this.beneficiaryModel.beneCity, true, 100);
        await Appl.Validator.validateString('State', `beneState`, this.beneficiaryModel.beneState, true, 50);
        await Appl.Validator.validateString('Country', `beneCountry`, this.beneficiaryModel.beneCountry, true, 50);
        await Appl.Validator.validateZip('Zip', `beneZip`, this.beneficiaryModel.beneZip, true, 5);
        if (!Appl.Validator.isValid()) {
            return false;
        }
        this.beneficiaryModel.beneBirthDate = Helper.toServerDateTime(this.beneficiaryModel.beneBirthDate);
        this.beneficiaryModel.afrMbrAffidavitId = this.model.beneficiaryDesignation.afrMbrAffidavitId;
        const result = await this._service?.createOrUpdateBeneficiaryAsync(this.beneficiaryModel);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false
        } else {
            runInAction(() => {
                this.beneficiaryModel.afrBeneId = result.data;
            })
        }
        this.isAddingBeneficiary = false;
        this.isEditingBeneficiary = false;
        this.selectedBeneficiary = -1;
        this.model.stepStatus.manageBeneficiary = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action async setMemberAggreedDeclarationAsync(agree: boolean): Promise<void> {

        if (agree) {
            if (!(this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married || this.model.personalInformation.lovPersonalStatusId === PersonalStatus.RegistedDomesticPartner)) {
                this.showError("Please verify that your personal status is not set to either Married or Registered Domestic Partner.");
                return;
            }
        }
        const response = await this._service?.setMemberAggreedDeclarationStatusAsync(this.submissionId!, agree);
        if (response.success) {
            this.beneficiaryDesignationCompleted = true;
            this.model.stepStatus.memberDeclaration = StepStatus.Completed;
            await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        } else {
            Appl.Error.handle(response.error);
        }
    }

    @action public async onConfirmDialogAsync(): Promise<void> {
        if (this.confirmDialogOperation === 'REMOVE_BENEFICIARY') {
            if (this.selectedBeneficiary > -1 && this.model.beneficiaryDesignation.beneficiaries && this.model.beneficiaryDesignation.beneficiaries.length > 0) {
                let ben = this.model.beneficiaryDesignation.beneficiaries[this.selectedBeneficiary];
                const benDeleteResponse = await this._service?.deleteBeneficiaryAsync(ben.afrBeneId!);
                if (benDeleteResponse.success) {
                    runInAction(() => {
                        this.model.beneficiaryDesignation.beneficiaries?.splice(this.selectedBeneficiary, 1);
                    });
                } else {
                    Appl.Error.handle(benDeleteResponse.error);
                }
            }
            this.selectedBeneficiary = -1;
        } else if (this.confirmDialogOperation === 'DELETE_DOCUMENT') {
            const result = await this._service?.deleteDocumentsAsync(this.activeDocument.docId!);
            if (!result.success) {
                Appl.Error.handle(result.error);
            } else {
                this.model.uploadedDocuments = this.model.uploadedDocuments?.filter(item => item !== this.activeDocument)
                this.showSuccess('Deleted Sucessfully');
            }
        }
        this.showConfirmDialog = false;
    }



    @action public async onTaxWithholdingStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.taxWithholding = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onFederalTaxWithholdStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();

        if (this.model.taxWithHolding.federalTaxWithHolding?.federalWithholdIncomeTax) {
            if (this.model.taxWithHolding.federalTaxWithHolding?.federalWithholdIncomeTaxStatus === '') {
                await Appl.Validator.setError('federalWithholdIncomeTaxStatus', 'Please select a filing status.');
            }
        }
        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.createOrUpdateFederalTaxWithholdingAsync(this.model.taxWithHolding.federalTaxWithHolding!);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.model.stepStatus.federalTaxWithholding = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action
    public async onStateTaxWithholdStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTax) {
            if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === undefined) {
                await Appl.Validator.setError('stateWithholdIncomeTaxStatus', 'Please select a filing status.');
            } else {
                if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "M") {
                    await Appl.Validator.validateNumber('Allowance', `marriedWithholdStateIncomeAllowance`, this.model.taxWithHolding.stateTaxWithHolding?.stateMarriedWithholdIncomeAllowance, true);
                } else if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "S") {
                    await Appl.Validator.validateNumber('Allowance', `singleWithholdStateIncomeAllowance`, this.model.taxWithHolding.stateTaxWithHolding?.stateSingleWithholdIncomeAllowance, true);
                } else if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "H") {
                    await Appl.Validator.validateNumber('Allowance', `headOfHouseHoldStateIncomeAllowance`, this.model.taxWithHolding.stateTaxWithHolding?.stateHeadOfHouseHoldIncomeAllowance, true);
                }
            }
        }
        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.createOrUpdateStateTaxWithholdingAsync(this.model.taxWithHolding.stateTaxWithHolding!);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.model.stepStatus.stateTaxWithholding = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action
    public async setTaxWithholdingAggreedDeclarationAsync(agree: boolean): Promise<void> {
        const response = await this._service?.setTaxWithholdAggreedDeclarationStatusAsync(this.submissionId!, agree);
        if (response.success) {
            this.taxWithHoldingModelCompleted = true;
            this.model.stepStatus.declarationTaxWithholding = StepStatus.Completed;
            await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        } else {
            Appl.Error.handle(response.error);
        }
    }

    @action public async onDirectDepositStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.directDeposit = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action
    public async createOrUpdateDirectDepositAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validateString('Institution Name', 'institutionName', this.model.directDeposit.institutionName, true, 100);
        await Appl.Validator.validateNumber('9-Digit Routing Number', 'bankRoutingNumeric', this.model.directDeposit.bankRoutingNumeric, true);
        await Appl.Validator.validateString('Account Number', 'bankAccountNumeric', this.model.directDeposit.bankAccountNumeric, true, 50);
        if (this.model.directDeposit.lovAccountTypeId === 0) {
            await Appl.Validator.setError('lovAccountTypeId', 'Please select a account type.');
        }
        if (!Appl.Validator.isValid()) { return false; }
        this.model.directDeposit.submissionId = this.submissionId;
        const result = await this._service?.createOrUpdateDirectDepositAsync(this.model.directDeposit);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.directDepositModelCompleted = true;
        this.model.stepStatus.directDepositAuthorization = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onSickLeaveStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.sickLeave = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action
    public async createOrUpdateAccruedSickLeaveAsync(): Promise<boolean> {
        const result = await this._service?.createOrUpdateAccruedSickLeavesync(this.model.accruedSickLeave);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.accruedSickLeaveModelCompleted = true;
        this.model.stepStatus.dispositionOfAccruedSickLeave = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    public onSickLeaveCreditChange(e: any, fieldName: string): void {
        if (fieldName === 'sickLeaveCreditN') {
            this.model.accruedSickLeave.sickLeaveCredit = !e.target.checked;
            this.model.accruedSickLeave.sickLeaveCreditN = e.target.checked;
        } else {
            this.model.accruedSickLeave.sickLeaveCredit = e.target.checked;
            this.model.accruedSickLeave.sickLeaveCreditN = !e.target.checked;
        }
        if (!this.model.accruedSickLeave.sickLeaveCredit) {
            this.model.accruedSickLeave.sickLeaveHours = 0;
        }
    }

    @action public async onScreaMembershipStepChangeAsync(intersted: boolean): Promise<boolean> {
        this.model.stepStatus.screaMembership = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        if (intersted) {
            this.navigate(`/sra/retiree/application/${this.memberId}/${Steps.ScreaMembership}/${Steps.MembershipApplication}`)
        } else {
            this.screaMembershipModelCompleted = true; this.navigate(`/sra/retiree/application/${this.memberId}/${Steps.ScreaMembership}/${Steps.MembershipApplication}`)
        }
        return true;
    }

    @action
    public async createOrUpdateScreaMembershipAsync(): Promise<boolean> {

        Appl.Validator.init();
        if (this.model.screaMembership.associateMembership) {
            await Appl.Validator.validateString('Associate Member Type', 'associateMbrType', this.model.screaMembership.associateMbrType, true, 10);
            await Appl.Validator.validateString('Associate Member Name', 'associateMbrName', this.model.screaMembership.associateMbrName, true, 100);
        }
        if (!Appl.Validator.isValid()) { return false; }
        this.model.screaMembership.submissionId = this.submissionId;
        const result = await this._service?.createOrUpdateScreaMembershipAsync(this.model.screaMembership);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.screaMembershipModelCompleted = true;
        this.model.stepStatus.membershipApplication = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async onServiceRetirementSignOffChangeAsync(): Promise<boolean> {
        this.model.stepStatus.serviceRetirementSignOff = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action
    public async createOrUpdateServiceRetirementChecklistAsync(): Promise<boolean> {

        const result = await this._service?.createOrUpdateServiceRetirementSignOffAsync(this.submissionId!, this.model.signOffModels);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.signOffModelCompleted = true;
        this.model.stepStatus.serviceRetirementChecklist = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action onSignOffCheckboxChange = (e: any, model?: object, fieldId?: string) => {
        (model as any)[fieldId!] = e.target.checked;
        if (e.target.checked) {
            this.signOffCheckedItemsCount++;
        } else {
            this.signOffCheckedItemsCount--;
        }
    };

    @action public async onReviewApplicationStepChangeAsync(): Promise<boolean> {
        this.model.stepStatus.reviewApplication = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    @action public async showUploadDialogAsync(): Promise<void> {
        this.uploadDialogVisible = true;
        this.activeDocument = { docTypeId: 1 }
        this.activeDocument.docType = this.model.documentList?.find(o => o.key === 1)?.value;
    }

    @action public async hideUploadDialogAsync(): Promise<void> {
        this.uploadDialogVisible = false;
        this.activeDocument = { docTypeId: 1 }
        this.activeDocument.docType = this.model.documentList?.find(o => o.key === 1)?.value;
    }

    @action public async onAddDocumentAsync(): Promise<void> {

        if (!this.model.uploadedDocuments) {
            this.model.uploadedDocuments = [];
        }
        if (!this.activeDocument.file) {
            this.showError('Please Add Documents');
            return;
        }
        this.model.uploadedDocuments.push(this.activeDocument);
        await this.hideUploadDialogAsync();
    }

    @action public async onRemoveDocumentAsync(document: DocumentsModel): Promise<void> {
        if (document.isNew) {
            this.model.uploadedDocuments = this.model.uploadedDocuments?.filter(item => item !== document)
        } else {
            this.activeDocument = document;
            this.confirmDialogOperation = 'DELETE_DOCUMENT';
            this.confirmDialogMessage = 'Are you sure you want to remove this document?';
            this.showConfirmDialog = true;
        }
    }

    @action public async downloadDocumentAsync(id: number, fileName: string): Promise<void> {
        const result = await this._service?.downloadAsync(`/retirement/Retiree/download/${id}`);
        if (!result.success) {
            Appl.Error.handle(result.error);
        } else {
            Helper.downloadFile(result.data, fileName, '');
        }
    }

    @action public async getAppContentAsPdfAsync(submissionId: number, templateId: string, fileName: string): Promise<void> {
        const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${submissionId}/${templateId}`);
        if (!result.success) {
            Appl.Error.handle(result.error);
        } else {
            Helper.downloadFile(result.data, fileName, '');
        }
    }

    // @action public async getAppContentAsPdfBlobAsync(submissionId: number, templateId: string): Promise<any> {
    //     const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${submissionId}/${templateId}`);
    //     if (!result.success) {
    //         Appl.Error.handle(result.error);
    //     }
    //     this.afrFile = result.data
    // }

    @action public async onSubmitRequiredDocumentsAsync(): Promise<void> {
        this.documentUploadModelCompleted = false;
        let message = "";
        if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married) {
            //Marriage Certificate & spouse birth certificate
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.MarriageRegistrationCertificate)) {
                message += "<li>Marriage Certificate is required.</li>"
            }
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate)) {
                message += "<li>Spouse Birth Certificate is required.</li>"
            }

        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.RegistedDomesticPartner) {
            //require certificate of domestic partnership and partner's birth certificate
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate)) {
                message += "<li>Partner's Birth Certificate is required.</li>"
            }
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerSsn)) {
                message += "<li>Certificate of Domestic Partnership is required.</li>"
            }
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Divorced) {
            //require DRO
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.CourtEndorsedDomesticRelationsOrder)) {
                message += "<li>A Court Endorsed Domestic Relations Order (DRO) is required.</li>"
            }
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Widowed) {
            //require spouse's death certificate
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDeathCertificate)) {
                message += "<li>Spouse's Death Certificate is required.</li>"
            }
        }
        if (this.model.directDeposit.lovAccountTypeId === AccountType.CheckingAccount) {
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.VoidedCheck)) {
                message += "<li>Voided Check is required.</li>"
            }
        } else if (this.model.directDeposit.lovAccountTypeId === AccountType.SavingsAccount) {
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.CertifiedBankLetterOrSavingsAccountStatement)) {
                message += "<li>Certified bank letter or savings account statement is required.</li>"
            }
        }
        if (this.model.signOffModels.find(o => o.lovServiceSignOffId === 7)?.active) {//Temporary Annuity
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.TemporaryAnnuitiesEstimateFromSocialSecurityOffice)) {
                message += "<li>Temporary annuities estimate from Social Security office is required.</li>"
            }
        }
        if (this.model.beneficiaryDesignation?.beneficiaries && this.model.beneficiaryDesignation?.beneficiaries.length > 0) {
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate)) {
                message += "<li>Beneficiaries Birth Certificates are required.</li>"
            }
        }
        if (message !== "") {
            message = `Below documents are rquired.<ul class="list-disc ml-4 pb-6">${message}</ul>`
            this.showError(message);
            return;
        }

        if (this.model.uploadedDocuments.find(o => o.isNew)) {
            let result = await this._service?.uploadAsync(`/retirement/Retiree/createOrUpdateRequiredDocuments/${this.submissionId}`, this.model.uploadedDocuments);
            if (!result.success) {
                Appl.Error.handle(result.error);
                return;
            } else {
                result = await this._service?.getSubmittedDocumentsAsync(this.submissionId!);
                if (result.success) {
                    runInAction(() => {
                        this.model.uploadedDocuments = result.data;
                    });
                }
            }
        }
        this.documentUploadModelCompleted = true;
        this.model.stepStatus.uploadDocuments = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
    };

    @action
    public async submitApplicationAsync(): Promise<boolean> {
        const result = await this._service?.submitApplication(this.submissionId!);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.navigate(`/sra/retiree/afr-status/${this.memberId}`)
        this.submitApplicationCompleted = true;
        this.model.stepStatus.submitApplication = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
        return true;
    }

    public async getSubmissionStatus(memberId: string): Promise<void> {
        const result = await this._service?.getSubmissionByMemberIdAsync(memberId, true);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return;
        } else {
            runInAction(() => {
                this.submission = result.data
            });
        }
    }

    @action public async showPrintDialog(submissionId: number): Promise<void> {
        const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${submissionId}/6105b`);
        if (result.success) {
            this.afrFile = result.data
        } else {
            Appl.Error.handle(result.error);
        }
        this.printDialogVisible = true;
    }

    @action public async hidePrintDialog(): Promise<void> {
        this.printDialogVisible = false;
    }

    @action public async searchAddress(filter?: string): Promise<void> {
        const result = await this._service.getListOfValuesAsync('address-lookup', filter);
        runInAction(async () => {
            if (result.success) {
                this.addressListItems = result.data;
            } else {
                Appl.Error.handle(result.error);
            }

        });
    }
}