import { Service } from 'typedi';
import { LoginModel } from './login/LoginModel';
import { BaseService, IBaseService, ServerResponse } from '../../../core/services/BaseService';
import { Appl } from '../../../core/Appl';

export interface IAccountService extends IBaseService {
  loginAsync(model: LoginModel): Promise<ServerResponse>;
  // logoutAsync(): Promise<void>;
}

@Service() // eslint-disable-next-line
export class AccountService extends BaseService implements IAccountService {
  constructor() {
    super(`${Appl.Setting.Services.MbaseServiceUrl}`);
  }

  public async loginAsync(model: LoginModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/mbase/Auth/login`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return this.getErrorResponse(error);
    }
  }

  // public async logoutAsync(): Promise<void> {}
}
