import React from 'react';
import { observer } from 'mobx-react';
import { View } from '../../../../../core/components/Index';
import { useParams } from 'react-router-dom';
import SraModule from '../../SraModule';
import ApplyViewModel from './ApplyViewModel';
import useAsyncEffect from 'use-async-effect';
import { Appl } from '../../../../../core/Appl';
import { SubmissionStatus } from '../../SraEnums';

export const SubmissionStatusView: React.FC = observer(() => {
  const vm = Appl.Services.get<ApplyViewModel>('ApplyViewModel');
  let { memberId } = useParams();
  useAsyncEffect(async () => {
    await vm.getSubmissionStatus(memberId!);
  }, []);
  return (
    <>
      <View id="SubmissionStatusView" authorized={Appl.User.isAuthenticated}
        showMenuBar={Appl.User.isAuthenticated}
        menuItems={SraModule.MenuItems()}
      >
        <div className="space-y-6 mt-4">
          <h3 className="text-3xl font-semibold">Submission Status</h3>

          <h4 className="text-xl font-semibold">
            Retirement Application Form is {vm.submission!.submissionStatusDesc}!
          </h4>
          <p className="text-gray-600 text-justify text-base font-normal">
            {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Submitted && <>
              Congratulations! You have completed submission of your retirement application.
              <br /><br />We have received your application with reference #<b>{vm.submission.submissionId}</b>. We are in the process of reviewing your application and will notify you when it is done.
              <br /><br />You can refer the above Service Retirement reference #{vm.submission.submissionId} for your future communications.
            </>
            }
            {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Reviewing && <>
              We have received your application with reference #<b>{vm.submission?.submissionId}</b>. We are in the process of reviewing your application and will notify you when it is done.
              <br /><br />You can refer the above Service Retirement reference #{vm.submission?.submissionId} for your further communications.
            </>
            }
            {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Pending && <>
              We have received your application with reference #<b>{vm.submission?.submissionId}</b>. You application is currently kept pending.
              <br /><br />You can refer the above Service Retirement reference #{vm.submission?.submissionId} for your further communications.
            </>
            }
            {vm.submission?.lovSubmissionStatusId === SubmissionStatus.InformationRequired && <>
              We have received your application with reference #<b>{vm.submission?.submissionId}</b>. You application requires further information.
              <br /><br />You can refer the above Service Retirement reference #{vm.submission?.submissionId} for your further communications.
            </>
            }
            {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Completed && <>
              Your retirement application with reference #<b>{vm.submission?.submissionId}</b> is completed.
              <br /><br />You can refer the above Service Retirement reference #{vm.submission?.submissionId} for your further communications.
            </>
            }
            {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Rejected && <>
              Your retirement application with reference #<b>{vm.submission?.submissionId}</b> is rejected.
              <br /><br />You can refer the above Service Retirement reference #{vm.submission?.submissionId} for your further communications.
            </>
            }
          </p>
        </div>
      </View>
    </>
  );
});
