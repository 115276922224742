import { observer } from "mobx-react";
import { Button, ButtonType } from "../actions/Button";

export interface ViewHeaderProps {
    title: string | React.ReactNode;
    description?: string;
    helpPageId?: string;
    children?: React.ReactNode;
    className?: string;
    backUrl?: string;
}
export const ViewHeader: React.FC<ViewHeaderProps> = observer(
    ({ className = 'flex items-center justify-between md:space-x-6 flex-wrap md:flex-nowrap items-center pb-2 ', ...props }) => {
        const helpUrl = `/help/${props.helpPageId ?? ''}`;
        return (
            <>
                <div className={className}>
                    <h1 className="w-full md:w-auto font-semibold text-2xl md:text-32px tracking--1percent text-neutral-800 py-2">{props.title}</h1>
                    <div className="w-full md:w-auto flex items-center flex-wrap md:flex-nowrap">
                        {props.children ?? props.children} 
                        {props.helpPageId && (
                            <Button
                                buttonType={ButtonType.Link}
                                url={helpUrl}
                                icon="circle-help"
                                title="Help"
                                tooltip={props.description}
                                rel="noreferrer"
                                className="btn btn-sm btn-outline-secondary me-2"
                            />
                        )}
                        {props.backUrl && (
                            <Button
                                buttonType={ButtonType.Link}
                                url={props.backUrl}
                                icon="arrow-left"
                                title="Back"
                                rel="noreferrer"
                                className="btn btn-sm btn-outline-secondary"
                            />
                        )}
                    </div>
                </div>
            </>
        );
    },
);