import React from 'react';
import { observer } from 'mobx-react';

export const InputErrors: React.FC<{
  errors?: Map<string, string>;
  viewId?: string;
  suffixid?: string;
}> = observer((props) => {
  const errorLabels = () => {
    const spans: Array<React.ReactNode> = [];
    if (props.viewId) {
      const id = props.suffixid !== undefined ? props.viewId + props.suffixid : props.viewId;
      const error = props.errors?.get(id);
      if (error) {
        spans.push(
          <span
                className="text-red-600 text-sm font-medium mt-1 block"
            id={`${props.viewId}-feedback`}
            key={`${props.viewId}-feedback`}>
            {error}
          </span>,
        );
      } else {
        if (props.errors?.has("")) {
          let messages = props.errors?.get("");
          if (messages && messages.length > 0) {
            alert(messages[0]);
          }
        }
      }
    }
    return spans;
  };

  return <>{errorLabels()}</>;
});
