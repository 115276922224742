import React, { useState } from 'react';
import './MenuBarView.css';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Menu } from 'lucide-react';
import { NavItem } from '../../../core/services/Models';

export interface MenuBarViewProps {
  profileTitle?: string | React.ReactNode;
  profilePictureUrl?: string;
  role?: string;
  profileUrl?: string;
  logoutUrl?: string;
  menuItems?: Array<NavItem>;
}

export const MenuBarView: React.FC<MenuBarViewProps> = observer((props) => {
  const [visible, setVisible] = useState(false);

  const navLinkClassName = 'text-pine-700 hover:text-pine-800 font-medium border-b-2 block py-0.5 tracking--1_5percent';

  return (
    <>
      {/* on desktop show all links in a row */}
      <ul className="hidden md:flex mt-0 w-full  text-15px font-normal gap-x-8 py-3">
        {props.menuItems?.map((menuItem, index) => {
          const activeClassName = window.location.pathname === menuItem.path ? 'border-primary-600' : ' border-transparent';
          return (
            <li key={`menuItem-${index}`} className="block border-b dark:border-gray-700 lg:inline lg:border-b-0">
              <Link to={menuItem.path!} className={`${navLinkClassName} ${activeClassName}`} aria-current="page">
                {/* {menuItem.icon && <Icon name={menuItem.icon} className="me-2" />} */}
                {menuItem.label}
              </Link>
            </li>
          );
        })}
      </ul>

      <nav className="-mx-6 px-4 md:hidden flex items-center border-b">
        <div className="border-r pr-3 mr-3">
          <Sidebar visible={visible} onHide={() => setVisible(false)} pt={{
            content: {
              className: 'px-0'
            },
          }}>
            <ul className=" mt-0 w-full  text-15px font-normal py-3">
              {props.menuItems?.map((menuItem, index) => {
                const activeClassName = window.location.pathname === menuItem.path ? 'border-primary-600' : ' border-white';
                return (
                  <li key={`menuItem-${index}`} className="block border-b dark:border-gray-700 lg:inline lg:border-b-0">
                    <Link to={menuItem.path!} className={`${navLinkClassName} ${activeClassName} py-3 px-5`} aria-current="page">
                      {/* {menuItem.icon && <Icon name={menuItem.icon} className="me-2" />} */}
                      {menuItem.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Sidebar>
          <Button severity="secondary" outlined onClick={() => setVisible(true)} size="small" className="!border-none">
            <Menu size={20} />
          </Button>
        </div>
        <ul className="flex flex-col mt-0 w-full  text-15px font-normal lg:flex-row gap-x-8 py-3">
          {/* show the active link onnly  from props.menuItems find it based on window.location.pathname === menuItem.href */}
          {props.menuItems
            ?.filter((menuItem) => window.location.pathname === menuItem.path)
            .map((menuItem, index) => {
              return (
                <li key={`menuItem-${index}`} className="block">
                  <Link
                    to={menuItem.path!}
                    className={`text-pine-700 hover:text-pine-800 font-medium block py-0.5 tracking--1_5percent`}
                    aria-current="page">
                    {/* {menuItem.icon && <Icon name={menuItem.icon} className="me-2" />} */}
                    {menuItem.label}
                  </Link>
                </li>
              );
            })}
        </ul>
      </nav>
    </>
  );
});
