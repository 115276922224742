import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { IAccountService } from '../AccountService';
import { LoginModel } from './LoginModel';
import { Appl } from '../../../../core/Appl';
import { Role } from '../../../../core/services/Enums';
import { AuthContext } from '../../../../core/services/AuthContext';
import { AuthProvider } from '../../../../core/services/IUserContext';
import { ServerResponse } from '../../../../core/services/BaseService';

@Service()
export default class LoginViewModel extends BaseViewModel {
  @observable public model: LoginModel = {};
  private _service: IAccountService;

  constructor() {
    super();
    this._service = Appl.Services.get<IAccountService>('IAccountService');
    makeObservable(this);
  }

  @action public async initAsync() {
    this.pageTitle = 'SCERS - Login';
    if (Appl.User.isAuthenticated) {
      this.navigate('/sra');
    }
  }


  @action public async loginAsync(authProvider: AuthProvider = "Custom") {
    let result: ServerResponse;
    if (authProvider == "Custom") {
      const valid = await this.validateModelAsync(this.model);
      if (!valid) {
        return;
      }
    }
    result = await AuthContext.instance.signInAsync(authProvider, this.model.username, this.model.password);
    if (result.success) {
      window.location.assign(`/sra`);
      // if (Appl.User.hasRole(Role.Member)) {
      //   window.location.assign(`/sra`);
      // } else if (Appl.User.hasRole(Role.Admin)) {
      //   window.location.assign(`/sra/retiree`);
      // } else {
      //   window.location.assign(window.location.href);
      // }
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action public async validateModelAsync(model: LoginModel): Promise<boolean> {
    Appl.Validator.init();
    await Appl.Validator.validateString('User Name', 'username', model.username, true);
    await Appl.Validator.validateString('Password', 'password', model.password, true);
    return Appl.Validator.isValid();
  }

  @action public async forgotPassowrdAsync() {
    this.navigate('ForgotPassowrdView');
  }

  @action public async registerAsync() {
    this.navigate('RegisterView');
  }
}
