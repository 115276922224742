import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Step, SubStep } from './SidebarFormNavigation';

interface FormData {
  activeStepNumber?: number;
  activeStep?: Step;
  activeSubStep?: SubStep;
  nextStep?: any,
  stepsFlatList?: any;
  currentStep?: string | number;
  completedStep?: string | number;
  retirementSteps: any;
  // submissionId?: string| number;
  data: any;
  errors?: any;
}

interface FormContextType {
  formData: FormData;
  updateFormData: (key: keyof FormData, data: any) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useForm = (): FormContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({
    activeStepNumber: 0,
    currentStep: '',
    completedStep: '',
    retirementSteps: [],
    // submissionId: 1,
    data: {},
    errors: []
  });

  const updateFormData = (step: keyof FormData, data: string) => {
    setFormData((prev) => ({ ...prev, [step]: data }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};