import { Cookies } from 'react-cookie';

export interface ICacheService {

  get<T>(key: string): T | null;
  addOrUpdate<T>(key: string, value: T): void;
  remove(key: string): void;
  removeAll(): void;

  getSession<T>(key: string): T | null;
  addOrUpdateSession<T>(key: string, value: T): void;
  removeSession(key: string): void;
  removeAllSession(): void;

  getCookie<T>(key: string): T | null;
  addOrUpdateCookie<T>(key: string, value: T): void;
  removeCookie(key: string): void;
  removeAllCookie(): void;

  removeAllCache(): void;
}

export class CacheService implements ICacheService {

  private static _instance: CacheService;
  public static get instance() {
    return this._instance ?? (this._instance = new CacheService());
  }

  private _useEncoding: boolean = true;
  public get<T>(key: string): T | null {
    let item = localStorage.getItem(key);
    if (item === null) { return null; }
    let decoded = this.decode(item);
    try {
      return JSON.parse(decoded) as T
    } catch {
      return decoded as T
    }
  }

  public addOrUpdate<T>(key: string, value: T): void {
    let item = this.encode(value?.toString())
    localStorage.setItem(key, item);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public removeAll(): void {
    localStorage.clear();
  }

  public getSession<T>(key: string): T | null {
    let item = sessionStorage.getItem(key);
    if (item === null) { return null; }
    let decoded = this.decode(item);
    try {
      return JSON.parse(decoded) as T
    } catch {
      return decoded as T
    }
  }

  public addOrUpdateSession<T>(key: string, value: T): void {
    let item = this.encode(value?.toString())
    sessionStorage.setItem(key, item);
  }

  public removeSession(key: string): void {
    sessionStorage.removeItem(key);
  }

  public removeAllSession(): void {
    sessionStorage.clear();
  }

  public getCookie<T>(key: string): T | null {
    const cookies = new Cookies();
    const item = cookies.get(key);
    if (item === null) { return null; }
    let decoded = this.decode(item);
    try {
      return JSON.parse(decoded) as T
    } catch {
      return decoded as T
    }
  }

  public addOrUpdateCookie<T>(key: string, value: T): void {
    let item = this.encode(value?.toString())
    const cookies = new Cookies();
    cookies.set(key, item, {
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
    });
  }

  public removeCookie(key: string): void {
    const cookies = new Cookies();
    cookies.remove(key)
  }

  public removeAllCookie(): void {
    const cookies = new Cookies();
    let items = cookies.getAll();
    //TODO: Remove All
  }

  public removeAllCache(): void {
    this.removeAll();
    this.removeAllSession();
    this.removeAllCookie();
  }

  private encode(text?: string): string {
    if (!text) {
      return "";
    }
    if (this._useEncoding) {
      return btoa(text)
    }
    return text;
  }

  private decode(text?: string): string {
    if (!text) {
      return "";
    }
    if (this._useEncoding) {
      return atob(text)
    }
    return text;
  }
}

