import { Appl } from '../Appl';
import { AuthProvider } from './IUserContext';
import { ServerResponse } from './BaseService';
import { UserContextBuiltIn } from './UserContextBuiltIn';
import { UserContextAzure } from './UserContextAzure';

export class AuthContext {

    private static _instance: AuthContext;
    public static get instance() {
        return this._instance ?? (this._instance = new AuthContext());
    }

    public async signInAsync(authProvider: AuthProvider, username?: string, password?: string): Promise<ServerResponse> {

        let result: ServerResponse = { success: false };
        if (authProvider === "Custom") {
            result = await UserContextBuiltIn.instance.signInAsync(username, password);
        } else if (authProvider === "AzureAD") {
            result = await UserContextAzure.instance.signInAsync();
        }
        return result;
    }

    public async signOutAsync(navigateUrl: string = ''): Promise<void> {
        Appl.Cache.removeAllCache()
        if (navigateUrl && navigateUrl !== "") {
            window.location.href = navigateUrl;
        } else {
            window.location.href = "/";
        }
    }


}

