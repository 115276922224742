import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RouteConfig } from './config/RouteConfig';

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <RouteConfig />
  </BrowserRouter>,
)