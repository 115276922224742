import { CSSProperties, useEffect } from "react";
import { NotAuthorizedView } from "../../pages/NotAuthorizedView";
import { MenuBarView } from "./MenuBarView";
import { observer } from "mobx-react";
import { Container } from "./Container";
import "./View.css"
import { NavItem } from "../../../core/services/Models";

export interface ViewProps {
    id: string;
    pageTitle?: string | React.ReactNode;
    description?: string;
    children?: React.ReactNode;
    authorized?: boolean;
    className?: string;
    style?: CSSProperties | undefined;
    profileTitle?: string | React.ReactNode;
    role?: string;
    profileUrl?: string;
    logoutUrl?: string;
    showMenuBar?: boolean;
    menuItems?: Array<NavItem>;
    container?: boolean;
}
export const View: React.FC<ViewProps> = observer(({ pageTitle = "", authorized = true, showMenuBar = false, container = true, className = 'flex-shrink-0 py-5', menuItems = [], ...props }) => {
    //by default, the layout will be in a container, but if the container is set to false, it will be a div
    const Layout = container ? Container : 'div';
    useEffect(() => {
        if (pageTitle === "") {
            document.title = `SCERS`;
        } else {
            document.title = `SCERS - ${pageTitle}`;
        }
    }, []);

    return (
        <>
            {showMenuBar && (
                <div>
                    <Container>
                        <MenuBarView menuItems={menuItems} profileTitle={props.profileTitle} role={props.role} logoutUrl={props.logoutUrl} profileUrl={props.profileUrl} />
                    </Container>
                </div>
            )}

            <main id={props.id} className={className} style={props.style}>
                <Layout>
                    {authorized && props.children}
                    {!authorized && <NotAuthorizedView />}
                </Layout>
            </main>
        </>
    );
},
);
