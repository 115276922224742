import React from 'react';
import { Calendar, CalendarBaseProps } from 'primereact/calendar';
import { observer } from 'mobx-react';
import { Nullable } from 'primereact/ts-helpers';
import { InputErrors } from './InputErrors';
import { Icon } from '../Icon';
import { Appl } from '../../../core/Appl';

interface CalendarExProps extends CalendarBaseProps {
  id: string;
  label?: string;
  helpText?: string;
  hidden?: boolean;
  value?: Nullable<Date>;
  onChange?(event: React.FormEvent<Date>): void;
  className?: string;
  suffixid?: string;
  showHint?: boolean,
}

export const InputCalendar: React.FC<CalendarExProps> = observer((props) => {
  const {
    id,
    label,
    helpText,
    hidden,
    value,
    onChange,
    className = '',
    suffixid,
    showHint,
    ...rest
  } = props;

  const renderLabel = () => {
    if (label && !hidden) {
      return (
        <label htmlFor={`cal-${id}`} className="font-bold text-15px text-black mb-1 block">
          {label}
          {props.required && <span style={{ color: 'red' }}> * </span>}
          {helpText && <Icon name="question-circle" />}
          {showHint && <span className='font-normal block'>mm/dd/yyyy</span>}
        </label>
      );
    }
    return null;
  };

  return (
    <div>
      {renderLabel()}
      {props.disabled ? (
        <div>{value?.toString()}</div>
      ) : (
        <>
          <Calendar
            inputClassName={props.readOnlyInput ? '!bg-gray-100 !cursor-not-allowed' : className}
            className={className}
            inputId={`cal-${id}`}
            value={value}
            placeholder={props.placeholder}
            dateFormat="mm/dd/yy"
            data-val-field-label={`${id}-feedback`}
            visible={!hidden}
            showIcon={!props.readOnlyInput}
            showOnFocus={false}
            aria-label={label}
            readOnlyInput={props.readOnlyInput}
            onChange={onChange as any}
            {...rest}
          />
          <InputErrors errors={Appl.Error?.items} viewId={id} suffixid={suffixid} />
        </>
      )}
    </div>
  );
});
