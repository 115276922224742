import { RapService } from './RapService';
import RapDashboardViewModel from './dashboard/RapDashboardViewModel';
import { Appl } from '../../../core/Appl';
import { NavItem } from '../../../core/services/Models';

export default class RapModule {

  public static MenuItems: Array<NavItem> = [
    { id: 'rap', label: 'Dashboard', path: '/rap' },
    { id: 'unimplemented', label: 'RAP Browser', path: '/unimplemented' },
    { id: 'unimplemented', label: 'Estimate RAP', path: '/unimplemented' },
    { id: 'unimplemented', label: 'Maintenance Tables', path: '/unimplemented' }
  ]

  public static init(): void {
    Appl.Services.add('IRapService', RapService);
    Appl.Services.add('RapDashboardViewModel', RapDashboardViewModel);
  }
}
