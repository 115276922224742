import React, { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { classNames } from 'primereact/utils';
import { useForm } from './FormContext';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Check, ChevronDown } from 'lucide-react';
export enum StepStatus {
  Completed = 'completed',
  InProgress = 'in-progress',
  NotStarted = 'not-started',
  Disabled = 'disabled',
  Active = 'active',
  Error = 'error',
}

export interface SubStep {
  subStep: string;
  slug: string;
  nextButtonLabel?: string;
  cancelButtonLabel?: string;
  hideCancelButton?: boolean;
  hideNextButton?: boolean;
  element?: JSX.Element;
  // onStepChange?: () => void;
  //status?: 'completed' | 'in-progress' | 'not-started' | 'disabled' | 'review' | 'error' | undefined;
  status?: StepStatus;
  onStepChange?: (arg?: string) => Promise<boolean>;

}

export interface Step {
  step: string;
  slug: string;
  nextButtonLabel?: string;
  cancelButtonLabel?: string;
  hideCancelButton?: boolean;
  hideNextButton?: boolean;
  element?: JSX.Element;
  subSteps: SubStep[];
  status?: StepStatus;
  onStepChange?: (arg: string) => Promise<boolean>;
}

const SidebarFormNavigation: React.FC = observer(() => {
  const { formData } = useForm();

  const stepIcon = (step: any) => {
    let circleColor = '';
    let textColor = 'text-15px';
    let icon = <></>//<div className=" h-full w-full  h-6 w-6">
    //   <div className="h-full w-full border-[#B3B3B3] bg-transparent border-2 rounded-full " aria-hidden="true" />{' '}
    // </div>
    if (formData.activeSubStep && formData.activeSubStep.slug === step.slug) {
      circleColor = 'bg-orange-500';
      textColor = 'text-15px';
      icon = <div className='bg-orange-500 h-6 w-6 flex items-center justify-center rounded-full' />
    }
    else if (step.status === StepStatus.NotStarted) {
      circleColor = 'border-primary-500 bg-transparent border-2 ';
      textColor = 'text-15px';
      icon = <div className=" h-full w-full  h-6 w-6">
        <div className="h-full w-full border-[#B3B3B3] bg-transparent border-2 rounded-full " aria-hidden="true" />{' '}
      </div>
    } else if (step.status === StepStatus.Completed) {
      circleColor = 'bg-primary-500';
      textColor = 'text-15px text-primary-800';
      icon = <div className='bg-[#B3B3B3] h-6 w-6 flex items-center justify-center rounded-full'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="text-white w-4 h-4">
          <path fillRule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd" />
        </svg>
      </div>
    } else if (step.status === StepStatus.Active) {
      circleColor = 'bg-yellow-500';
      textColor = 'text-15px text-yellow-500';
      icon = <div className='bg-primary-500 h-6 w-6 flex items-center justify-center rounded-full' />
    } else if (step.status === StepStatus.Error) {
      circleColor = 'bg-red-500';
      textColor = 'text-15px text-red-500';
      icon = <div className='bg-primary-500 h-6 w-6 flex items-center justify-center rounded-full' />
    }

    return (
      <>
        <span className="flex items-start">
          <span className="relative flex h-6 w-6 flex-shrink-0 items-center justify-center bg-white rounded-full">
            {icon}
          </span>
          <span className={`ml-3 font-medium text-gray-300 ${step.status === StepStatus.Active && '!text-primary-500 font-semibold'}`}>
            {step.step}
          </span>{' '}
        </span>
      </>
    )
  };

  const subStepIcon = (subStep: any) => {
    let circleColor = '';
    let textColor = 'text-15px text-[#656565]';
    const stepIsActive = formData.activeSubStep && formData.activeSubStep.slug === subStep.slug


    if (stepIsActive) { 
      circleColor = 'bg-primary-500';
     
    }
    else if (subStep.status === StepStatus.NotStarted) {
      circleColor = 'border-primary-500 bg-transparent border-2 '; 
    } else if (subStep.status === StepStatus.Completed) {
      circleColor = 'bg-primary-500'; 
    } else if (subStep.status === StepStatus.Active) {
      circleColor = 'bg-yellow-500'; 
      
    } else if (subStep.status === StepStatus.Error) {
      circleColor = 'bg-red-500'; 
    }

    return (
      <>
      <div className={`ml-0.5 flex w-full items-center pl-4 py-1 ${stepIsActive && 'bg-orange-50 '}`}>
        <div className="ml-2 mr-1 h-5 w-5">
          {/* <div className={`h-full w-full bg-red-500 rounded-full ${circleColor}`} aria-hidden="true" /> */}
          {subStep.status === StepStatus.Completed && <Check className='h-5 w-5 text-orange-500' />}
        </div>
        <p className={textColor}>{subStep.subStep}</p>
        </div>
      </>
    )

  };

  return (
    <>
      {formData.retirementSteps?.map(
        (
          step: {
            onStepChange: any;
            slug: any;
            completed: any;
            subSteps: any;
            step: React.ReactNode;
          },
          index: any,
        ) => (
          <div key={index}>
            <Accordion.Root
              className={`${formData.activeStep && formData.activeStep.slug === step.slug ? ' ' : ''}`}
              type="single"
              // defaultValue={step.slug}
              defaultValue={formData.activeStep && formData.activeStep.slug}>
              <AccordionItem value={`${step.slug}`} className="relative">
                <AccordionTrigger>
                  {index < (formData && formData.activeStepNumber ? formData.activeStepNumber : 0) ? (
                    <div className="absolute left-5 top-4  mt-0.5 h-full w-0.5 bg-primary-500 " aria-hidden="true" />
                  ) : index > (formData && formData.activeStepNumber ? formData.activeStepNumber : 0) ?  
                  <div className="absolute left-5  top-4   mt-0.5 h-full w-0.5 bg-[#B3B3B3]" aria-hidden="true" /> : (index + 1 >= formData.retirementSteps.length ? null : (
                    <div className="absolute left-5  top-4   mt-0.5 h-full w-0.5 bg-orange-500" aria-hidden="true" />
                  ))}
                  <Link to={`${step.slug}`} className="rounded-lg px-2 text-base w-full py-3 text-left group">
                    {/* {stepIcon(step)} */}
                    <span className="flex items-start">
                      <span className="relative flex h-6 w-6 flex-shrink-0 items-center justify-center bg-white rounded-full">
                        {index < (formData && formData.activeStepNumber ? formData.activeStepNumber : 0) ? (
                        <div className='h-6 w-6 flex items-center justify-center rounded-full'>
                        <svg   className='text-primary-500 w-full h-full' viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 2.15625C13.9781 2.15625 16.3547 3.14068 18.107 4.89297C19.8593 6.64526 20.8438 9.02188 20.8438 11.5C20.8438 13.9781 19.8593 16.3547 18.107 18.107C16.3547 19.8593 13.9781 20.8438 11.5 20.8438C9.02188 20.8438 6.64526 19.8593 4.89297 18.107C3.14068 16.3547 2.15625 13.9781 2.15625 11.5C2.15625 9.02188 3.14068 6.64526 4.89297 4.89297C6.64526 3.14068 9.02188 2.15625 11.5 2.15625ZM11.5 23C14.55 23 17.4751 21.7884 19.6317 19.6317C21.7884 17.4751 23 14.55 23 11.5C23 8.45001 21.7884 5.52494 19.6317 3.36827C17.4751 1.2116 14.55 0 11.5 0C8.45001 0 5.52494 1.2116 3.36827 3.36827C1.2116 5.52494 0 8.45001 0 11.5C0 14.55 1.2116 17.4751 3.36827 19.6317C5.52494 21.7884 8.45001 23 11.5 23ZM16.5762 9.38867C16.9984 8.96641 16.9984 8.28359 16.5762 7.86582C16.1539 7.44805 15.4711 7.44355 15.0533 7.86582L10.067 12.8521L7.95566 10.7408C7.5334 10.3186 6.85059 10.3186 6.43281 10.7408C6.01504 11.1631 6.01055 11.8459 6.43281 12.2637L9.30781 15.1387C9.73008 15.5609 10.4129 15.5609 10.8307 15.1387L16.5762 9.38867Z" fill="currentColor"/>
                        </svg>
                        </div>
                        ) : null}
                        {index == (formData && formData.activeStepNumber ? formData.activeStepNumber : 0) ? (
                          <div className='bg-orange-500 h-6 w-6 flex items-center justify-center rounded-full' />
                        ) : null}
                        {index > (formData && formData.activeStepNumber ? formData.activeStepNumber : 0) ? (
                          <div className=" h-full w-full  h-6 w-6">
                            <div className="h-full w-full border-[#B3B3B3] bg-transparent border-2  rounded-full " aria-hidden="true" />{' '}
                          </div>
                        ) : null}
                      </span>
                     
                      <span className={`ml-3 text-15px font-medium text-gray-400 
                        
                        //active
                        ${formData.activeStep && formData.activeStep.slug === step.slug && '!text-gray-600 font-semibold'}

                        // completed
                        ${index < (formData && formData.activeStepNumber ? formData.activeStepNumber : 0) ? 'text-primary-500 font-semibold' : ''}
                        
                        `}>
                        {step.step}
                      </span>{' '}
                      
                    </span>
                  </Link>{' '}{step.subSteps.length > 0 && <ChevronDown className="w-5 h-5 text-gray-400 transform transition-transform" />}
                </AccordionTrigger>
                {step.subSteps && (
                  <AccordionContent>
                    {step.subSteps.map(
                      (
                        subStep: {
                          slug: any;
                          completed: any;
                          subStep: React.ReactNode;
                          status: StepStatus,
                        },
                        subIndex: any,
                      ) => (
                        <div key={subIndex} className="relative pl-5">
                          <Link
                            to={`${step.slug}/${subStep.slug}`}
                            className={`${formData.activeSubStep && formData.activeSubStep.slug === subStep.slug ? ' rounded-lg  text-black font-medium' : 'text-gray-700'}
                            py-1 flex items-center space-x-2 text-[13px]`}>
                            {subStepIcon(subStep)}
                            {/* {formData.activeSubStep && formData.activeSubStep.slug === subStep.slug || subStep.status === 'completed' ? (
                              <div className=" ml-3 h-4 w-4 ">
                                <div className="h-full w-full bg-primary-500  rounded-full" aria-hidden="true" />{' '}
                              </div>
                            ) : subStep.status === 'error' ? <div className=" ml-3 h-4 w-4">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-full w-full  text-red-600 rounded-full ">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
                              </svg>
                            </div> : (
                              <div className=" ml-3 h-4 w-4 ">
                                <div className="h-full w-full  border-primary-500 bg-transparent  border-2 rounded-full " aria-hidden="true" />{' '}
                              </div>
                            )} */}
                          </Link>
                        </div>
                      ),
                    )}
                  </AccordionContent>
                )}
              </AccordionItem>
            </Accordion.Root>
          </div>
        ),
        // ) : (
        //   <div key={index}>
        //     <Link to={`${step.slug}`} className="flex items-center space-x-2">
        //       <div className={`h-6 w-6 rounded-full flex items-center justify-center`}>
        //         <Check className="h-4 w-4 text-white" />
        //       </div>
        //       <p className="text-sm">{step.step}</p>
        //     </Link>

        //   </div>
        // )
      )}
    </>
  );
});

type AccordionItemProps = React.ComponentProps<typeof Accordion.Item> & {
  className?: string;
};

const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item className={classNames(className)} {...props} ref={forwardedRef}>
    {children}
  </Accordion.Item>
));

type AccordionTriggerProps = React.ComponentProps<typeof Accordion.Trigger> & {
  className?: string;
};

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="flex">
    <Accordion.Trigger
      className={classNames(
        'cursor-pointer group flex flex-1 cursor-default items-center justify-between eading-none outline-none',
        className,
      )}
      {...props}
      ref={forwardedRef}>
      {children}
      {/* <ChevronDownIcon
          className=" ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
          aria-hidden
        /> */}
    </Accordion.Trigger>
  </Accordion.Header>
));

type AccordionContentProps = React.ComponentProps<typeof Accordion.Content> & {
  className?: string;
};

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionContentProps>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames('data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp', className)}
    {...props}
    ref={forwardedRef}>
    {children}
  </Accordion.Content>
));

export default SidebarFormNavigation;
