export enum SubmissionStatus {
    Draft = 1,
    Submitted = 2,
    Reviewing = 3,
    Pending = 4,
    InformationRequired = 5,
    Completed = 6,
    Rejected = 7
}

export enum PersonalStatus {
    Single = 1,
    Married = 2,
    RegistedDomesticPartner = 3,
    Widowed = 4,
    Divorced = 5,
    TerminatedDomesticPartnership = 6
}

export enum AccountType {
    CheckingAccount = 1,
    SavingsAccount = 2,
}

export enum SupportedDocuments {
    BirthCertificate = 1,
    MarriageRegistrationCertificate = 2,
    SpouseDomesticPartnerSsn = 3,
    CourtEndorsedDomesticRelationsOrder = 4,
    ElectionOfRetirementAllowance = 5,
    SpouseDomesticPartnerOtherBeneficiaryBirthCertificate = 6,
    Other = 7,
    SpouseDeathCertificate = 8,
    VoidedCheck = 9,
    CertifiedBankLetterOrSavingsAccountStatement = 10,
    TemporaryAnnuitiesEstimateFromSocialSecurityOffice = 11
}
