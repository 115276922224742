import { Service } from 'typedi';
import { BaseService, IBaseService } from '../../core/services/BaseService';
import { Appl } from '../../core/Appl';
export type IPublicService = IBaseService;

@Service() // eslint-disable-next-line
export class PublicService extends BaseService implements IPublicService {
  constructor() {
    super(`${Appl.Setting.Services.RetirementServiceUrl}`);
  }
}
