import { lazyComponent } from '../core/components/LazyComponent';
import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import App from '../App';
import { NotAuthorizedView } from '../core/pages/NotAuthorizedView';
import { HelpView } from '../core/pages/HelpView';
import { PageNotImplementedView } from '../core/pages/PageNotImplementedView';
import { PageNotFoundView } from '../core/pages/PageNotFoundView';
import { Appl } from '../core/Appl';
import LoginView from '../modules/admin/account/login/LoginView';
import { DashboardView } from '../modules/admin/sra/dashboard/DashboardView';
import { PlanningCheckListView } from '../modules/admin/sra/checkList/PlanningCheckListView';
import ComponentsView from '../ui/components';
import { RetirementConsiderationsView } from '../modules/admin/sra/checkList/RetirementConsiderationsView';
import { SubmissionStatusView } from '../modules/admin/sra/retiree/addEdit/12_1_SubmissionStatusView';
import { RapDashboardView } from '../modules/admin/rap/dashboard/RapDashboardView';
import { MemberLookupView } from '../modules/admin/members/memberFiles/invite/docUpload/MemberLookupView';
import { UploadRequestListView } from '../modules/admin/members/memberFiles/list/UploadRequestListView';
import RedirectView from '../modules/admin/account/login/RedirectView';
import LogoutView from '../modules/admin/account/login/LogoutView';
import { SubmissionListView } from '../modules/admin/sra/submission/list/SubmissionListView';
import ChangeOfAddressView from '../modules/admin/members/changeOfAddress/addEdit/ChangeOfAddressView';
import DeathReportView from '../modules/admin/members/deaths/deathReport/addEdit/DeathReportView';
import { DeathReportListView } from '../modules/admin/members/deaths/deathReport/list/DeathReportListView';
import { ChangeOfAddressListView } from '../modules/admin/members/changeOfAddress/list/ChangeOfAddressListView';

export function RouteConfig() {

  const SecuredRoutes = () => {
    return (
      Appl.User.isAuthenticated ? <Outlet /> : <LoginView />
    );
  };

  let accountPublicRoutes: Array<RouteObject> = [
    { path: '', element: lazyComponent('modules/public/home/HomeView') },
    { path: '/login', element: <LoginView /> },
    { path: '/logout', element: <LogoutView /> },
    { path: '/redirect', element: <RedirectView /> },//element: lazyRoute("modules/admin/identity/auth/LoginView")
    // { path: '/logout', element: lazyComponent('modules/admin/identity/auth/LogoutView') },
    { path: 'noauthorization', element: <NotAuthorizedView /> },
    { path: 'help/:id', element: <HelpView /> },
    { path: 'unimplemented', element: <PageNotImplementedView /> },
    { path: '/components', element: <ComponentsView /> },
    { path: '*', element: <PageNotFoundView /> },
  ];

  let retirementSecuredRoutes: Array<RouteObject> = [
    { path: 'sra', element: <DashboardView /> },
    { path: 'sra/planning-checklist', element: <PlanningCheckListView /> },
    { path: 'sra/retirement-considerations', element: <RetirementConsiderationsView /> },
    { path: 'sra/retiree', element: <SubmissionListView /> },
    // { path: 'sra/retiree/addEdit/:submissionId', element: lazyComponent("modules/admin/sra/retiree/addEdit/RetireeView") },
    { path: 'sra/retiree/application/:memberId/*', element: lazyComponent("modules/admin/sra/retiree/addEdit/ApplicationLayout") },
    { path: 'sra/retiree/afr-status/:memberId', element: <SubmissionStatusView /> },

  ];

  let changeOfAddressRoutes: Array<RouteObject> = [
    { path: 'members/change-of-address-notifications', element: <ChangeOfAddressListView /> },
    { path: 'members/change-of-address/:id', element: <ChangeOfAddressView /> },
  ];

  let deathsRoutes: Array<RouteObject> = [
    { path: 'members/death-notifications', element: <DeathReportListView /> },
    { path: 'members/death-report/:id', element: <DeathReportView /> },
  ];

  let rapSecuredRoutes: Array<RouteObject> = [
    { path: 'rap', element: <RapDashboardView /> },
  ];

  let memberFilesSecuredRoutes: Array<RouteObject> = [
    { path: 'members/invite/doc-upload', element: <MemberLookupView /> },
    { path: 'members/upload-requests', element: <UploadRequestListView /> },
  ];

  let securedRoutes = new Array<RouteObject>;
  securedRoutes.push(...retirementSecuredRoutes);
  securedRoutes.push(...changeOfAddressRoutes);
  securedRoutes.push(...deathsRoutes);
  securedRoutes.push(...memberFilesSecuredRoutes);
  securedRoutes.push(...rapSecuredRoutes);

  let publicRoutes = new Array<RouteObject>;
  publicRoutes.push(...accountPublicRoutes);

  const element = useRoutes([
    { path: '', element: <App />, children: [{ element: <SecuredRoutes />, children: securedRoutes }, { children: publicRoutes }] },
  ]);
  return element;
}
