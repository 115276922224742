import React from 'react';
import {observer} from 'mobx-react';

export const PageHelp: React.FC<{viewId?: string; title: string}> = observer((props) => {
  const helpUrl = `/help/${props.viewId}`;

  return (
    <>
      <a href={helpUrl} title={props.title} target="_blank" rel="noopener noreferrer">
        <i className={`pi pi-question-circle`} style={{fontSize: '1.6em', marginTop: '12px'}}></i>
      </a>
    </>
  );
});
