import { Service } from 'typedi';
import { BaseService, IBaseService, ServerResponse } from '../../../core/services/BaseService';
import { Appl } from '../../../core/Appl';

export type IRapService = IBaseService;

@Service()
export class RapService extends BaseService implements IRapService {
  constructor() {
    super(`${Appl.Setting.Services.RapServiceUrl}/rap`);
  }
}
