import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import MemberLookupViewModel from './MemberLookupViewModel';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import MemberFileModule from '../../MemberFileModule';
import { Appl } from '../../../../../../core/Appl';
import { Button, Input, InputCalendar, InputMultiSelect, InputOptionBox, InputType, View, ViewHeader } from '../../../../../../core/components/Index';


export const MemberLookupView: React.FC = observer(() => {
  const vm = Appl.Services.get<MemberLookupViewModel>('MemberLookupViewModel');
  vm.navigate = useNavigate();
  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);


  //add special formatting to the document type list for select items 
  const documentTypeTemplate = (option: any) => {
    if (option.value === 'Proof of Identity (e.g,. Social Security Card, Photo ID, etc)') {
      return <span>Proof of Identity <span className='text-15px font-normal'>(e.g,. Social Security Card, Photo ID, etc)</span></span>
    }
    return (
      <span>{option.value}</span>
    );
  };

  return (
    <>
      <View
        id="MemberListView"
        pageTitle={vm.pageTitle}
        authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
        menuItems={MemberFileModule.MenuItems}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
        <div className="space-y-6 mt-4">
          <h2 className="text-22px font-semibold mb-6 text-black">Contact Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div>
              <Input
                id="reqFirstName"
                value={vm.model.reqFirstName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'reqFirstName');
                }}
                label="First Name"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="reqLastName"
                value={vm.model.reqLastName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'reqLastName');
                }}
                label="Last Name"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="reqEmail"
                value={vm.model.reqEmail}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'reqEmail');
                }}
                label="Email"
                required={true}
                maxLength={200}
              />
            </div>
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <h2 className="text-22px font-semibold text-black">Member Information</h2>
            <div>
              <InputOptionBox
                inputId="sameAsRequesterSelected"
                label="Same as Contact"
                className="mt-1"
                checked={vm.sameAsRequesterSelected}
                onChange={(e: CheckboxChangeEvent) => vm.onSameAsRequesterChange(e)}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 mb-3">
            <div>
              <Input
                id="memberFirstName"
                value={vm.model.memberFirstName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'memberFirstName');
                }}
                label="First Name"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="memberLastName"
                value={vm.model.memberLastName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'memberLastName');
                }}
                label="Last Name"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="memberEmail"
                value={vm.model.memberEmail}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'memberEmail');
                }}
                label="Email"
                required={false}
                maxLength={200}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 mb-3">
            <div>
              <Input
                id="mbrId"
                value={vm.model.mbrId}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onInputChange(e, 'mbrId', vm.model);
                }}
                label="Member ID"
                required={false}
                maxLength={40}
              />
            </div>
            <div>
              <Input
                id="mbrSsn"
                value={vm.model.mbrSsn}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onInputChange(e, 'mbrSsn', vm.model);
                }}
                label="SSN (XXX-XX-####)"
                required={false}
                maxLength={4}
              />
            </div>
            <div>
              <InputCalendar
                id="mbrDob"
                value={vm.model.mbrDob}
                label="Date Of Birth (mm/dd/yyyy)"
                onChange={(e) => {
                  vm.onInputChange(e, 'mbrDob', vm.model);
                }}
                required={false}
                className="w-full"
              />
            </div>
          </div>
          <hr />
          <h2 className="text-22px font-semibold mb-4 text-black">Requested Document(s)</h2>
          <div className="grid grid-cols-1 gap-4 mt-4 mb-6">
            <div>
              <InputMultiSelect
                id="requestedDocuments"
                type="text"
                label="Choose Document Types"
                value={vm.model?.requestedDocuments}
                options={vm.documentTypeListItems}
                itemTemplate={documentTypeTemplate}
                optionValue="value"
                optionLabel="value"
                onChange={(e: any) => vm.onRequiredDocumentsChange(e)}
                maxSelectedLabels={1}
                filter={true}
                className="max-w-full !max-w-[500px] !w-full"
                required={true}
                placeholder="Please Select"
              />
            </div>
            <div className='text-15px text-neutral-700 leading-6'>
              {vm.model.requestedDocuments && vm.model.requestedDocuments.length > 0 && (
                <>
                  <p className='mb-3'>You have selected the following Document Types:</p>
                  <p>
                    <ul className="list-disc ml-4 font-medium">
                      {vm.model.requestedDocuments.map((item, index) => {

                        return (
                          <div key={`documentTypeListItem-${index}`} className="flex items-center">

                            {item !== 'Other' && (item === 'Proof of Identity (e.g,. Social Security Card, Photo ID, etc)' ? <li>Proof of Identity <span className='font-normal'>(e.g,. Social Security Card, Photo ID, etc)</span></li> :
                              <li>{item}</li>
                            )}
                          </div>
                        )
                      })}
                      {vm.model.requestedDocuments.includes('Other') && (
                        <li>
                          <span className="block">Other (please specify)</span>
                          <Input
                            id="otherDocumentName"
                            value={vm.model.otherDocumentName}
                            inputType={InputType.Text}
                            onChange={(e) => {
                              vm.onMemberInfoChange(e, 'otherDocumentName');
                            }}
                            required={vm.otherDocTextBoxVisible}
                            hidden={!vm.otherDocTextBoxVisible}
                            style={{ width: '480px' }}
                          />
                        </li>
                      )}
                    </ul>
                  </p>
                </>
              )}
            </div>
          </div>
          <hr />
          <div className="flex items-center justify-center gap-x-6 my-6">
            <Button title="Send Invitation" disabled={!vm.enableSendInvitationButton} onClick={async () => await vm.sendInvitationAsync()} />
            <Button title="Cancel" severity="secondary" onClick={() => vm.navigate(-1)} />
          </div>
        </div>
      </View>
    </>
  );
});
