import { Appl } from '../Appl';
import { IUserContext } from './IUserContext';
import { UserContextBase } from './UserContextBase';
import { UserContextBuiltIn } from './UserContextBuiltIn';
import { UserContextAzure } from './UserContextAzure';

export class UserContext extends UserContextBase implements IUserContext {

  private static _instance: UserContext;
  public static get instance() {
    let authProvider = Appl.Cache.get("AuthProvider");
    if (authProvider === "AzureAD") {
      this._instance = UserContextAzure.instance;
    } else {
      this._instance = UserContextBuiltIn.instance;
    }
    return this._instance;
  }
}

