import './ConfirmBox.css';
import { observer } from 'mobx-react';
import { action, makeAutoObservable, observable } from 'mobx';
import { Appl } from '../../Appl';
import { Button } from '../actions/Button';
import parse from 'html-react-parser';
import { X } from 'lucide-react';

export const ConfirmBox: React.FC = observer(() => {
    const vm = Appl.ConfirmBox;
    let titleBackground = 'text-bg-info';
    let titleColor = 'black';
    let iconName = 'circle-info';
    return (
        <>
            {vm.visible &&
                <>
                    <div className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75" style={{ zIndex: 4000 }}></div>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto drop-shadow-sm" style={{ zIndex: 5000 }}>
                        <div className="flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                <div className="flex items-start bg-cream-50 border-b-2 border-orange-500 p-0 py-4 px-6 justify-between items-center">
                                    <h3 className="text-2xl font-semibold leading-6 text-black tracking--1percent" id="modal-title">{vm.title}</h3>
                                    <div className='ml-4'>
                                        <button type="button" className="bg-orange-500 flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 h-6 w-6 hover:text-white-500 inline-flex items-center justify-center rounded-full text-white" data-pc-section="closebutton"
                                            onClick={() => { vm.acceptConfirmation(false) }}>
                                            <span className="sr-only">Close</span>
                                            <X className="h-4 w-4" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                                <div className="bg-white px-6 p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:text-left">
                                            <div>
                                                <p className="text-15px text-gray-800">{parse(vm.message)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-x-4 px-6 py- pb-6 ">
                                    <Button title={vm.acceptLabel} onClick={() => vm.acceptConfirmation(true)} />
                                    <Button title={vm.rejectLabel} severity='secondary' onClick={() => vm.acceptConfirmation(false)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
});

export interface IConfirmBoxService {
    title: string;
    subTitle: string;
    message: string;
    visible: boolean;
    confirmAccept: boolean;
    acceptLabel: string;
    rejectLabel: string;
    selectedObject?: any;
    show(message: string, title?: string, subTitle?: string, acceptLabel?: string, rejectLabel?: string, accept?: Function, reject?: Function): void;
    hide(): void;
    acceptConfirmation(value: boolean): void;
    accept?: Function;
    reject?: Function;
}

export class ConfirmBoxService implements IConfirmBoxService {

    private static _instance: ConfirmBoxService;
    public static get instance() {
        return this._instance ?? (this._instance = new ConfirmBoxService());
    }

    @observable public title = '';
    @observable public subTitle = '';
    @observable public message = '';
    @observable public visible = false;
    @observable public acceptLabel = 'Yes';
    @observable public rejectLabel = 'No';
    @observable public confirmAccept = false;
    accept?: Function;
    reject?: Function;
    constructor() {
        makeAutoObservable(this);
    }

    @action public show(message: string, title: string | undefined = 'Confirm', subTitle = '',
        acceptLabel = "Yes", rejectLabel = "No", accept?: Function, reject?: Function): void {
        this.title = title;
        this.message = message;
        this.subTitle = subTitle;
        this.visible = true;
        this.confirmAccept = false;
        this.acceptLabel = acceptLabel;
        this.rejectLabel = rejectLabel;
        if (accept) {
            this.accept = accept;
        }
        if (reject) {
            this.reject = reject;
        }
    }

    @action public hide(): void {
        this.title = '';
        this.message = '';
        this.subTitle = '';
        this.visible = false;
    }

    @action public acceptConfirmation(value: boolean): void {
        this.confirmAccept = value;
        if (value) {
            if (this.accept) {
                this.accept();
            }
        } else {
            if (this.reject) {
                this.reject();
            }
        }
        this.hide();
        console.log(`acceptConfirmation:${this.confirmAccept}`);
    }
}