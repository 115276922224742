import React from 'react';
import './RapDashboardView.css';
import { observer } from 'mobx-react';
import { Button, Icon, View, ViewHeader } from '../../../../core/components/Index';
import { Link, useNavigate } from 'react-router-dom';
import RapDashboardViewModel from './RapDashboardViewModel';
import useAsyncEffect from 'use-async-effect';
import RapModule from '../RapModule';
import { Appl } from '../../../../core/Appl';

export const RapDashboardView: React.FC = observer(() => {
  const vm = Appl.Services.get<RapDashboardViewModel>('RapDashboardViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);
  return (
    <>
      <View id="RapDashboardView" authorized={Appl.User.isAuthenticated}
        showMenuBar={Appl.User.isAuthenticated}
        menuItems={RapModule.MenuItems}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} />
        <div className="grid grid-cols-3 gap-4">
          <div>
            <div className="colour-1 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  {' '}
                  <Icon name="file-pen-line" title="" color="white" /> RAP BROWSER
                </h5>
                <p>RAP Browser.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="View" onClick={() => vm.navigate(`/rap/list`)} />
              </div>
            </div>
          </div>
          <div >
            <div className="colour-2 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="clipboard-list" title="" color="white" /> ESTIMATE RAP
                </h5>
                <p>Estimate RAP Input.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="View" onClick={() => vm.navigate(`/rap/addEdit`)} />
              </div>
            </div>
          </div>
          <div>
            <div className="colour-3 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="list-checks" title="" color="white" /> MAINTENANCE TABLE
                </h5>
                <p>RAP Maintenance Tables.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="View" onClick={() => vm.navigate(`/rap/utility`)} />
              </div>
            </div>
          </div>
        </div>
      </View>
    </>
  );
});
