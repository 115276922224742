import { Service } from 'typedi';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { action, makeObservable, observable } from 'mobx';
import { Appl } from '../../../../../core/Appl';
import { IChangeOfAddressService } from '../ChangeOfAddressService';
import { ChangeOfAddressListModel } from './ChangeOfAddressListModel';
import Helper from '../../../../../core/services/Helper';


@Service() // eslint-disable-next-line
export default class ChangeOfAddressListViewModel extends BaseListViewModel<ChangeOfAddressListModel> {
  private service: IChangeOfAddressService;
  @observable public afrFile?: any;
  @observable public printDialogVisible = false;

  constructor() {
    super('Change of Address Notifications Queue', 'mbrChangeOfAddressId', false);
    this.service = Appl.Services.get<IChangeOfAddressService>('IChangeOfAddressService');
    makeObservable(this);
  }

  public async getPagedAsync(): Promise<void> {
    const response = await this.service.getPagedAsync(`/members/ChangeOfAddress/search`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

  @action
  public async showPrintDialog(id: number): Promise<void> {
    const result = await this.service?.downloadAsync(`/members/ChangeOfAddress/download-change-of-address-pdf-form/${id}`);
    if (result.success) {
      this.afrFile = result.data
    } else {
      Appl.Error.handle(result.error);
    }
    this.printDialogVisible = true;
  }

  @action public async hidePrintDialog(): Promise<void> {
    this.printDialogVisible = false;
  }

  @action
  public async export(format: string): Promise<void> {
    this.searchRequest = { skip: 0, take: 10000, sortColumn: this.searchRequest!.sortColumn, sortAscending: this.searchRequest!.sortAscending, filter: '' };
    const result = await this.service.getQueueAsFileAsync(format, this.searchRequest);
    if (result.success) {
      Helper.downloadFile(result.data, 'ChangeOfAddress.csv', 'text/csv');
    } else {
      Appl.Error.handle(result.error);
    }
  }
}
