import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { View, ViewHeader } from '../components/Index';

export const HelpView: React.FC = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  return (
    <>
      <View id="HelpView">
        <ViewHeader title={`Help - ${params.id}`} backUrl="/" />
        <div className="p-8">
          <div className="bg-white rounded-xl mb-4 text-center py-16 px-6 shadow-card border border-gray-25 space-y-8">
            <div>
              <h2 className="font-medium text-lg md:text-2xl text-gray-800">UNDER CONSTRUCTION</h2>
              <p className="text-gray-500 max-w-2xl mx-auto text-sm md:text-base">The help content you are looking is under constructions</p>
            </div>
          </div>
        </div>
      </View>
    </>
  );
};
