import { Service } from 'typedi';
import { BaseListViewModel } from '../../../../../../core/services/BaseViewModel';
import { action, makeObservable } from 'mobx';
import { Appl } from '../../../../../../core/Appl';
import { DeathReportListModel } from './DeathReportListModel';
import { IDeathService } from '../../DeathService';
import Helper from '../../../../../../core/services/Helper';

@Service() // eslint-disable-next-line
export default class DeathReportListViewModel extends BaseListViewModel<DeathReportListModel> {
  private service: IDeathService;

  constructor() {
    super('Death Notifications Queue', 'mbrDeathReportId', false);
    this.service = Appl.Services.get<IDeathService>('IDeathService');
    makeObservable(this);
  }

  public async getPagedAsync(): Promise<void> {
    const response = await this.service.getPagedAsync(`/members/DeathReporting/search`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

  @action
  public async export(format: string): Promise<void> {
    this.searchRequest = { skip: 0, take: 10000, sortColumn: this.searchRequest!.sortColumn, sortAscending: this.searchRequest!.sortAscending, filter: '' };
    const result = await this.service.getQueueAsFileAsync(format, this.searchRequest);
    if (result.success) {
      Helper.downloadFile(result.data, 'Death_Reporting.csv', 'text/csv');
    } else {
      Appl.Error.handle(result.error);
    }
  }

}
