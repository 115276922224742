import Container from "typedi";

export interface IServiceContainer {
    add(interfaceName: string, implementation: any): void;
    get<T>(interfaceName: string): T
}

export class ServiceContainer implements IServiceContainer {

    private static _instance: ServiceContainer;
    public static get instance() {
        return this._instance ?? (this._instance = new ServiceContainer());
    }

    public add(interfaceName: string, implementation: any): void {
        Container.set(interfaceName, Container.get(implementation));
    }
    public get<T>(interfaceName: string): T {
        return Container.get<T>(interfaceName);
    }
    // public addSingletone<T>(implementation: any): void {
    //   //let interfaceBame:string = typeof(T).name;
    //   let interfaceBame: string = typeof<T>();
    //   //let tp = typeof (T);
    //   Reflect.getMetadata('name', T);
    //   Container.set(interfaceBame, Container.get(implementation));
    // }

}