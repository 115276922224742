import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { Appl } from '../../../../core/Appl';
import { DeathReportModel } from './deathReport/addEdit/DeathReportModel';

export interface IDeathService extends IBaseService {
    getQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse>;
    createDeathReportAsync(model: DeathReportModel): Promise<ServerResponse>;
    getListOfValuesAsync(tableName: string | undefined): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class DeathService extends BaseService implements IDeathService {
    constructor() {
        super(`${Appl.Setting.Services.MemberServiceUrl}`);
    }

    public async getQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/members/DeathReporting/download-death-repoting-queue/${format}`, searchModel, { responseType: 'blob' });
            return this.getResponse(response);
        } catch (error: unknown) {
            return this.getErrorResponse(error);
        }
    }

    public async createDeathReportAsync(model: DeathReportModel): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/members/DeathReporting`, model);
            return this.getResponse(response);
        } catch (error: unknown) {
            return this.getErrorResponse(error);
        }
    }

    public async getListOfValuesAsync(tableName: string | undefined): Promise<ServerResponse> {
        try {
            const response = await this.client.get(`/members/ListOfValue/byTableName/${tableName}`);
            return this.getResponse(response);
        } catch (error: unknown) {
            return this.getErrorResponse(error);
        }
    }
}
