import { useEffect, useRef } from 'react';
import { ListItem } from './BaseService';

export default class Helper {

  public static StateChanged: boolean = true;

  public static States: Array<ListItem> = [
    { key: '', value: 'Select' },
    { key: 'AL', value: 'Alabama' },
    { key: 'AL', value: 'Alaska' },
    { key: 'AZ', value: 'Arizona' },
    { key: 'AR', value: 'Arkansas' },
    { key: 'CA', value: 'California' },
    { key: 'CO', value: 'Colorado' },
    { key: 'CT', value: 'Connecticut' },
    { key: 'DE', value: 'Delaware' },
    { key: 'FL', value: 'Florida' },
    { key: 'GA', value: 'Georgia' },
    { key: 'HI', value: 'Hawaii' },
    { key: 'ID', value: 'Idaho' },
    { key: 'IL', value: 'Illinois' },
    { key: 'IN', value: 'Indiana' },
    { key: 'IA', value: 'Iowa' },
    { key: 'KS', value: 'Kansas' },
    { key: 'KY', value: 'Kentucky' },
    { key: 'LA', value: 'Louisiana' },
    { key: 'ME', value: 'Maine' },
    { key: 'MD', value: 'Maryland' },
    { key: 'MA', value: 'Massachusetts' },
    { key: 'MI', value: 'Michigan' },
    { key: 'MN', value: 'Minnesota' },
    { key: 'MS', value: 'Mississippi' },
    { key: 'MO', value: 'Missouri' },
    { key: 'MT', value: 'Montana' },
    { key: 'NE', value: 'Nebraska' },
    { key: 'NV', value: 'Nevada' },
    { key: 'NH', value: 'New Hampshire' },
    { key: 'NJ', value: 'New Jersey' },
    { key: 'NM', value: 'New Mexico' },
    { key: 'NY', value: 'New York' },
    { key: 'NC', value: 'North Carolina' },
    { key: 'ND', value: 'North Dakota' },
    { key: 'OH', value: 'Ohio' },
    { key: 'OK', value: 'Oklahoma' },
    { key: 'OR', value: 'Oregon' },
    { key: 'PA', value: 'Pennsylvania' },
    { key: 'RI', value: 'Rhode Island' },
    { key: 'SC', value: 'South Carolina' },
    { key: 'SD', value: 'South Dakota' },
    { key: 'TN', value: 'Tennessee' },
    { key: 'TX', value: 'Texas' },
    { key: 'UT', value: 'Utah' },
    { key: 'VT', value: 'Vermont' },
    { key: 'VA', value: 'Virginia' },
    { key: 'WA', value: 'Washington' },
    { key: 'WV', value: 'West Virginia' },
    { key: 'WI', value: 'Wisconsin' },
    { key: 'WY', value: 'Wyoming' },
  ];

  public static usePoll(callback: any, delayInSeconds: number) {
    const savedCallback: any = useRef();

    delayInSeconds = delayInSeconds * 1000;

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delayInSeconds !== null) {
        const id = setInterval(tick, delayInSeconds);
        return () => clearInterval(id);
      }
    }, [delayInSeconds]);
  }

  public static printContent(divName: string): void {
    const printContents = window.document.getElementById(divName)!.innerHTML;
    const printWindow = document.open('', '', '');
    if (printWindow !== null) {
      printWindow.document.write(printContents);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      // printWindow.close();
    }
  }

  public static formatDate(param: any) {
    if (param) {
      const date = new Date(param);
      const d = date.getDate();
      const m = date.getMonth();
      const y = date.getFullYear();
      return `${m + 1}/${d}/${y}`;
    }
    return '';
  }

  public static getDayMonthYearModel(param?: Date): DayMonthYearModel {

    let dayMonthYearModel: DayMonthYearModel = {};
    if (param) {
      const date = new Date(param);
      dayMonthYearModel.day = date.getDate();
      dayMonthYearModel.month = date.getMonth() + 1;
      dayMonthYearModel.year = date.getFullYear();
    }
    return dayMonthYearModel;
  }

  public static getDateModel(year: number, month: number, day: number): Date {

    const date = new Date(year, month - 1, day);
    return date;
  }

  public static formatDateTime(param: any) {
    if (param) {
      const date = new Date(param);
      const d = date.getDate();
      const m = date.getMonth();
      const y = date.getFullYear();

      const minutes = this.twoDigitsFormat(date.getMinutes());
      const seconds = this.twoDigitsFormat(date.getSeconds());

      let hour = date.getHours();
      const ampm = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
      const hours = this.twoDigitsFormat(hour);
      return `${this.twoDigitsFormat(m + 1)}/${this.twoDigitsFormat(d)}/${y} ${hours}:${minutes}:${seconds} ${ampm}`;
    }

    return '';
  }

  public static twoDigitsFormat(num: number): string {
    return num.toString().padStart(2, '0');
  }

  public static roundNumber(value: number, fractionDigits: number): string {
    return value && value != null ? value.toFixed(fractionDigits) : '';
  }

  public static isDateIsNull(param: any): boolean {
    if (param === '') {
      return true;
    } else if (param.toLocaleString().includes('0001')) {
      return true;
    }
    return false;
  }

  public static toDateOnly(param: any): Date | undefined {
    let date: Date;
    if (param) {
      if (param.toLocaleString().includes('0001')) {
        return undefined;
      }
      try {
        date = new Date(param.substring(0, 10).replace(/-/g, '/'));
      } catch (e) {
        date = new Date(param.toLocaleDateString());
      }
      //Fixing an "off by 1 day" bug: https://www.youtube.com/watch?v=oKFb2Us9kmg
      //date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    } else {
      date = new Date();
    }
    return date;
  }

  public static toServerDateTime(param: any): Date {
    let date: Date;
    if (param) {
      try {
        date = new Date(param.substring(0, 10));
      } catch (e) {
        date = new Date(param.toLocaleDateString());
      }
    } else {
      date = new Date();
    }
    //Fixing an "off by 1 day" bug: https://www.youtube.com/watch?v=oKFb2Us9kmg
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  public static toClientDateTime(param: any): Date {
    let date: Date;
    if (param) {
      try {
        //date = new Date(param.substring(0,10))
        date = new Date(param);
      } catch (e) {
        alert(e);
        date = new Date(param.toLocaleDateString());
      }
    } else {
      date = new Date();
    }
    //Fixing an "off by 1 day" bug: https://www.youtube.com/watch?v=oKFb2Us9kmg
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
  }
  public static delay(ms: number) {
    //setTimeout(resolve, ms)
    //setInterval(() => {}, ms);
    setTimeout(() => { }, ms);
  }

  public static formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return '0 Bytes'

    const k = 1000;//1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  public static getFileType(fileName: string) {
    const re = /(?:\.([^.]+))?$/;
    const objects = re.exec(fileName);
    return objects != null ? objects[1] : '';
  }

  public static getContentType(fileType: string) {
    let contentType = '';
    switch (fileType) {
      case 'csv':
        contentType = 'text/csv';
        break;
      case 'json':
        contentType = 'application/json';
        break;
      case 'xml':
        contentType = 'application/xml';
        break;
      case 'docx':
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'xlsx':
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'csvzip':
      case 'jsonzip':
      case 'zip':
        contentType = 'application/zip';
        break;
      case 'pdf':
        contentType = 'application/pdf';
        break;
      default:
        contentType = 'text/plain';
        break;
    }

    return contentType;
  }

  public static downloadFile(data: any, fileName: string, fileType: string) {
    // Create a blob with the data we want to download as a file
    const contentType = Helper.getContentType(fileType);
    if (fileType === 'json') {
      data = JSON.stringify(data);
    }

    // if(fileType=="pdf"){
    //     data = Helper.base64ToArrayBuffer(data);
    // }
    const blob = new Blob([data], { type: contentType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }
  //   public static base64ToArrayBuffer(base64: any) {
  //     let binaryString = window.atob(base64);
  //     let binaryLen = binaryString.length;
  //     var bytes = new Uint8Array(binaryLen);
  //     for (var i = 0; i < binaryLen; i++) {
  //         var ascii = binaryString.charCodeAt(i);
  //         bytes[i] = ascii;
  //     }
  //     return bytes;
  //   }

  public static getActionBadge(action: string) {
    let color = 'badge ';
    switch (action) {
      case 'Update':
        color += 'bg-warning';
        break;
      case 'New':
        color += 'bg-success';
        break;
      default:
        color += 'bg-secondary';
        break;
    }

    return color;
  }

  public static getDifference(newRecord: any, oldRecord: any, properties: string[]) {
    let content = '<tr><th>VERION</th>';
    let newRow = '<tr><td><b>NEW</b></td>';
    let oldRow = '<tr><td><b>OLD</b></td>';
    properties.forEach(function (property) {
      content += `<th>${property.toUpperCase()}</th>`;
      const bgcolor = (
        typeof newRecord[property] === 'string'
          ? newRecord[property].trim().toUpperCase() != oldRecord[property].trim().toUpperCase()
          : newRecord[property] != oldRecord[property]
      )
        ? 'text-danger'
        : '';
      oldRow += `<td>${oldRecord[property] === null ? '' : oldRecord[property]}</td>`;
      newRow += `<td class="${bgcolor}">${newRecord[property] === null ? '' : newRecord[property]}</td>`;
    });
    return `<table class="table table-striped">${content}</tr>${newRow}</tr>${oldRow}</tr></table>`;
  }
}

//Extension Methods
declare global {
  interface Date {
    toDateOnly(): Date;
  }
  interface String {
    toAddPrefix(prefix: string): string;
  }

  // interface String {
  //     toHyperLink(prefix: string): string;
  // }
}
Date.prototype.toDateOnly = function (): Date {
  debugger;
  return this;
  // let date:Date;
  //     if(this){
  //         try{
  //             date = new Date(this.toString().substring(0,10))
  //         }catch(e){
  //             date = new Date()
  //         }

  //     }else{
  //         date = new Date();
  //     }
  // return date;
};

String.prototype.toAddPrefix = function (prefix: string) {
  debugger;
  return `${prefix}${this}`;
};


export interface DayMonthYearModel {
  day?: number;
  month?: number;
  year?: number;
}