import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { MultiSelect, MultiSelectPassThroughOptions, MultiSelectProps } from 'primereact/multiselect';
import { InputErrors } from './InputErrors';
import { Appl } from '../../../core/Appl';
import { classNames } from 'primereact/utils';

const TRANSITIONS = {
  overlay: {
      timeout: 150,
      classNames: {
          enter: 'opacity-0 scale-75',
          enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
          exit: 'opacity-100',
          exitActive: '!opacity-0 transition-opacity duration-150 ease-linear'
      }
  }
}; 
interface MultiSelectExProps extends MultiSelectProps {
  label?: string;
  helpText?: string;
}

export class InputMultiSelect extends React.Component<MultiSelectExProps, MultiSelect> {
  render() {
    const input = this.props;
    return (
      <>
        {input.label && !input.hidden && (
          <label htmlFor={input.id} className="font-bold text-15px text-black mb-2 block">
            {input.label || input.id}:
            {input.required && <span style={{ color: 'red' }}> * </span>}
            {input.helpText && (
              <>
                <Tooltip target={`.${input.id}-help-text`} content={input.helpText} />
                <i className={`pi pi-question-circle ml-2 ${input.id}-help-text`} style={{ fontSize: '0.8em' }}></i>
              </>
            )}
          </label>
        )}
        {input.disabled ? (
          <>
            <div className="field col-12 md:col-6">{input.value?.toString()}</div>
          </>
        ) : (
          <>
            <MultiSelect 
              className={input.className ? input.className : 'field w-100'}
              id={input.id}
              inputId={input.id}
              value={input.value}
              placeholder={input.placeholder}
              options={input.options}
              data-val-field-label={`${input.id}-feedback`}
              onChange={this.props.onChange}
              {...this.props}
            />
            <InputErrors errors={Appl.Error?.items} viewId={input?.id} />
          </>
        )}
      </>
    );
  }
}