import clsx from 'clsx';

export function Container({
  as: Component = 'div',
  className,
  children,
}: {
  as?: any;
  className?: string;
  children?: React.ReactNode;
}) {
  return <Component className={clsx('mx-auto px-6 lg:px-12', className)}>{children}</Component>;
}
//max-w-[96rem]