import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorView } from "../pages/ErrorView";
import { lazy, Suspense } from "react";
import dynamicIconImports from "lucide-react/dynamicIconImports";

type IconProps = {
    name: string;
    size?: number;
    className?: string;
    title?: string;
    color?: string;
    provider?: 'FA' | 'LUCID'
};

export const Icon: React.FC<IconProps> = ({ size = 24, className = 'm-2', color = 'black', provider = "LUCID", ...props }) => {
    const fallback = <div style={{ background: '#ddd', width: 24, height: 24 }} />
    let LucideIcon = lazy(dynamicIconImports[props.name as keyof typeof dynamicIconImports]);
    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorView}
                onError={() => {
                    // reset the state of your app so the error doesn't happen again
                }}
                onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                }}>
                <Suspense fallback={fallback}>
                    {provider === "FA" && <FontAwesomeIcon icon={props.name as IconName} className={className} title={props.title} color={color} {...props} />}
                    {provider === "LUCID" && <LucideIcon size={size} color={color} className={className} {...props} />}
                </Suspense>
            </ErrorBoundary>
        </>
    );
};