import { ReactNode } from "react";
import { Icon } from "../Icon";

type EmptyStateProps = {
    children?: ReactNode;
    title?: ReactNode;
    description?: string;
    className?: string;
    icon?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({ className = '', ...props }) => {
    return (
        <div className={`p-8 py-16 flex border-solid border-1 flex-col text-center items-center justify-center ${className}`}>
            <div className="text-gray-400 mt-3">{props.icon && <Icon name={props.icon} size={64} color='#CFCFCF' />}</div>
            <div className={`text-base text-gray-700 font-medium my-2`}>{props.title}</div>
            <span className="block text-gray-500 text-sm font-normal">{props.description}</span>
            {props.children && props.children}
        </div>
    );
};
